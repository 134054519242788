import { Component, OnInit } from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {LinkedMapsService} from "../linked-maps.service";
import {
  LinkableProgram, MapLink, PendingMapLink,
  PublishedProgramSummary,
} from "../../../../../shared/programmapper-authoring.model";
import {SelectedCollegeService} from "../../../../selected-college.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "../../../../../shared/message/message.service";


@Component({
  selector: 'app-linked-maps-add-modal',
  templateUrl: './linked-maps-add-modal.component.html',
})
export class LinkedMapsAddModalComponent implements OnInit {

  program: PublishedProgramSummary;
  form: FormGroup;
  linkedMap$: Observable<MapLink[]>;
  pendingLinks$: Observable<PendingMapLink[]>;
  mapEmphasis: string;

  linkablePrograms: LinkableProgram[];
  linkableProgramsSub: Subscription;
  linkableColleges: string[];
  collegeFilteredPrograms : LinkableProgram[];
  partnerCollegeSelected: boolean = false;
  requestingMapLink = false;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private linkedMapsService: LinkedMapsService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.program = this.linkedMapsService.program;

    this.loadLinkablePrograms();

    this.linkedMap$ = this.linkedMapsService.getLinkedMaps(this.linkedMapsService.sourceMap.id);

    this.pendingLinks$ = this.linkedMapsService.getPendingLinkedMaps(this.linkedMapsService.sourceMap.id);

    this.mapEmphasis = this.linkedMapsService.sourceMap.emphasis;

    this.form = this.fb.group({transferMapId: [null, Validators.required]});
  }

  ngOnDestroy() {
    this.linkableProgramsSub.unsubscribe();
  }

  loadLinkablePrograms() {
    //load the linkable maps for this map
    this.linkableProgramsSub = this.linkedMapsService
      .getLinkablePrograms().subscribe(linkablePrograms => {
        let distinctColleges = new Set();
        linkablePrograms.forEach(linkableProgram => {
          distinctColleges.add(linkableProgram.collegeName);
        });
        this.linkableColleges = Array.from(distinctColleges);
        this.linkablePrograms = linkablePrograms;
      })
  }

  cancel() {
    this.closeModal();
  }

  closeModal() {
    // Close this modal then re-open the "add" modal.
    this.router.navigate([{ outlets: { popup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(() => {
      this.router.navigate([{ outlets: { subPopup: ['linked-maps'] } }],
        { relativeTo: this.route.parent, skipLocationChange: true });
    });
  }

  submitForm() {
    this.requestingMapLink = true;

    let linkedProgramId = this.form.value.transferMapId;
    let linkableProgramSelected = this.linkablePrograms.filter(program => program.programId === linkedProgramId)[0];

    this.linkedMapsService.createPendingMapLink(this.linkedMapsService.sourceMap.id, linkableProgramSelected.collegeId, linkedProgramId).subscribe(
      (response: boolean) => {
        //this.messageService.add(`Link successfully created.`, 'icon-notify');
        this.closeModal();
        },
    );

  }

  filterCollegePrograms(filterBy: string) {
    this.partnerCollegeSelected = false;
    this.form.reset();

    this.collegeFilteredPrograms =
      this.linkablePrograms.filter(linkableProgram =>
      linkableProgram.collegeName == filterBy);
    this.partnerCollegeSelected = true;
  }
}
