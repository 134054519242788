import { NgModule } from '@angular/core';
import { CommonModule as CoreCommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { CourseGroupCreateComponent } from './custom-course-choice-create/custom-course-choice-create.component';
import { CustomCourseChoiceUpdateComponent } from './custom-course-choice-update/custom-course-choice-update.component';
import { SharedModule } from '../../../shared/shared.module';

import { MapControlsComponent } from './map-controls/map-controls.component';
import { ConfirmNavigationComponent } from './confirm-navigation/confirm-navigation.component';
import {CurriculumItemSelectModule} from "./curriculum-item-select/curriculum-item-select.module";
import { ConfirmRemoveTermComponent } from './confirm-remove-term/confirm-remove-term.component';
import { LinkedMapsModalComponent } from './linked-maps/linked-maps-modal/linked-maps-modal.component';
import { LinkedMapsUnlinkConfirmModalComponent } from './linked-maps/linked-maps-unlink-confirm-modal/linked-maps-unlink-confirm-modal.component';
import { LinkedMapsAddModalComponent } from './linked-maps/linked-maps-add-modal/linked-maps-add-modal.component';
import { PendingLinkedMapsUnlinkConfirmModalComponent } from './linked-maps/pending-linked-maps-unlink-confirm-modal/pending-linked-maps-unlink-confirm-modal.component';
import {
  LinkedMapsUpdateApprovalModalComponent
} from "./linked-maps/linked-maps-update-approval-modal/linked-maps-update-approval-modal.component";
import {LinkedMapsReviewApprovalModalComponent} from "./linked-maps/linked-maps-review-approval-modal/linked-maps-review-approval-model.component";
import {LinkedMapsPendingDeclinedUnlinkModalComponent} from "./linked-maps/linked-maps-pending-declined-unlink-modal/linked-maps-pending-decliend-unlink-modal.component";
import { LinkedMapsReviewActiveModalComponent } from './linked-maps/linked-maps-review-active-modal/linked-maps-review-active-modal.component';
import { LinkedMapsUnlinkPendingConfirmModalComponent } from './linked-maps/linked-maps-unlink-pending-confirm-modal/linked-maps-unlink-pending-confirm-modal.component';
import { MilestoneCreateComponent } from './milestone-create/milestone-create.component';
import { MilestoneUpdateComponent } from './milestone-update/milestone-update.component';

@NgModule({
  imports: [
    CoreCommonModule,
    RouterModule,
    ReactiveFormsModule,
    CurriculumItemSelectModule,
    SharedModule,
  ],
  declarations: [CourseGroupCreateComponent, CustomCourseChoiceUpdateComponent, MapControlsComponent, ConfirmNavigationComponent, ConfirmRemoveTermComponent, LinkedMapsModalComponent, LinkedMapsUnlinkConfirmModalComponent, PendingLinkedMapsUnlinkConfirmModalComponent, LinkedMapsAddModalComponent, PendingLinkedMapsUnlinkConfirmModalComponent,
    LinkedMapsUpdateApprovalModalComponent, LinkedMapsReviewApprovalModalComponent, LinkedMapsPendingDeclinedUnlinkModalComponent, LinkedMapsReviewActiveModalComponent, LinkedMapsUnlinkPendingConfirmModalComponent, MilestoneCreateComponent, MilestoneUpdateComponent],
  exports: [CoreCommonModule, RouterModule, ReactiveFormsModule, ConfirmNavigationComponent]
})
export class CommonModule { }
