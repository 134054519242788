import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseMapCardChoiceService } from '../course-map-card-choice.service';
import { CourseMapCard } from 'src/app/shared/programmapper-authoring.model';

@Component({
  selector: 'app-course-notes-update-modal',
  templateUrl: './course-notes-update-modal.component.html',
  styleUrls: ['./course-notes-update-modal.component.scss']
})
export class CourseNotesUpdateModalComponent implements OnInit {
  form: FormGroup;
  courseMapCard: CourseMapCard;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected fb: FormBuilder,
    protected courseMapCardChoiceService: CourseMapCardChoiceService
  ) { }

  ngOnInit() {
    this.courseMapCard = this.courseMapCardChoiceService.getSelectedCourseMap();

    if (!this.courseMapCard) {
      this.closeModalEmpty();
      return;
    }

    this.initializeForm();
  }

  initializeForm() {
    this.form = this.fb.group({
      courseNotes: [this.courseMapCard.courseNotes, [Validators.required, Validators.maxLength(1000)]],
    });
  }

  submitForm(): boolean {
    this.courseMapCard.courseNotes = this.form.value['courseNotes'];
    this.saveCardAndClose();
    return false;
  }

  removeNote() {
    this.courseMapCard.courseNotes = '';
    this.saveCardAndClose();
  }

  closeModalEmpty() {
    this.router.navigate([{ outlets: { subPopup: null, popup: null } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }

  cancel() {
    this.courseMapCardChoiceService.clearSelectedCourseMapCard();
    this.closeModalEmpty();
  }

  private saveCardAndClose() {
    this.courseMapCardChoiceService.updateCourseMapCard(this.courseMapCard);
    this.closeModalEmpty();
  }
}
