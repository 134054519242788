import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, ReplaySubject, Subject, Subscription, timer} from "rxjs/index";
import {
  MapExport,
  PublishedSiteVersion,
  PublishStatus,
  SiteContentPreview
} from "../../../shared/programmapper-authoring.model";
import {PublishService} from "../publish.service";
import {isNullOrUndefined} from "util";
import {LocalStorageService} from "ngx-webstorage";
import {AuthenticationService} from "../../../authentication.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-map-export',
  templateUrl: './map-export.component.html',
})
export class MapExportComponent implements OnInit, OnDestroy {

  publishedSiteVersionsSub: Subscription;
  publishedSiteVersionsLoaded: boolean;
  publishedSiteVersions: PublishedSiteVersion[];

  csvGenerating: boolean[] = [];

  mapExports: MapExport[] = [];

  private previewPollingSub: Subscription;
  private unsubscribe$ = new Subject();

  constructor(private publishService: PublishService) { }

  ngOnInit() {

    this.publishedSiteVersionsSub = this.publishService.getPublishedSiteVersions().subscribe(
      publishedSiteVersions => {
        this.publishedSiteVersions = publishedSiteVersions;
        this.publishedSiteVersionsLoaded = true;
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.publishedSiteVersionsSub.unsubscribe();
  }

  seperateSiteUrls(url: String){
    return url.split(",");
  }

  anyCSVGenerating() {
    return this.csvGenerating.some(value => value === true);
  }

  isGeneratePending(mapExport: MapExport) {
    return mapExport.publishStatus === PublishStatus.PENDING;
  }

  isGenerateCompleted(mapExport: MapExport) {
    return mapExport.publishStatus === PublishStatus.COMPLETED;
  }

  requestCSV(publishedSiteVersion: PublishedSiteVersion, index: number) {
    this.csvGenerating[index] = true;

    this.publishService.createSiteContentExport(publishedSiteVersion)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(mapExport => {
        const tick$: Observable<number> = timer(0, 5000); // first check is at 0 seconds;

        this.previewPollingSub = tick$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(t => {

            const x = this.publishService.getMapExport(mapExport.id)
              .pipe(takeUntil(this.unsubscribe$));

            x.subscribe(mapExport => {
              if (mapExport.publishStatus !== PublishStatus.PENDING) {
                this.previewPollingSub.unsubscribe();
                this.csvGenerating[index] = false;
              }
              this.mapExports[index] = mapExport;
            });
            return x;
          });
      });
  }

}
