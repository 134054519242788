import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { ConfirmNavigationService } from './confirm-navigation.service';
import {MapsService} from "../../maps.service";

@Component({
  selector: 'app-confirm-navigation',
  templateUrl: './confirm-navigation.component.html',
  styleUrls: ['./confirm-navigation.component.css']
})
export class ConfirmNavigationComponent implements OnInit {
  nextUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private confirmNavigationService: ConfirmNavigationService,
    private mapsService : MapsService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.nextUrl = paramMap.get('next');
    });
  }

  continueNavigation() {
    this.confirmNavigationService.allowLeave$.next(true);
    this.mapsService.clearUnsavedMapState();
    this.router.navigateByUrl(this.nextUrl);
  }

  closeModal() {
    this.router.navigate([{ outlets: { confirmLeave: null } }],
                         { relativeTo: this.route.parent });
  }
}
