import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../authentication.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MessageService} from '../../../shared/message/message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup;

  constructor(
    private messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.initializeForm();
    this.renderer.addClass(document.body, 'l-staticfooterpage-white');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'l-staticfooterpage-white');
  }

  initializeForm() {
    this.form = this.fb.group({
      'email': ['', [Validators.required, Validators.email]],
      'password': ['', Validators.required],
    });
  }

  submitForm() {
    this.authService.logIn(this.form.value.email, this.form.value.password).subscribe(() => {
      if (this.authService.isLoggedIn) {
        const redirectUrl = this.authService.redirectUrl || '/college';
        this.router.navigateByUrl(redirectUrl);
      } else {
        this.messageService.add('User name or password is incorrect.',
          'icon-alert', 'authalert-error');
      }
    },
      (error: HttpErrorResponse) => {
        this.messageService.add('User name or password is incorrect.',
          'icon-alert', 'authalert-error');
      });
  }
}
