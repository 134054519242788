import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CourseMapCard } from 'src/app/shared/programmapper-authoring.model';

@Injectable({
  providedIn: 'root'
})
export class CourseMapCardChoiceService {

  private _courseMapCardForEdit: CourseMapCard;
  
  private _courseMapCardUpdatedSource: Subject<CourseMapCard> = new Subject();
  courseMapCardUpdated$ = this._courseMapCardUpdatedSource.asObservable();

  constructor() { }

  public selectCourseMapCardForEdit(courseMapCard: CourseMapCard) {
    this._courseMapCardForEdit = courseMapCard;
  }

  public getSelectedCourseMap(): CourseMapCard {
    return this._courseMapCardForEdit;
  }

  public clearSelectedCourseMapCard() {
    this._courseMapCardForEdit = null;
  }

  updateCourseMapCard(courseMapCard: CourseMapCard) {
    this._courseMapCardUpdatedSource.next(courseMapCard);
  }
}
