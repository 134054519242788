import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

import {
  DataIssue,
  ErrorSeverity,
  PendingMapLink,
  ProgramMapSummary,
  ProgramWithMap,
  PublishedProgramSummary,
} from '../../../shared/programmapper-authoring.model';
import {MessageService} from '../../../shared/message/message.service';

import {MapsService} from '../maps.service';
import {MapListService} from "./map-list.service";
import {LinkedMapsService} from "../common/linked-maps/linked-maps.service";
import {forkJoin} from "rxjs/index";

export interface MapListRow{
  programMapSummary?: ProgramMapSummary;
  pendingMapLink?: PendingMapLink;
  errors: DataIssue[];
}

@Component({
  selector: 'app-map-list',
  templateUrl: './map-list.component.html',
  styleUrls: ['./map-list.component.css']
})
export class MapListComponent implements OnInit, OnDestroy {
  form: FormGroup;
  componentLoaded = false;
  program: PublishedProgramSummary;
  programWithMap: ProgramWithMap;
  mapListRows: MapListRow [];

  displayCustomCTA = false;
  updateCallToAction$: Subscription;
  totalPathwaysCount = 0;
  publicTransferMapsCount = 0;
  incompleteTransferMapsCount = 0;
  incomingTransferMapRequestCount = 0;
  //unconfirmedTransfers: ProgramMapSummary[] = [];
  private routeDataSub: Subscription;
  private programSub: Subscription;
  private programWithMapSub: Subscription;
  private unconfirmedTransfersSub: Subscription;
  private mapListUpdateSub: Subscription;
  mapListLoaded = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private mapsService: MapsService,
    private linkedMapsService: LinkedMapsService,
    //private ctaUpdateService: CtaUpdateService,
    private messageService: MessageService,
    private mapListService: MapListService
  ) { }

  ngOnInit() {

    // optionally, the route can include a query parameter about which pending map link 'link' modal to open
    let requestedPendingMapLinkId: string = this.route.snapshot.queryParamMap.get('selectedPendingMapLink');

    this.loadMapList(requestedPendingMapLinkId);

    this.mapListUpdateSub = this.mapListService.updateMapList.asObservable().subscribe(update => {
      if (update) {
        // TODO : verify should we pass requestedPendingMapLinkId?
        this.loadMapList(undefined);
        this.mapListService.updateMapList.next(false);
      }
    });
  }

  private loadMapList(requestedPendingMapLinkId: string | undefined) {
    this.mapListLoaded = false;
    this.mapListService.pendingMapLink = undefined;
    this.routeDataSub = this.route.parent.parent.data.subscribe((data: {
      mapsData: [PublishedProgramSummary, ProgramWithMap, string[]] }) => {
      const [program, programWithMap, _, ] = data.mapsData;
      if (programWithMap) {
        this.programWithMap = programWithMap;
      }
      this.program = program;

      this.programSub = this.mapsService.getPublishedProgram(program.masterRecordId).subscribe(
        (program: PublishedProgramSummary) => {
          // The /maps resolve guard doesn't re-trigger on child routes, refresh data.
          this.programWithMapSub = this.mapsService.getProgramWithMap(program.masterRecordId).subscribe(
            programWithMap => {
              // get all pending maps for this program

              this.programWithMap = programWithMap;
              this.program = program;

              if (this.programWithMap === null) {
                this.mapListLoaded = true;
              }

              if (this.programWithMap) {
                let mapListRows: MapListRow[] = this.programWithMap.maps.map(
                  programMapSummary => {
                    return {
                      programMapSummary: programMapSummary
                    } as MapListRow;
                  }
                );

                mapListRows = this.sortDefaultMapsToTop(mapListRows);
                // console.log(mapListRows);

                var selectedPendingMapLink;

                // load the unconfirmed map links from server.
                this.unconfirmedTransfersSub = this.linkedMapsService.getPendingMapLinksForLinkedProgram(program.masterRecordId).subscribe(pendingMapLinks => {

                  pendingMapLinks.forEach((pendingMapLink) => {
                    if (!pendingMapLink.isDeclined) {
                      // client side wrapper 'MapListRow' which allows for a mix of and Maps and PendingMapLinks within the limitations of the table widget
                      const mapListRow = {
                        pendingMapLink: pendingMapLink,
                        errors: [{severity: ErrorSeverity.WARNING}]
                        // mapId : transfer.mapId,
                        // errors: [{severity: ErrorSeverity.WARNING}],
                        // linkedMaps: [],
                        // isDefaultMap : false,
                        // linkedProgramId : transfer.linkedProgramId,
                        // isMissingYourMap : true,
                      } as MapListRow;

                      mapListRows.push(mapListRow);
                      if (requestedPendingMapLinkId && pendingMapLink.id == requestedPendingMapLinkId) {
                        selectedPendingMapLink = pendingMapLink;
                      }
                    }
                  });
                  this.calculateAllPathwayCounts();
                  if(requestedPendingMapLinkId && selectedPendingMapLink){
                    this.openFixMissingMapModal(selectedPendingMapLink)
                  }
                  this.mapListRows = mapListRows;
                  this.mapListLoaded = true;

                });
              }
            }
          );
        });

      //this.ctaUpdateService.initialize(program.programMapCTA);
      //this.listenForCTAUpdate();

      this.componentLoaded = true;

    });
  }

  calculateAllPathwayCounts() {
    let totalPathwaysCount = 0;
    let publicTransferMapsCount = 0;
    let incompleteTransferMapsCount = 0;
    let incomingTransferMapRequestCount = 0;
    let pendingMapLinksForLinkedPrograms$ = this.linkedMapsService.getPendingMapLinksForLinkedProgram(this.programWithMap.masterRecordId);

    forkJoin(pendingMapLinksForLinkedPrograms$).subscribe(([pendingMapLinksIn]) =>{
      pendingMapLinksIn.forEach(linkedPendingMapLink => {
        if(!linkedPendingMapLink.isDeclined){
          incomingTransferMapRequestCount++;
        }
      })

      this.programWithMap.maps.forEach( programMap =>{
        programMap.pendingMapLinks.forEach(pendingMapLink => {
          if(!pendingMapLink.isDeclined){
            totalPathwaysCount++;
            incompleteTransferMapsCount++;
          }
        })

        programMap.linkedMaps.forEach(mapLink => {
          totalPathwaysCount++;
          if(mapLink.isApproved == true && mapLink.linkedMapIsApproved == true && mapLink.map.status == "PUBLIC" && mapLink.linkedMap.status == "PUBLIC"){
            publicTransferMapsCount++;
          }
          else{
            incompleteTransferMapsCount++;
          }
        })

        if(programMap.linkedMaps.length == 0 && programMap.pendingMapLinks.length == 0){
          totalPathwaysCount++;
        }
      })

      this.totalPathwaysCount = totalPathwaysCount;
      this.publicTransferMapsCount = publicTransferMapsCount;
      this.incompleteTransferMapsCount = incompleteTransferMapsCount;
      this.incomingTransferMapRequestCount = incomingTransferMapRequestCount;
    })

  }

  sortDefaultMapsToTop(mapListRows: MapListRow[]) : MapListRow[]{
    if (mapListRows) {
      mapListRows  = mapListRows.sort((a, b) => {
        if(a.pendingMapLink){
          return -1;
        }
        else {
          if(a.programMapSummary.isDefaultMap){
            return -1;
          }
          if (a.programMapSummary.transferTo > b.programMapSummary.transferTo) {
            return 1;
          }
          if (b.programMapSummary.transferTo < a.programMapSummary.transferTo) {
            return -1;
          }
          return 0;
        }
      });
    }
    return mapListRows;
  }

  ngOnDestroy() {
    if (this.routeDataSub) this.routeDataSub.unsubscribe();
    if (this.programSub) this.programSub.unsubscribe();
    if (this.programWithMapSub) this.programWithMapSub.unsubscribe();
    if (this.unconfirmedTransfersSub) this.unconfirmedTransfersSub.unsubscribe();
    if (this.mapListUpdateSub) this.mapListUpdateSub.unsubscribe();
  }

  openAdvisingLinkModal() {
    this.router.navigate([{ outlets: { popup: ['edit-advising-link'] } }],
      { relativeTo: this.route, skipLocationChange: true });
  }

  openAdditionalInfoLinkModal() {
    this.router.navigate([{ outlets: { popup: ['edit-additional-info-link'] } }],
      { relativeTo: this.route, skipLocationChange: true });
  }

  openFixMissingMapModal(pendingMapLink: PendingMapLink) {
    this.mapListService.pendingMapLink = pendingMapLink;

    this.router.navigate([{ outlets: { popup: ['fix-missing-map'] } }],
      { relativeTo: this.route, skipLocationChange: true },);
  }

  openCopyMapModal(map: ProgramMapSummary, program: PublishedProgramSummary) {
    this.mapListService.mapToCopySummary = map;
    this.mapListService.programSummary = program;

    this.router.navigate([{ outlets: { popup: ['copy-map'] } }],
      { relativeTo: this.route, skipLocationChange: true },);
  }

  listenForModals() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(event.url.toLowerCase().includes("popup:")){
          this.calculateAllPathwayCounts();
        }
      }
    });
  }

  getProgramMapTotalTransfers(programMapSummary: ProgramMapSummary) {
    let pendingMapLinkCount = 0;
    programMapSummary.pendingMapLinks.forEach(pendingMapLink => {
      if(!pendingMapLink.isDeclined){
        pendingMapLinkCount++;
      }
    })
    const total = pendingMapLinkCount + programMapSummary.linkedMaps.length;
    return total;
  }
}
