import {RouterModule, Routes} from "@angular/router";
import {ChooseYearModalComponent} from "./choose-year-modal/choose-year-modal.component";
import {NgModule} from "@angular/core";

const routes: Routes = [
  {
    path: 'choose-year',
    component: ChooseYearModalComponent,
    outlet: 'popup',
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticatedRoutingModule { }