import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MilestoneService} from "../milestone.service";
import {MilestoneData} from "../../missing-types.type";
import {MilestoneMapCard} from "../../../../shared/programmapper-authoring.model";
import {linkTextValidator, linkUrlValidator} from "../builder.mixin";

@Component({
  selector: 'app-milestone-update',
  templateUrl: './milestone-update.component.html',
  styleUrls: ['./milestone-update.component.css']
})
export class MilestoneUpdateComponent implements OnInit {
  private form: FormGroup;

  constructor(
    protected fb: FormBuilder,
    protected milestoneService: MilestoneService
  ) { }

  ngOnInit() {
    this.initializeForm(this.milestoneService.milestoneCardForEdit);
  }

  initializeForm(milestoneMapCard: MilestoneMapCard) {
    const id: string = Math.random().toString(36).replace('0.', '');
    this.form = this.fb.group({
      id: id,
      title: [milestoneMapCard.title, [Validators.required, Validators.maxLength(100)]],
      shortDescription: [milestoneMapCard.shortDescription, [Validators.required, Validators.maxLength(100)]],
      longDescription: [milestoneMapCard.longDescription, [Validators.required, Validators.maxLength(1000)]],
      linkUrl: [milestoneMapCard.linkUrl, [Validators.pattern('^(https?:/\/\.*)') ]],
      linkText: [milestoneMapCard.linkText],
    }, { validators: [linkUrlValidator, linkTextValidator]});
  }

  submitForm(): boolean {
    const milestoneData = this.form.value as MilestoneData;
    this.milestoneService.milestoneAdded$.next(milestoneData);
    return false;
  }

  cancel() {
    this.milestoneService.milestoneAdded$.next(undefined);
  }
}
