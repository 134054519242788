import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { ApiService } from '../../../shared/core/api.service';
import {
  CollegeUser,
  CreateUserFromInvitationRequest,
  CreateUserFromInvitationResponse
} from '../../../shared/programmapper-authoring.model';


@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(
    private apiService: ApiService) { }

  createUserFromInvitation(createRequest: CreateUserFromInvitationRequest) {
    return this.apiService.post<CreateUserFromInvitationResponse,
      CreateUserFromInvitationRequest>(createRequest, `active-users`);
  }

  getInvitedUserByToken(invitationToken: string): Observable<CollegeUser> {
    return this.apiService.get<CollegeUser>('invited-user?invitationToken=' + invitationToken);
  }

}
