import {OnDestroy, OnInit, Renderer2} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {take, tap} from 'rxjs/operators';

import {GeneralService} from '../../college/general/general.service';

import {MessageService} from '../../../shared/message/message.service';
import {
  CollegeGeneralInfo,
  CourseListMapCard,
  CourseMapCard,
  CustomCourseChoiceMapCard,
  ErrorSeverity,
  GenEdAreaMapCard,
  MapCard,
  MapCardType,
  MapDataIssue,
  MapPreview,
  MapPreviewCreateRequest,
  MapStatus,
  MapYear,
  MilestoneMapCard,
  ProgramMap,
  PublishedCourseSummary,
  PublishedGeneralEducationAreaSummary,
  PublishedProgramSummary,
  Term,
} from '../../../shared/programmapper-authoring.model';

import {MapControlsService} from './map-controls/map-controls.service';
import {MapControlsAction} from './map-controls/map-controls.type';
import {CustomCourseChoiceService} from './custom-course-choice.service';
import {ConfirmNavigationService} from './confirm-navigation/confirm-navigation.service';

import {MapsService} from '../maps.service';

import {CourseType, MilestoneData} from '../missing-types.type';
import {CourseSelectService, SelectedCourseWithUnits,} from './curriculum-item-select/course-select.service';
import {courseListToMapCard, courseToMapCard, genEdAreaToMapCard} from '../map-card.utils';
import {isNullOrUndefined} from "util";
import {ConfirmRemoveTermService} from "./confirm-remove-term/confirm-remove-term.service";
import {LinkedMapsService} from "./linked-maps/linked-maps.service";
import {SelectedYearAndVersionService} from "../../selected-year-and-version.service";
import {MilestoneService} from "./milestone.service";
import {
  PrebuiltCourseListSelectService,
  SelectedPrebuiltCourseList
} from "./curriculum-item-select/prebuilt-courselist-select.service";
import { CourseMapCardChoiceService } from './course-map-card-choice.service';


export abstract class BuilderMixin implements OnInit, OnDestroy {
  form: FormGroup;
  componentHasChanges = false;
  componentLoaded = false;
  program: PublishedProgramSummary;
  map: ProgramMap;
  pathwaysTitles: string[];
  // courseTypes: any = ['core', 'general', 'elective', 'developmental'];
  courseTypes: any = ['core', 'general', 'elective'];
  initialMapItems: MapCard[] = [];
  table: any = [];
  listenSelectCourse: Subscription;
  listenSelectPrebuiltCourseList: Subscription;
  listenCreateCustomCourseList: Subscription;
  listenEditCustomCourseGroup: Subscription;
  listenCreateMilestone: Subscription;
  listenEditMilestone: Subscription;
  listenEditCourseNotes: Subscription;

  private initialTermsPerYear: number;
  mapYear: number;
  termNameDefaults: string[];

  mapPreview: MapPreview;
  activeMapControl$: Subscription;
  messageDropdownState = 'inactive';
//  private curriculumItemSelectDone$: Subscription;
  private customCourseGroupDone$: Subscription;
  showTermColumnLabelEdit: boolean[][] = [];

  messageDropdownClass = '';
  errors: MapDataIssue[] = [];
  private confirmRemoveTermSubscription: Subscription;
  private selectedMapYearSubscription: Subscription;

  disableRemoveButton = false;
  saving = false;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected fb: FormBuilder,
    protected generalService: GeneralService,
    protected mapsService: MapsService,
    protected customCourseListService: CustomCourseChoiceService,
    protected milestoneService: MilestoneService,
    protected courseSelectService: CourseSelectService,
    protected prebuiltCourseListSelectService: PrebuiltCourseListSelectService,
    protected mapControlsService: MapControlsService,
    protected confirmNavigationService: ConfirmNavigationService,
    protected renderer: Renderer2,
    protected messageService: MessageService,
    protected confirmRemoveTermService: ConfirmRemoveTermService,
    protected linkedMapsService: LinkedMapsService,
    protected selectedYearAndVersionService: SelectedYearAndVersionService,
    protected courseMapCardChoiceService: CourseMapCardChoiceService,
  ) { }

  canDeactivate(nextPath: string): Observable<boolean> {
    return this.confirmNavigationService.allowLeave$.pipe(
      tap((canLeave: boolean) => {

        if (! canLeave) {
          this.router.navigate([{ outlets: {
            confirmLeave: ['confirm-leave', { next: nextPath }]
          } }], { relativeTo: this.route, skipLocationChange: true });
        }

      })
    );
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'l-fixedfooterpage');
    this.renderer.addClass(document.body, 'l-staticfooterpage');

    if (this.listenSelectCourse && !this.listenSelectCourse.closed) {
      this.listenSelectCourse.unsubscribe();
    }
    if (this.listenCreateCustomCourseList && !this.listenCreateCustomCourseList.closed) {
      this.listenCreateCustomCourseList.unsubscribe();
    }
    if (this.listenEditCustomCourseGroup && !this.listenEditCustomCourseGroup.closed) {
      this.listenEditCustomCourseGroup.unsubscribe();
    }
    if (this.listenCreateMilestone && !this.listenCreateMilestone.closed) {
      this.listenCreateMilestone.unsubscribe();
    }
    if (this.listenEditMilestone && !this.listenEditMilestone.closed) {
      this.listenEditMilestone.unsubscribe();
    }
    if (this.activeMapControl$ && !this.activeMapControl$.closed) {
      this.activeMapControl$.unsubscribe();
    }
    if (this.customCourseGroupDone$ && !this.customCourseGroupDone$.closed) {
      this.customCourseGroupDone$.unsubscribe();
    }
    if (this.confirmRemoveTermSubscription && !this.confirmRemoveTermSubscription.closed) {
      this.confirmRemoveTermSubscription.unsubscribe();
    }
    if (this.selectedMapYearSubscription && !this.selectedMapYearSubscription.closed) {
      this.selectedMapYearSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.confirmNavigationService.allowLeave$.next(false);

    this.renderer.removeClass(document.body, 'l-staticfooterpage');
    this.renderer.addClass(document.body, 'l-fixedfooterpage');

    //this.registerCloseModalListeners();
    this.generalService.getCollegeGeneralInfo().subscribe((collegeGeneralInfo: CollegeGeneralInfo) =>
    {
      const initialYears = collegeGeneralInfo.newMapYears;
      this.termNameDefaults = collegeGeneralInfo.termNames;


      this.initialTermsPerYear = collegeGeneralInfo.defaultTermsPerYear;


      this.selectedMapYearSubscription = this.selectedYearAndVersionService.getCollegeCurriculumVersions().subscribe(
        (versionInfo) => {
          let version = versionInfo.filter(version => {
            return version.publishedCurriculumIri === collegeGeneralInfo.publishedCurriculumIri;
          });
          this.mapYear = version[0].year;

          this.initializeMapTable(this.initialTermsPerYear, initialYears);
          this.initializeForm();
          this.componentLoaded = true;
        }
      )
    });

    this.setClassOnMessageDropdown();
  }

  abstract initializeMapTable(initialTermsPerYear: number, initialYears: number) : void;

  abstract initializeForm(): void;


  protected convertMapYearsToTable(table: MapYear[]) {
// TODO: there's probably a slick operator to do this:
    const years: Object[] = new Array();
    table.forEach((mapYear, index) => {

      // Normally the year is saved to the term on map creation, but some maps were made before the year was
      // being saved, so this populates the year in the UI and it will be saved next time save button clicked.
      // The year is from the revision saved on college/general.
      mapYear.terms.forEach(term => {
        if (!term.year) {
          term.year = this.mapYear + index;
        }
      });

      years.push(mapYear.terms)
    });
    return years;
  }

  // registerCloseModalListeners() {
  //   this.curriculumItemSelectDone$ = this.courseSelectService.selectedCourseAndUnits$.subscribe(
  //     (_: SelectedCourseWithUnits) => this.closeCurriculumItemSelect());
  // }

  getMapItems(): MapCard[] {
    const mapItems: MapCard[] = [];
    for (let year = 0; year < this.table.length; year++) {
      for (let term = 0; term < this.table[year].length; term++) {
        const courseTypes: any = this.table[year][term];
        const courseMapItems: MapCard[][] = Object.values(courseTypes);
        const termMapItems: MapCard[] = courseMapItems.reduce(
          (flat, mapItems) => { flat.push(...mapItems); return flat; }, []);
        mapItems.push(...termMapItems);
      }
    }
    return mapItems;
  }

  getTitleDisplay(mapCard: MapCard){
    switch(mapCard.type){
      case MapCardType.VERTICAL_SPACE : {
        return 'VERTICAL SPACE'
      }
      case MapCardType.MILESTONE : {
        return (mapCard as MilestoneMapCard).title
      }
      case MapCardType.COURSE : {
        return (mapCard as CourseMapCard).title
      }
      case MapCardType.CUSTOM_COURSE_CHOICE : {
        return (mapCard as CustomCourseChoiceMapCard).title
      }
      case MapCardType.COURSE_LIST : {
        return (mapCard as CourseListMapCard).title
      }
      case MapCardType.GENERAL_EDUCATION_AREA : {
        const genEdMapCard = (mapCard as GenEdAreaMapCard);
        return genEdMapCard.generalEducationPattern + " / " + genEdMapCard.area + " / " + genEdMapCard.title;
      }
      default :
        throw new Error("unsupported map card type: " + mapCard.type);
    }

  }

  addMapCard(mapItem: MapCard, year: number, term: number, courseType: CourseType): void {
    if (mapItem) {
      this.table[year - 1][term - 1][courseType].push(mapItem);
    }
    this.setUnsavedMapState();
  }

  removeMapItem(year, term, courseType, objectIndex) {
    // Prevent a double click from removing 2 courses.
    this.disableRemoveButton = true;
    setTimeout(() => this.disableRemoveButton = false, 500);

    const mapItem = this.table[year][term][courseType][objectIndex];

    // TODO
    // if (courseyItem) {
    //   this.courseyItemSelectService.removeCourseyItem(courseyItem);
    // }

    this.table[year][term][courseType].splice(objectIndex, 1);
    this.setUnsavedMapState();
  }

  updateYearsToComplete($event: any) {
    const numYears = parseInt($event.target.value, 10),
      yearsInTable = this.table.length,
      years = [];

    if (numYears > yearsInTable) {
      this.table.push(...this.createYears(numYears - yearsInTable));
    } else {
      /*
       * TODO: With a warning, allow for
       * this.table = this.table.slice(numYears - 1);
       * and an interation of removeMapItem(year, term, courseType, objectIndex)
       */
    }
    this.setUnsavedMapState();
  }

  protected initializeFromUnsavedState(unsavedMapFormData: MapPreviewCreateRequest, unsavedMapDataIssues: MapDataIssue[]) {
    this.table = this.convertMapYearsToTable(unsavedMapFormData.table);
    this.setErrors(unsavedMapDataIssues);
  }

  protected setErrors(errors: MapDataIssue[]) {
    this.errors = errors;
    this.setClassOnMessageDropdown();
  }

  protected overrideFormFromUnsavedMapState(mapState: MapPreviewCreateRequest, isCreate: boolean, mapId: string, version: number) {

    let val = {
      programMasterRecordId: this.program.masterRecordId,
      transferTo: mapState.transferTo,
      mapStatus: mapState.mapStatus,
      status : (mapState.mapStatus === MapStatus.PUBLIC) ? true : false,
      isDefaultMap: mapState.isDefaultMap,
      emphasis: mapState.emphasis,
      // isIncludesDevelopmental: [false, Validators.required],
      // isIncludingBridgeTerm: [false, Validators.required],
      // isDisplayCustomCTA: [false, Validators.required],
    };
    if(!isCreate){
      let idFields = {
        id: mapId,
        version: version
      };
      Object.assign(val, idFields)
    }
    this.form.setValue(val);
  }

  onPathwayToChange() {
    this.setUnsavedMapState();
  }

  hasCriticalErrors(map:ProgramMap){

    if(isNullOrUndefined(map) || isNullOrUndefined(map.errors)){
      return false;
    }
    let numCriticalErrors = map.errors.filter(error =>
    error.severity === ErrorSeverity.CRITICAL)
      .length;

    return numCriticalErrors > 0;
  }

  onMapStatusChange() {
    this.setUnsavedMapState();
  }

  onMapDefaultChange() {
    this.setUnsavedMapState();
  }

  onMapEmphasisChange(){
    this.setUnsavedMapState();
  }

  getIdToDisplay(mapCard: MapCard){
    switch(mapCard.type){
      case MapCardType.COURSE: {
        return (mapCard as CourseMapCard).courseReference.courseCode;
      }
      case MapCardType.COURSE_LIST: {
        return 'COURSE LIST';
      }
      case MapCardType.GENERAL_EDUCATION_AREA: {
        return 'COURSE LIST';
      }
      case MapCardType.CUSTOM_COURSE_CHOICE: {
        return 'CUSTOM'
      }
      case MapCardType.MILESTONE: {
        return 'MILESTONE'
      }
    }
  }

  addCourseCard(yearIndex: number, termIndex: number, courseType: CourseType) {

    if (this.listenSelectCourse && ! this.listenSelectCourse.closed) {
      this.listenSelectCourse.unsubscribe();
    }

    this.listenSelectCourse = this.courseSelectService.selectedCourseAndUnits$
      .subscribe((curriculumItemWithUnits: SelectedCourseWithUnits) => {
        const mapCard: MapCard = courseToMapCard(curriculumItemWithUnits.course as PublishedCourseSummary, curriculumItemWithUnits.minUnits, curriculumItemWithUnits.maxUnits);
        this.addMapCard(mapCard, yearIndex + 1, termIndex + 1, courseType);
        this.listenSelectCourse.unsubscribe();
    });

    this.openCourseSelect();
  }

  addPrebuiltCourseListOrGenEdAreaCard(yearIndex: number, termIndex: number, courseType: CourseType) {

    // We may still be subscribed, fixes bug COL-1815.
    if (this.listenSelectCourse && !this.listenSelectCourse.closed) {
      this.listenSelectCourse.unsubscribe();
    }
    if (this.listenSelectPrebuiltCourseList && ! this.listenSelectPrebuiltCourseList.closed) {
      this.listenSelectPrebuiltCourseList.unsubscribe();
    }

    // listen for event that a prebuilt course list (and its units, if range) was selected
    this.listenSelectPrebuiltCourseList = this.prebuiltCourseListSelectService.selectedPrebuiltCourseListAndUnits$
      .subscribe((selectedPrebuiltCourseList: SelectedPrebuiltCourseList) => {
        let mapCard: MapCard;
        if(selectedPrebuiltCourseList) {
          if (selectedPrebuiltCourseList.prebuiltCourseList.type == 'COURSE_LIST') {
            mapCard = courseListToMapCard(selectedPrebuiltCourseList.prebuiltCourseList, selectedPrebuiltCourseList.minUnits, selectedPrebuiltCourseList.maxUnits, selectedPrebuiltCourseList.filterUnits);
          } else if (selectedPrebuiltCourseList.prebuiltCourseList.type == 'GENERAL_EDUCATION_AREA') {
            mapCard = genEdAreaToMapCard(selectedPrebuiltCourseList.prebuiltCourseList as PublishedGeneralEducationAreaSummary, selectedPrebuiltCourseList.minUnits, selectedPrebuiltCourseList.maxUnits, selectedPrebuiltCourseList.filterUnits);
          } else {
            throw new Error("not supported: " + selectedPrebuiltCourseList.prebuiltCourseList.type);
          }
          this.addMapCard(mapCard, yearIndex + 1, termIndex + 1, courseType);
        }
        this.listenSelectPrebuiltCourseList.unsubscribe();
      });

    this.openPrebuiltCourseListOrGenEdAreaSelect();
  }

  createCustomCourseList(yearIndex: number, termIndex: number, courseType: CourseType) {

    // We may still be subscribed, fixes bug COL-1815.
    if (this.listenSelectCourse && !this.listenSelectCourse.closed) {
      this.listenSelectCourse.unsubscribe();
    }

    if (this.listenCreateCustomCourseList && ! this.listenCreateCustomCourseList.closed) {
      this.listenCreateCustomCourseList.unsubscribe();
    }

    this.listenCreateCustomCourseList =
      this.customCourseListService.customCourseListMapCardAdded$.subscribe(
        (customCourseListMapCard: CustomCourseChoiceMapCard) => {
          if(customCourseListMapCard){
            this.addMapCard(customCourseListMapCard, yearIndex + 1, termIndex + 1, courseType);
          }
          this.listenCreateCustomCourseList.unsubscribe();
    });

    this.openCreateCustomCourseGroup();
  }

  createMilestone(yearIndex: number, termIndex: number) {

    if (this.listenCreateMilestone && !this.listenCreateMilestone.closed) {
      this.listenCreateMilestone.unsubscribe();
    }

    this.listenCreateMilestone =
      this.milestoneService.milestoneAdded$.subscribe(
        (milestoneData: MilestoneData) => {
          if(milestoneData){
            let milestoneMapCard = this.milestoneDataToMapCard(milestoneData);
            this.addMapCard(milestoneMapCard, yearIndex + 1, termIndex + 1, "milestone");
          }
          this.closePopups();
          this.listenCreateMilestone.unsubscribe();
        });

    this.openCreateMilestone();
  }

  private milestoneDataToMapCard(milestoneData: MilestoneData) {
    let milestoneMapCard: MilestoneMapCard = {
      title: milestoneData.title,
      shortDescription: milestoneData.shortDescription,
      longDescription: milestoneData.longDescription,
      linkText: milestoneData.linkText,
      linkUrl: milestoneData.linkUrl,
      type: MapCardType.MILESTONE
    }
    return milestoneMapCard;
  }

  initializeCourseSelectService() {
    this.courseSelectService.initialize([]);
  }

  openCreateCustomCourseGroup() {
    this.router.navigate([{ outlets: { subPopup: ['custom-course-choice-create'], popup: null } }],
                         { relativeTo: this.route, skipLocationChange: true });
  }

  openCreateMilestone() {
    this.router.navigate([{ outlets: { popup: ['milestone-create'] } }],
      { relativeTo: this.route, skipLocationChange: true });
  }

  openEditMilestone() {
    this.router.navigate([{ outlets: { popup: ['milestone-update'] } }],
      { relativeTo: this.route, skipLocationChange: true });
  }

  openCourseSelect() {
    this.initializeCourseSelectService();
    this.router.navigate([{ outlets: { popup: ['course-select']} }],
                         { relativeTo: this.route, skipLocationChange: true });
  }

  openPrebuiltCourseListOrGenEdAreaSelect() {
    this.router.navigate([{ outlets: { popup: ['course-list-select'], subPopup: null } }],
      { relativeTo: this.route, skipLocationChange: true });
  }

  closePopups() {
    this.router.navigate([{ outlets: { popup: null, subPopup: null } }],
                         { relativeTo: this.route, skipLocationChange: true });
  }

  openMapControlsFor(mapItem: MapCard, year, term, courseType, index: number) {
    const objectList = this.table[year][term][courseType];

    if (this.activeMapControl$ && ! this.activeMapControl$.closed) {
      this.activeMapControl$.unsubscribe();
    }

    this.mapControlsService.initialize<MapCard>(mapItem, {
      showRemove: true,
      showEdit: mapItem.type === MapCardType.CUSTOM_COURSE_CHOICE,
    });
    this.activeMapControl$ = this.mapControlsService.mapAction$.subscribe(
      (action: MapControlsAction) => {
        switch (action) {
          case MapControlsAction.EDIT:
            this.editCustomCourseList(objectList, index);
            break;
          case MapControlsAction.REMOVE:
            this.removeMapItem(year, term, courseType, index);
            this.closePopups();
            break;
          case MapControlsAction.MOVE_UP:
            this.updateOrder(objectList, index, index - 1);
            this.closePopups();
            break;
          case MapControlsAction.MOVE_DOWN:
            this.updateOrder(objectList, index, index + 1);
            this.closePopups();
            break;
      }
    });

    this.router.navigate([{ outlets: { popup: ['map-controls'] } }],
                         { relativeTo: this.route, skipLocationChange: true });
  }

  updateOrder(objectList: any[], index: number,  newIndex: number) {
    if (newIndex >= 0 && newIndex < objectList.length) {
      const movee = objectList[index],
        moved = objectList[newIndex];

      objectList[index] = moved;
      objectList[newIndex] = movee;
    }
    this.setUnsavedMapState();
  }

  createMapPreview() {
    const formData: MapPreviewCreateRequest = Object.assign({}, this.form.value);
    formData.table = this.convertTableToMapYearTable(this.table);

    if(this.form.controls.id){
      formData.originalMapId = this.form.controls.id.value;
    }

    this.mapsService.postMapPreview(formData).subscribe(
      (mapPreview: MapPreview) => {

        if(mapPreview.errors){
          this.setErrors(mapPreview.errors);
          this.setUnsavedMapState();
        }

        if(mapPreview.errors &&
          mapPreview.errors.filter(error => error.severity == ErrorSeverity.CRITICAL)
            .length > 0
        ){
          return;
        }
        else {
          this.router.navigate(['..', 'preview', mapPreview.id],
            { relativeTo: this.route });
        }
      },
      (error: HttpErrorResponse) => {
        this.messageService.add(
          `The server is unable to generate a preview for this map.`,
          'icon-alert', 'authalert-error');
      });
  }

  editCustomCourseList(objectList: MapCard[], customCourseGroupIndex: number) {
    // We may still be subscribed, fixes bug COL-1815.
    if (this.listenSelectCourse && !this.listenSelectCourse.closed) {
      this.listenSelectCourse.unsubscribe();
    }

    if (this.listenEditCustomCourseGroup && ! this.listenEditCustomCourseGroup.closed) {
      this.listenEditCustomCourseGroup.unsubscribe();
    }

    this.listenEditCustomCourseGroup =
      this.customCourseListService.customCourseListMapCardUpdated$.subscribe(
        (customCourseListMapCard: CustomCourseChoiceMapCard) => {
          if (customCourseListMapCard) {
            objectList[customCourseGroupIndex] = customCourseListMapCard;
          }
          this.listenEditCustomCourseGroup.unsubscribe();
    });

    const card = objectList[customCourseGroupIndex] as CustomCourseChoiceMapCard;
    let customCourseListMapCard = this.deepCopyCardForEdit(card);

    this.customCourseListService.selectCustomCourseListMapCardForEdit(customCourseListMapCard as CustomCourseChoiceMapCard);

    this.router.navigate([{
      outlets: { subPopup: ['custom-course-choice-update'], popup: null }
    }], { relativeTo: this.route, skipLocationChange: true });

  }

  openCourseNotesModal(objectList: MapCard[], courseMapCardIndex: number) {
    // We may still be subscribed, fixes bug COL-1815.
    if (this.listenSelectCourse && !this.listenSelectCourse.closed) {
      this.listenSelectCourse.unsubscribe();
    }

    if (this.listenEditCustomCourseGroup && !this.listenEditCustomCourseGroup.closed) {
      this.listenEditCustomCourseGroup.unsubscribe();
    }

    if (this.listenEditCourseNotes && !this.listenEditCourseNotes.closed) {
      this.listenEditCourseNotes.unsubscribe();
    }

    const card = objectList[courseMapCardIndex] as CourseMapCard;
    this.courseMapCardChoiceService.selectCourseMapCardForEdit(card);

    // subscribe to changes taking place in the modal
    this.listenEditCourseNotes = this.courseMapCardChoiceService.courseMapCardUpdated$.subscribe(
      (updatedCourseMapCard: CourseMapCard) => {
        if (updatedCourseMapCard) {
          objectList[courseMapCardIndex] = updatedCourseMapCard;
        }
        this.listenEditCourseNotes.unsubscribe();
      }
    )

    this.router.navigate([{
      outlets: { subPopup: ['course-notes-update'], popup: null }
    }], { relativeTo: this.route, skipLocationChange: true });
  }

  private deepCopyCardForEdit(card: CustomCourseChoiceMapCard) {

    let customCourseListMapCard: CustomCourseChoiceMapCard =
      {
        courseReferences: card.courseReferences.map(it => it), // deep copy. fixes bug where editing custom group's courses didn't cancel properly
        title: card.title,
        minUnits: card.minUnits,
        maxUnits: card.maxUnits,
        shortDescription: card.shortDescription,
        longDescription: card.longDescription,
        isCalculateUnits: card.isCalculateUnits,
        dualRequirement: card.dualRequirement,
        type: card.type
      };
    return customCourseListMapCard;
  }

  updateDualRequirement(dualRequirement, yearIndex: number, termIndex: number, type, mapItemIndex: number) {
    if(!dualRequirement  || dualRequirement == "null"){
      dualRequirement = null;
    }
    this.table[yearIndex][termIndex][type][mapItemIndex].dualRequirement = dualRequirement;
  }

  editMilestoneCard(objectList: MapCard[], milestoneIndex: number) {
    if (this.listenEditMilestone && ! this.listenEditMilestone.closed) {
      this.listenEditMilestone.unsubscribe();
    }

    this.listenEditMilestone =
      this.milestoneService.milestoneAdded$.subscribe(
        (milestoneData: MilestoneData) => {
          if(milestoneData){
            let milestoneMapCard = this.milestoneDataToMapCard(milestoneData);
            objectList[milestoneIndex] = milestoneMapCard;
          }
          this.closePopups();
          this.listenEditMilestone.unsubscribe();
        });

    const mapItem: MapCard = objectList[milestoneIndex];
    this.doEditMilestoneData(mapItem as MilestoneMapCard);
  }

  private doEditMilestoneData(milestoneMapCard: MilestoneMapCard) {

    this.milestoneService.milestoneCardForEdit = milestoneMapCard;

    this.openEditMilestone();
  }

  computeTermUnits(term: Term): string {

    const allMapItems = [].concat(term.core)
      .concat(term.general)
      .concat(term.elective);

    const totalMinUnits = allMapItems.reduce(
      (totalUnits: number, mapItem: MapCard) => {
        return totalUnits + this.getMinUnitsForMapCard(mapItem)
      }, 0);

    const totalMaxUnits = allMapItems.reduce(
      (totalUnits: number, mapItem: MapCard) => {
        return totalUnits + this.getMaxUnitsForMapCard(mapItem)
      }, 0);

    if (totalMinUnits === totalMaxUnits) {
      return totalMinUnits;
    } else {
      return totalMinUnits + ' - ' + totalMaxUnits;
    }
  }

  protected convertTableToMapYearTable(table) {
    const mapYears: MapYear[] = new Array();
    table.forEach(row => {
      const terms: Term[] = new Array(...row);
      mapYears.push({terms: terms})
    })
    return mapYears;
  }

  private getMinUnitsForMapCard(mapItem: MapCard) {
    switch (mapItem.type){
      case MapCardType.VERTICAL_SPACE : {
        return 0;
      }
      case MapCardType.GENERAL_EDUCATION_AREA : {
        return (mapItem as GenEdAreaMapCard).minUnits;
      }
      case MapCardType.COURSE_LIST : {
        return (mapItem as CourseListMapCard).minUnits;
      }
      case MapCardType.COURSE: {
        return (mapItem as CourseMapCard).minUnits;
      }
      case MapCardType.CUSTOM_COURSE_CHOICE: {
        return (mapItem as CustomCourseChoiceMapCard).minUnits;
      }
    }
  }


  private getMaxUnitsForMapCard(mapItem: MapCard) {
    switch (mapItem.type){
      case MapCardType.VERTICAL_SPACE : {
        return 0;
      }
      case MapCardType.GENERAL_EDUCATION_AREA : {
        return (mapItem as GenEdAreaMapCard).maxUnits;
      }
      case MapCardType.COURSE_LIST : {
        return (mapItem as CourseListMapCard).maxUnits;
      }
      case MapCardType.COURSE: {
        return (mapItem as CourseMapCard).maxUnits;
      }
      case MapCardType.CUSTOM_COURSE_CHOICE: {
        return (mapItem as CustomCourseChoiceMapCard).maxUnits;
      }
    }
  }

  goBack() {
    this.router.navigate(['../../list'], { relativeTo: this.route });
  }

  toggleMessageDropdown() {
    this.messageDropdownState =
      (this.messageDropdownState === 'inactive') ? 'active' : 'inactive';
  }

  setClassOnMessageDropdown() {
    if (this.errors && this.errors.length > 0) {
      this.messageDropdownClass = 'warning';
      this.errors.forEach((item) => {
        if (item.severity === ErrorSeverity.CRITICAL) {
          this.messageDropdownClass = 'critical';
        }
      });
    }
  }

  private setUnsavedMapState() {
    const formData: MapPreviewCreateRequest = Object.assign({}, this.form.value);
    formData.table = this.convertTableToMapYearTable(this.table);
    this.mapsService.setUnsavedMapState(formData, this.errors);
    this.componentHasChanges = true;
  }

  protected createYearWithEmptyTerms(initialTermsPerYear: number, yearIncrease: number) : Term[]{
    const terms = [];

    for (let term = 1; term <= initialTermsPerYear; term++) {
      terms.push(this.createTerm(term - 1, yearIncrease));
    }
    return terms;
  }

  private createTerm(term: number, yearIncrease: number) {
    return {
      customLabel: this.termNameDefaults[term],
      year: this.mapYear + yearIncrease,
      'core': [],
      'general': [],
      'elective': [],
      'milestone': [],
    };
  }

  private createUntitledTerm(yearIndex: number) {
    return {
      customLabel: 'Untitled',
      year: this.mapYear + yearIndex,
      'core': [],
      'general': [],
      'elective': [],
      'developmental': [],
      'milestone': [],
    };
  }

  private createYears(numYears: number) : Term[][] {
    const years = [];

    for (let year = 1; year <= numYears; year++) {
      const terms = this.createYearWithEmptyTerms(this.initialTermsPerYear, this.table.length);
      years.push(terms);
    }

    return years;
  }

  toggleHide(yearIndex, termIndex){
    // console.log("toggle hide")
    if(this.table[yearIndex][termIndex].hidden){
      this.table[yearIndex][termIndex].hidden = false;
    }
    else{
      this.table[yearIndex][termIndex].hidden = true;
    }
  }

  placeholderLabel(currentYearIndex: number, currentTermIndex: number){
    let termCount = 0;
    for (let yearIndex = 0; yearIndex < this.table.length; yearIndex++) {
      for (let termIndex = 0; termIndex < this.table[yearIndex].length; termIndex++) {
        termCount++;
        if(currentYearIndex === yearIndex && currentTermIndex == termIndex){
          return this.formatTermLabel(termCount);
        }
      }
    }
  }

  private formatTermLabel(termCount: number) {
    switch(termCount){
      case 1: {
        return '1st Term'

      }
      case 2: {
        return '2nd Term'
      }
      case 3: {
        return '3rd Term'
      }
      default: {
        return termCount + 'th Term'
      }

    }
  }

  label(year: number, term: number) {
    if(!isNullOrUndefined(this.table[year][term].customLabel)){
      return this.table[year][term].customLabel;
    }
    return '';
  }

  setLabel(label: string, year: number, term: number) {
    if(!isNullOrUndefined(label) && label.length > 0){
      this.table[year][term].customLabel = label;
    }
    else{
      this.table[year][term].customLabel = null;
    }
  }

  toggleColumnEdit(year: number, term: number, open: boolean) {
    if (this.showTermColumnLabelEdit[year] === undefined) {
      this.showTermColumnLabelEdit[year] = [];
    }
    this.showTermColumnLabelEdit[year][term] = open;
  }

  insertTermAfter(yearIndex: number, term: number) {
    const insertedTerm = this.createUntitledTerm(yearIndex);
    this.table[yearIndex].splice(term + 1, 0, insertedTerm);

    //reset any term editing state (term indexes have change)
    this.showTermColumnLabelEdit = [];
  }

  openRemoveTermModal(year: number, term: number) {

    let termName;

    if (this.table[year][term].customLabel) {
      termName = this.table[year][term].customLabel;
    } else {
      termName = 'Term ' + (term + 1);
    }

    this.confirmRemoveTermService.termNameSubject.next(termName);

    this.confirmRemoveTermSubscription = this.confirmRemoveTermService.removeTermObservable$.pipe(take(1)).subscribe(
      (result) => {
        if (result) {
          this.removeTerm(year, term);
        }
      }
    );

    this.router.navigate([{ outlets: { popup: ['confirm-remove-term'] } }],
      { relativeTo: this.route, skipLocationChange: true });
  }

  removeTerm(year: number, term: number) {
    this.table[year].splice(term, 1);
    this.showTermColumnLabelEdit = [];
    this.removeEmptyYears();
  }

  private removeEmptyYears() {
    // Deleting all terms from a year causes empty year(s) in table
    // this removes the empty years(s) and resets the others.

    // Remove the empty years from table.
    this.table = this.table.filter(mapYear => {
      return mapYear.length !== 0;
    });

    // Reset the years.
    this.table.forEach((mapYear, index) => {
      mapYear.forEach(term => {
        term.year = this.mapYear + index;
      });
    });
  }

  insertTermBefore(yearIndex: number, term: number){
    const insertedTerm = this.createUntitledTerm(yearIndex);
    this.table[yearIndex].splice(term, 0, insertedTerm);

    //reset any term editing state (term indexes have change)
    this.showTermColumnLabelEdit = [];
  }

  getTermColumnLabelEdit(year: number, term: number) {
    if (this.showTermColumnLabelEdit[year] === undefined) {
      return false;
    }
    if (this.showTermColumnLabelEdit[year][term] === undefined) {
      return false;
    }
    return this.showTermColumnLabelEdit[year][term];
  }

  openLinkedMapsModal() {

    this.linkedMapsService.sourceMap = this.map;

    // TODO: load linked maps

    this.linkedMapsService.program = this.program;

    this.router.navigate([{ outlets: { subPopup: ['linked-maps'] } }],
      { relativeTo: this.route, skipLocationChange: true });
  }

  addYear() {
    this.table.push(...this.createYears(1));
    this.setUnsavedMapState();
  }

  getDisplayedYear(yearIndex: number, termIndex: number) {

    // The first term in a year can't be adjusted.
    if (termIndex === 0) {

      // When removing a term it's possible for the 2nd term to become the first and have an adjustment,
      // this just sets all the first terms in a year to not be adjusted.
      this.table[yearIndex][termIndex].yearAdjustment = false;

      return this.table[yearIndex][termIndex].year;
    }

    if (this.table[yearIndex][termIndex].yearAdjustment === true) {
      // If the year is adjusted it's always lower.
      return this.table[yearIndex][termIndex].year;
    } else {
      // If not adjusted all term years other than the first are incremented by one.
      return this.table[yearIndex][termIndex].year + 1;
    }
  }

  updateYearAdjustment(yearIndex: number, termIndex: number, event) {
    if (event.target.value === 'true') {
      this.table[yearIndex][termIndex].yearAdjustment = true;
    } else {
      this.table[yearIndex][termIndex].yearAdjustment = false;
    }
  }
}

export const linkUrlValidator: ValidatorFn =
  (control: FormGroup): ValidationErrors | null =>
    (!control.get('linkUrl').value && !!control.get('linkText').value) ?
      { 'linkTextIsEmpty': true } : null;

export const linkTextValidator: ValidatorFn =
  (control: FormGroup): ValidationErrors | null =>
    (!control.get('linkText').value && !!control.get('linkUrl').value) ?
      { 'linkUrlIsEmpty': true } : null;
