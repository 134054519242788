import { Injectable } from '@angular/core';
import { Observable} from "rxjs";
import {
  LinkableProgram, MapLink, MapLinkRequest, MapPreview, MapPreviewCreateRequest, PendingMapLink, PendingMapLinkRequest,
  ProgramMap, ProgramMapSummary, ProgramWithMap,
  PublishedProgramSummary
} from "../../../../shared/programmapper-authoring.model";
import {isNullOrUndefined} from "util";
import {ApiService} from "../../../../shared/core/api.service";

@Injectable({
  providedIn: 'root'
})
export class LinkedMapsService {

  program: PublishedProgramSummary;
  mapToUnlink: MapLink;
  pendingMapToUnlink: PendingMapLink;
  sourceMap: ProgramMap;

  constructor(private apiService: ApiService) { }

  getLinkablePrograms(): Observable<LinkableProgram[]> {
    return this.apiService.getCollege<LinkableProgram[]>(`linkable-programs`);
  }

  getLinkableMaps(programId: string, linkedMapId): Observable<ProgramMapSummary[]> {
    return this.apiService.getCollege<ProgramMapSummary[]>(`mapped-programs/${programId}/linkable-maps?linkedMapId=${linkedMapId}`);
  }

  createPendingMapLink(mapId: string, linkedCollegeId: string, linkedProgramId: string) : Observable<boolean> {
    if(isNullOrUndefined(mapId)){
      throw new Error("map id cannnot be undefined");
    }
    if(isNullOrUndefined(linkedProgramId)){
      throw new Error("linkedProgramId id cannnot be undefined");
    }
    return this.apiService.postCollege<boolean, PendingMapLinkRequest>(
      {
        linkedCollegeId: linkedCollegeId,
        linkedProgramId: linkedProgramId
      },
      `program-maps/${mapId}/pending-links`);
  }

  declinePendingLink(mapId: string, linkedProgramId: string) : Observable<boolean> {
    return this.apiService.postCollege<boolean, string>( linkedProgramId, `program-maps/${mapId}/pending-links/decline`)
  }

  postLinkedMapPreview(mapId: string, linkedMapId: string): Observable<MapPreview> {
    return this.apiService.postCollege<MapPreview, MapPreviewCreateRequest>(null, `program-maps/${mapId}/map-links/${linkedMapId}/map-previews`);
  }

  postLinkedMapPreviewWithPendingLink(mapId: string, pendingMapLinkId: string) : Observable<MapPreview> {
    return this.apiService.postCollege<MapPreview, MapPreviewCreateRequest>(null, `program-maps/${mapId}/pending-links/${pendingMapLinkId}/map-previews`);
  }

  approveMapLink(mapId: string, linkedMapId: string) : Observable<boolean>{
    return this.apiService.postCollege<boolean, any>(null,
      `program-maps/${mapId}/map-links/${linkedMapId}/approve-map-link`)
  }

  getPendingMapLinksForLinkedProgram(programId: string) : Observable<PendingMapLink[]> {
    return this.apiService.getCollege<PendingMapLink[]>(`mapped-programs/${programId}/pending-links`);
  }

  completeMapLink(mapId: string, linkedProgramId: string, linkedMapId: string) : Observable<boolean> {
    if(isNullOrUndefined(mapId)){
      throw new Error("map id cannnot be undefined");
    }
    if(isNullOrUndefined(linkedMapId)){
      throw new Error("linkedMapId id cannnot be undefined");
    }
    if(isNullOrUndefined(linkedProgramId)){
      throw new Error("linkedProgramId id cannnot be undefined");
    }
    return this.apiService.postCollege<boolean, MapLinkRequest>(
      {
        linkedMapId : linkedMapId,
        linkedProgramId : linkedProgramId,
      },
      `program-maps/${mapId}/map-links`);
  }

  completeMapLinkWithoutLinkedProgramId(mapId: string, pendingMapLinkId: string) : Observable<boolean> {
    if(isNullOrUndefined(mapId)){
      throw new Error("map id cannot be undefined");
    }
    if(isNullOrUndefined(pendingMapLinkId)){
      throw new Error("pendingLinkMapId id cannot be undefined");
    }

    return this.apiService.putCollege<null>(null, `program-maps/${mapId}/pending-map-link/${pendingMapLinkId}/map-links`);
  }

  deleteMapLink(mapId: string, linkedMapId: string) : Observable<boolean> {
    if(isNullOrUndefined(mapId)){
      throw new Error("map id cannnot be undefined");
    }
    if(isNullOrUndefined(linkedMapId)){
      throw new Error("linked map id cannnot be undefined");
    }
    return this.apiService.deleteCollege<boolean>(
      `program-maps/${mapId}/map-links/${linkedMapId}`);
  }

  declinePendingMapLink(mapId: string, linkedProgramId: string) : Observable<boolean> {
    if(isNullOrUndefined(mapId)){
      throw new Error("map id cannnot be undefined");
    }
    if(isNullOrUndefined(linkedProgramId)){
      throw new Error("linkedProgramId cannnot be undefined");
    }
    return this.apiService.postCollege<boolean, string>(
      linkedProgramId, `program-maps/${mapId}/pending-links/decline`);
  }


  deletePendingMapLink(mapId: string, linkedProgramId: string) : Observable<boolean> {
    if(isNullOrUndefined(mapId)){
      throw new Error("map id cannnot be undefined");
    }
    if(isNullOrUndefined(linkedProgramId)){
      throw new Error("linkedProgramId cannnot be undefined");
    }
    return this.apiService.deleteCollege<boolean>(
      `program-maps/${mapId}/pending-links/${linkedProgramId}`);
  }

  getLinkedMaps(mapId: string) : Observable<MapLink[]>{
    if(isNullOrUndefined(mapId)){
      throw new Error("map id cannnot be undefined");
    }
    return this.apiService.getCollege<MapLink[]>(`program-maps/${mapId}/links`);
  }

  calculatePublicTransferMapsCount(programWithMap : ProgramWithMap) {
    let count = 0;

    if (programWithMap) {
      programWithMap.maps.forEach((map) => {
        count = count + this.countPublicCompletedLinks(map);
      });
      return count;
    }
  }

  countPublicCompletedLinks(map: ProgramMapSummary) {
    let count = 0;
    // If it has linked maps and an id its a transfer map.
    if (map.linkedMaps) {
      let completeLinks = map.linkedMaps.filter(
        mapLink => mapLink.isApproved && mapLink.linkedMapIsApproved
      )
        .filter(mapLink => (mapLink.linkedMap.status == "PUBLIC" && mapLink.map.status == "PUBLIC"));
      count = completeLinks.length;
    }
    return count;
  }

  calculateIncompleteTransferMapsCount(programWithMap: ProgramWithMap) {
    let count = 0;

    if (programWithMap) {
      programWithMap.maps.forEach((map) => {
        count = count + this.countIncompleteMapLinks(map);
      });
      return count;
    }
  }

  public countIncompleteMapLinks(map: ProgramMapSummary) {
    let count = 0;
    // If it has linked maps and an id its a transfer map.
    if (map.linkedMaps && map.linkedMaps.length > 0) {
      let completeLinks = map.linkedMaps
        .filter(
        mapLink => {
          if(!mapLink.isApproved){
            return true;
          }
          if(!mapLink.linkedMapIsApproved){
            return true;
          }
          if(mapLink.map.status != "PUBLIC"){
            return true;
          }
          if(mapLink.linkedMap.status != "PUBLIC"){
            return true;
          }
          return false;
        }
      )
      count = count + completeLinks.length;
    }
    return count;
  }

  getLinkedMap(mapId: string, linkedMapId: string) : Observable<MapLink>{
    if(isNullOrUndefined(mapId)){
      throw new Error("map id cannot be undefined");
    }
    if(isNullOrUndefined(linkedMapId)){
      throw new Error("linked-map id cannnot be undefined");
    }

    return this.apiService.getCollege<MapLink>(``);
  }

  getPendingLinksByMapsId(mapId: string) : Observable<PendingMapLink[]>{
    if(isNullOrUndefined(mapId)){
      throw new Error("map id cannnot be undefined");
    }
    return this.apiService.getCollege<PendingMapLink[]>(`program-maps/${mapId}/pending-links`);
  }

  getPendingLinksForMultipleMaps(maps: ProgramMapSummary[]) : Observable<PendingMapLink[]>{
    if(isNullOrUndefined(maps)){
      throw new Error("maps cannot be undefined");
    }
    return this.apiService.postCollege<PendingMapLink[], ProgramMapSummary[]>(maps, `program-maps/pending-links`);
  }

  // your own pending (ie, 'sent request') links
  getPendingLinkedMaps(mapId: string) : Observable<PendingMapLink[]>{
    if(isNullOrUndefined(mapId)){
      throw new Error("map id cannnot be undefined");
    }
    return this.apiService.getCollege<PendingMapLink[]>(`program-maps/${mapId}/pending-links`);

  }

}
