import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-anonymous',
  templateUrl: './anonymous.component.html',
  styleUrls: ['./anonymous.component.css']
})
export class AnonymousComponent implements OnInit {
  @ViewChild('main') main: ElementRef;

  ngOnInit() {

  }

  skipLink() {
    this.main.nativeElement.focus();
  }

}
