import {Component, OnInit} from '@angular/core';
import {appVersion} from '../../app.version';
import {Observable} from "rxjs";
import {ApiService} from "../../shared/core/api.service";
import {map} from "rxjs/operators";

export interface ServerVersion{
  version: string;
}

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  uiVersion: string;
  serverVersion$: Observable<ServerVersion>;

  constructor(private apiUrlService: ApiService){

  }

  ngOnInit() {
    this.uiVersion = appVersion;
    this.serverVersion$ = this.apiUrlService.get<ServerVersion>("info");
  }

}
