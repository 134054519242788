import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignUpFromInvitationComponent } from './sign-up-from-invitation/sign-up-from-invitation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    LoginRoutingModule,
    SharedModule,
  ],
  declarations: [LoginComponent, ResetPasswordComponent, ForgotPasswordComponent, SignUpFromInvitationComponent]
})
export class LoginModule { }
