import {Component, OnInit} from "@angular/core";
import {NotificationsService} from "../../../../notifications/notifications.service";
import {MessageService} from "../../../../../shared/message/message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MapsService} from "../../../maps.service";
import {LinkedMapsService} from "../linked-maps.service";
import {MapLink, MapPreview, PublishStatus} from "../../../../../shared/programmapper-authoring.model";
import {Observable, ReplaySubject, Subject, Subscription, timer} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-linked-maps-update-approval-modal',
  templateUrl: './linked-maps-update-approval-modal.component.html',
})

export class LinkedMapsUpdateApprovalModalComponent implements OnInit{

  private currentSiteContentPreviewSource = new ReplaySubject<MapPreview>();
  currentSiteContentPreview$ : Observable<MapPreview> = this.currentSiteContentPreviewSource.asObservable();
  private previewPollingSub: Subscription;
  private unsubscribe$ = new Subject();
  mapId : string;
  linkedMapId : string;
  saving = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              protected mapsService: MapsService,
              private linkedMapsService: LinkedMapsService,
  ) { }

  ngOnInit() {

  }

  closePopup(){
   this.closeModal();
  }

  approveMapLink() {
    this.saving = true;

    this.linkedMapsService.approveMapLink(this.linkedMapsService.mapToUnlink.map.mapId, this.linkedMapsService.mapToUnlink.linkedMap.mapId).subscribe(() => {
      //then route us back
      this.closeModal();
    })
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { subPopup: ['linked-maps'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );
  }

  hasCriticalDataIssues() {
    return false; // TODO: this may be a problem, need to handle in the response (one or more maps not previewable)
  }

  isPreviewPending(siteContentPreview: MapPreview) {
    return siteContentPreview.publishStatus === PublishStatus.PENDING;
  }

  isPreviewCompleted(siteContentPreview: MapPreview) {
    return siteContentPreview.publishStatus === PublishStatus.COMPLETED;
  }

  requestPreview() {
    this.linkedMapsService.postLinkedMapPreview(this.linkedMapsService.mapToUnlink.map.mapId, this.linkedMapsService.mapToUnlink.linkedMap.mapId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(mapPreview => {
        const tick$: Observable<number> = timer(0, 5000); // first check is at 0 seconds;

        this.previewPollingSub = tick$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(t => {

            const x = this.mapsService.getMapPreview(mapPreview.id)
              .pipe(takeUntil(this.unsubscribe$));

            x.subscribe(siteContentPreview => {
              if (siteContentPreview.publishStatus !== PublishStatus.PENDING) {
                this.previewPollingSub.unsubscribe();
              }
              this.currentSiteContentPreviewSource.next(siteContentPreview);
            });

            return x;
          });
      });
  }

  unlinkMaps() {

    this.router.navigate([{ outlets: { popup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { popup: ['linked-maps-unlink'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );
  }
}
