import { OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { MapControlsService } from './map-controls/map-controls.service';
import { MapControlsAction } from './map-controls/map-controls.type';

import { CustomCourseChoiceService } from './custom-course-choice.service';
import {
  CourseInCustomList, CustomCourseChoiceMapCard
} from '../../../shared/programmapper-authoring.model';
import {SelectedCourseWithUnits, CourseSelectService} from './curriculum-item-select/course-select.service';


export class CustomCourseChoiceMixin implements OnDestroy {
  form: FormGroup;
  customCourseChoiceMapCard: CustomCourseChoiceMapCard;
  tableItems: CourseInCustomList[] = [];
  listenSelectCourse: Subscription;
  listenMapControls: Subscription;
  calculatedMinUnits;
  calculatedMaxUnits;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected fb: FormBuilder,
    protected courseSelectService: CourseSelectService,
    protected customCourseListService: CustomCourseChoiceService,
    protected mapControlsService: MapControlsService,
  ) { }

  ngOnDestroy() {
    if (this.listenSelectCourse && ! this.listenSelectCourse.closed) {
      this.listenSelectCourse.unsubscribe();
    }
    if (this.listenMapControls && ! this.listenMapControls.closed) {
      this.listenMapControls.unsubscribe();
    }
  }

  closeModalEmpty() {
    this.router.navigate([{ outlets: { subPopup: null, popup: null } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }

  cancel() {
    this.customCourseListService.clearSelectedCustomCourseListMapCard()
    this.closeModalEmpty();
  }

  selectCourse() {
    // TODO: Don't duplicate util
    if (this.listenSelectCourse && ! this.listenSelectCourse.closed) {
      this.listenSelectCourse.unsubscribe();
    }

    const selectedCourseMasterIds: string[] = this.tableItems.map(
      (courseInCustomList: CourseInCustomList) =>
        courseInCustomList.masterRecordId);

    // Initialize the curriculumItemSelectService to set which courses are already selected.
    this.courseSelectService.initialize(selectedCourseMasterIds);

    this.listenForSelectedCourseLoop();

    this.openCourseItemSelect();
  }

  listenForSelectedCourseLoop() : void{
    this.listenSelectCourse = this.courseSelectService.selectedCourseAndUnits$.subscribe(
      (selectedCourseWithUnits : SelectedCourseWithUnits) => {
        this.tableItems.push(this.mapSelectedCourseWithUnitsToCourseInCustomList(selectedCourseWithUnits));
        this.courseSelectService.resetSelectedCurriculumItemAndUnits();
        this.listenSelectCourse.unsubscribe();
        this.computeMinMaxUnits();
        this.listenForSelectedCourseLoop();
      }
    )
  }

  removeMapItem(tableItem: CourseInCustomList, index: number) {
    this.courseSelectService.removeCourse(tableItem.masterRecordId);
    this.tableItems.splice(index, 1);
    this.computeMinMaxUnits();
  }

  toggleRecommended(courseReference: CourseInCustomList, index: number) {
    const courseInCustomList : CourseInCustomList = {
      courseTitle: courseReference.courseTitle,
      courseCode : courseReference.courseCode,
      masterRecordId : courseReference.masterRecordId,
      minUnits : courseReference.minUnits,
      maxUnits : courseReference.maxUnits,
      isRecommended : ! courseReference.isRecommended
    };
    this.tableItems[index] = courseInCustomList;
  }

  openCourseItemSelect() {
    this.router.navigate([{ outlets: { popup: ['courses-for-list-select'] } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }

  computeMinMaxUnits() {
    if (this.tableItems.length > 0) {
      let minUnitsMin = undefined;
      let maxUnitsMax = undefined;

      this.tableItems.forEach((tableItem: CourseInCustomList) => {
        if(minUnitsMin == undefined){
          minUnitsMin = tableItem.minUnits;
        }
        if(maxUnitsMax == undefined){
          maxUnitsMax = tableItem.maxUnits;
        }
        minUnitsMin = (tableItem.minUnits < minUnitsMin) ? tableItem.minUnits : minUnitsMin;
        maxUnitsMax = (tableItem.maxUnits > maxUnitsMax) ? tableItem.maxUnits : maxUnitsMax;
      });
      this.calculatedMinUnits = minUnitsMin;
      this.calculatedMaxUnits = maxUnitsMax;

    } else{
      this.calculatedMinUnits = undefined;
      this.calculatedMaxUnits = undefined;
    }

  }

  openMapControlsFor(courseReference: CourseInCustomList, year, term, courseType, index: number) {
    // TODO: Don't duplicate util
    if (this.listenMapControls && ! this.listenMapControls.closed) {
      this.listenMapControls.unsubscribe();
    }

    this.mapControlsService.initialize<CourseInCustomList>(courseReference, { showRecommend: true,
                                       showRemove: true });
    this.listenMapControls = this.mapControlsService.mapAction$.subscribe(
      (action: MapControlsAction) => {
        switch (action) {
          case MapControlsAction.REMOVE:
            this.removeMapItem(courseReference, index);
            this.closeMapControls();
            break;
          case MapControlsAction.MOVE_UP:
            this.updateOrder(this.tableItems, index, index - 1);
            this.closeMapControls();
            break;
          case MapControlsAction.MOVE_DOWN:
            this.updateOrder(this.tableItems, index, index + 1);
            this.closeMapControls();
            break;
          case MapControlsAction.MARK_RECOMMENDED:
            this.toggleRecommended(this.tableItems[index], index);
            break;
      }
    });

    this.router.navigate([{ outlets: { popup: ['map-controls'] } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }

  closeMapControls() {
    this.router.navigate([{ outlets: { popup: null } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }

  updateOrder(tableItems: any[], index: number,  newIndex: number) {
    if (newIndex >= 0 && newIndex < tableItems.length) {
      const move = tableItems[index],
        moved = tableItems[newIndex];

      tableItems[index] = moved;
      tableItems[newIndex] = move;
    }
  }

  disableInputs(event) {
    const unitsInput = this.form.get('specifyUnits');

    if (event.target.value === 'singleUnits') {
      unitsInput.enable();
    } else if (event.target.value === 'rangeUnits') {
      unitsInput.disable();
    }
  }

  mapSelectedCourseWithUnitsToCourseInCustomList(selectedCourseWithUnits: SelectedCourseWithUnits) : CourseInCustomList {
    const courseInCustomList = <CourseInCustomList>({
        courseCode: selectedCourseWithUnits.course.courseCode,
        courseTitle : selectedCourseWithUnits.course.title,
        masterRecordId : selectedCourseWithUnits.course.masterRecordId,
        maxUnits : selectedCourseWithUnits.maxUnits,
        minUnits : selectedCourseWithUnits.minUnits,
        isRecommended : false
      }
    )

    return courseInCustomList;
  }
}


