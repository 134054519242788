import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { MessageService } from '../../../shared/message/message.service';

import { CurrentUser } from '../missing-types.type';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.css']
})
export class UserUpdateComponent implements OnInit, OnDestroy {
  form: FormGroup;

  currentUser: CurrentUser;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private messageService: MessageService,
    private accountService: AccountService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.accountService.getCurrentUser().subscribe(
      (currentUser: CurrentUser) => {
        this.currentUser = currentUser;
        this.instantiateForm();
      });

    this.renderer.addClass(document.body, 'l-staticfooterpage-white');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'l-staticfooterpage-white');
  }

  instantiateForm() {
    this.form = this.fb.group({
      firstName: [this.currentUser.firstName, Validators.required],
      lastName: [this.currentUser.lastName, Validators.required],
      email: [this.currentUser.email, [Validators.required, Validators.email]],
   });
  }

  submitForm() {
    // this.accountService.updateCurrentUser(this.form.value).subscribe(
    //   (currentUser: CurrentUser) => {
    //     this.currentUser = currentUser;
    //     this.messageService.add('Your information has been updated.', 'icon-notify');
    //   },
    //   (error: HttpErrorResponse) => {
    //     this.messageService.add('Your information failed to update.', 'icon-alert', 'authalert-error');
    //   });
  }
}
