import { Injectable } from '@angular/core';
import {
  CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router,
  UrlTree, PRIMARY_OUTLET
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthenticationService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = this.getUrlWithoutModals(state)
    this.authService.redirectUrl = url;
    return this.checkLogin();
  }

  // I return the requested URL (as defined in the snapshot), less any of the "secondary"
  // named-outlet segments (ie, modals/popups).
  private getUrlWithoutModals(routerStateSnapshot: RouterStateSnapshot ) : string {
    var urlTree = this.router.parseUrl( routerStateSnapshot.url );
    var segment = urlTree.root;
    // Walk down the tree of primary outlets and delete any "popup", "subPopup", and "confirmLeave" children. This should
    // leave us with a UrlTree that contains everything that the original URL had,
    // less the "secondary" named-outlets.  Ie, page url w/out modals on top of it.
    while ( segment && segment.children ) {
      delete( segment.children.popup );
      delete( segment.children.subPopup );
      delete( segment.children.confirmLeave );
      delete( segment.children.chooseYearPopup );
      segment = segment.children[ PRIMARY_OUTLET ];
    }
    return( urlTree ).toString();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.canActivate(next, state);
  }

  checkLogin(): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }

    // console.log("auth failure")
    this.router.navigate(['/login']);
    return false;
  }
}
