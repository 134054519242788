import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiService } from '../../../shared/core/api.service';
import { CollegeAbout, MediaType } from '../../../shared/programmapper-authoring.model.d';

import { CollegeMediaType } from '../missing-types.type';

@Injectable({
  providedIn: 'root'
})
export class AboutService {
  mediaTypes: CollegeMediaType[] = [
    {
      mediaTypeName: 'YouTube Video',
      mediaType: MediaType.YOUTUBE,
    },
    {
      mediaTypeName: 'Image',
      mediaType: MediaType.IMAGE,
    },
  ];

  constructor(private apiService: ApiService) {}

  getMediaTypes(): Observable<CollegeMediaType[]> {
    return of(this.mediaTypes);
  }

  getCollegeAbout(): Observable<CollegeAbout> {
    return this.apiService.getCollege<CollegeAbout>('college-about');
  }

  createCollegeAbout(collegeAbout: CollegeAbout): Observable<CollegeAbout> {
    return this.apiService.postCollege(collegeAbout, 'college-about');
  }

  updateCollegeAbout(collegeAbout: CollegeAbout): Observable<CollegeAbout> {
    return this.apiService.putCollege<CollegeAbout>(
      collegeAbout, 'college-about');
  }
}
