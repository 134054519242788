import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, timer, of } from 'rxjs';
import { map } from 'rxjs/operators';

interface CanDeactivateComponent {
  canDeactivate: (nextPath: string) => Observable<boolean> | Promise<boolean> | boolean;
  componentHasChanges?: boolean;
  form?: FormGroup;
}

@Injectable({
  providedIn: 'root'
})
export class MapsDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
  canDeactivate(
    component: CanDeactivateComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (nextState.url.includes('/preview') || nextState.url.includes('/builder')) {
        return true;
      } else {
      // TODO: get this working. it does not work currently (it doesn't warn upon leaving created map after returning from preview)
      //   if (! component.componentHasChanges && (component.form && ! component.form.dirty)) {
      //     return true;
      //   } else {
          return component.canDeactivate(nextState.url);
//       }
      }
  }
}
