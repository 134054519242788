import { Injectable } from '@angular/core';
import {
  PendingMapLink,
  ProgramMapSummary,
  PublishedProgramSummary
} from "../../../shared/programmapper-authoring.model";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MapListService {
  pendingMapLink: PendingMapLink;
  mapToCopySummary: ProgramMapSummary;
  programSummary: PublishedProgramSummary;

  constructor() { }
  updateMapList: BehaviorSubject<boolean> = new BehaviorSubject(false);

}
