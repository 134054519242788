import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';

import { CtaUpdateComponent } from './cta-update/cta-update.component';
import { VersionsListComponent } from './versions-list/versions-list.component';
import { GeneralComponent } from './general.component';
import { ExcludePreviousCatalogYearsComponent } from './exclude-previous-catalog-years/exclude-previous-catalog-years.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  declarations: [CtaUpdateComponent, VersionsListComponent, GeneralComponent, ExcludePreviousCatalogYearsComponent]
})
export class GeneralModule { }
