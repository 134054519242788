import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationsService} from '../notifications.service';
import {Observable, ReplaySubject, Subject, Subscription, timer} from 'rxjs/index';
import {MessageService} from '../../../shared/message/message.service';
import {
  ErrorSeverity,
  LinkedMapNotice, LinkedMapNoticeForApproval, MapPreview, MapPreviewCreateRequest, MapStatus, MapYear,
  NoticeType,
  PendingMapLink,
  ProgramLinkRequestAwaitingApproval,
  ProgramLinkRequestAwaitingCatalogUpdate,
  ProgramLinkRequestAwaitingPublicNotice,
  ProgramLinkRequestAwaitingResponseNotice,
  ProgramLinkRequestCatalogOutdated,
  ProgramLinkRequestDeniedNotice,
  ProgramLinkRequestMapLinkApproval,
  ProgramLinkRequestNotice,
  ProgramLinkRequestPrivateMapNotice, ProgramLinkUpdatedMapNotice, ProgramMap, ProgramMapSummary, PublishStatus
} from "../../../shared/programmapper-authoring.model";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {LinkedMapsService} from "../../maps/common/linked-maps/linked-maps.service";
import {HttpErrorResponse} from "@angular/common/http";
import {MapsService} from "../../maps/maps.service";
import {takeUntil} from "rxjs/operators";
import {filter} from "rxjs/internal/operators";

@Component({
  selector: 'app-notifications-panel',
  templateUrl: './notifications-panel.component.html',
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
 // DataIssueType = DataIssueType;
  notifications$: Observable<LinkedMapNotice[]>;
  routeSub: Subscription;
  selectedNotification: LinkedMapNotice;
  //notificationForDeny: LinkedMapNotice;
  confirmDialogOpen: boolean;

  componentLoaded = false;
  private currentSiteContentPreviewSource = new ReplaySubject<MapPreview>();
  currentSiteContentPreview$ : Observable<MapPreview> = this.currentSiteContentPreviewSource.asObservable();
  private previewPollingSub: Subscription;
  private unsubscribe$ = new Subject();
  errorWarning = ErrorSeverity.WARNING
  errorInfo = ErrorSeverity.INFO;

  constructor(private notificationService: NotificationsService,
              private messageService: MessageService,
              private route: ActivatedRoute,
              private router: Router,
              protected mapsService: MapsService,
              private linkedMapsService: LinkedMapsService,
  ) { }

  ngOnInit() {
    this.notifications$ = this.notificationService.getLinkedMapIssues();

    // COL-2368 If notifications link is clicked while on /notifications reload the notificaitons.
    // We do this because the count in the header updates.
    this.routeSub = this.router.events
      .pipe(filter(event =>
        event instanceof NavigationEnd,
      ))
      .subscribe((event: NavigationEnd) => {
        this.notifications$ = this.notificationService.getLinkedMapIssues();
      });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  selectNotification(notification: LinkedMapNotice) {
    this.currentSiteContentPreviewSource.next(undefined);
    this.selectedNotification = notification;
  }

  removeSelectedNotification() {
    this.selectedNotification = undefined;
  }

  declinePendingMapLink(programLinkRequestNotice: ProgramLinkRequestNotice) {
    this.linkedMapsService.declinePendingLink(programLinkRequestNotice.pendingMapLink.map.mapId, programLinkRequestNotice.pendingMapLink.linkedProgramId).subscribe(() => {
      this.refreshNotificationsAndCloseConfirmDialog();
    })
  }

  deletePendingMapLink(programLinkRequestAwaitingResponse: ProgramLinkRequestAwaitingResponseNotice) {
    this.linkedMapsService.deletePendingMapLink(programLinkRequestAwaitingResponse.pendingMapLink.map.mapId, programLinkRequestAwaitingResponse.pendingMapLink.linkedProgramId).subscribe(() => {
      this.refreshNotificationsAndCloseConfirmDialog();
    })
  }

  private refreshNotificationsAndCloseConfirmDialog() {
    this.notifications$ = this.notificationService.getLinkedMapIssues();
    this.removeSelectedNotification();
    this.notificationService.forceNoticeRetrieval();
    this.confirmDialogOpen = false;
    // TODO: update notice count in header here (no rounter nav = not triggered)
  }

  titleByNotification(value: LinkedMapNotice){
    if(value){
      switch(value.type){
        case NoticeType.PROGRAM_LINK_REQUEST:{
          return "Map Link Request";
        }
        case NoticeType.PROGRAM_LINK_REQUEST_DENIED:{
          return "Map Link Request - Linking Denied";
        }
        case NoticeType.PROGRAM_LINK_REQUEST_AWAITING_RESPONSE:{
          return "Map Link Request - Awaiting Response";
        }
        case NoticeType.PROGRAM_LINK_REQUEST_PRIVATE_MAP:{
          return "Private Map Status - Public Status Required";
        }
        case NoticeType.PROGRAM_LINK_REQUEST_AWAITING_PUBLIC:{
          return "Private Map Status - Awaiting Public Status";
        }
        case NoticeType.PROGRAM_LINK_REQUEST_AWAITING_APPROVAL:{
          return "Map Link Request - Awaiting Approve Linked Map";
        }
        case NoticeType.PROGRAM_LINK_REQUEST_MAP_LINK_APPROVAL:{
          return "Map Link Request - Approve Linked Map";
        }
        case NoticeType.PROGRAM_LINK_REQUEST_AWAITING_CATALOG_UPDATE:{
          return "Catalog out of Sync - Awaiting Update";
        }
        case NoticeType.PROGRAM_LINK_REQUEST_CATALOG_OUTDATED:{
          return "Catalog out of Sync";
        }
        case NoticeType.PROGRAM_LINK_MAP_UPDATED:{
          return "Linked Program Map Updated - Publish Required";
        }
        default:{ //this should never get hit, unless a new type of issue is added without adding to this switch method
          throw new Error("not implemented");
        }
      }
    }
  }

  //TODO: talk to Dria about removing this
  cancelProgramLinkRequest(programId: string, mapId: string){
    //this.router.navigateByUrl("/maps/programs/" + programId + "/maps/" + mapId + "/builder").then(this.)
    this.router.navigate( ['/maps', 'programs', programId, 'maps', mapId, 'builder'],
      {relativeTo: this.route.parent});
  }

  getAsProgramLinkRequest(value: LinkedMapNotice) : ProgramLinkRequestNotice {
    if( value && value.type === NoticeType.PROGRAM_LINK_REQUEST){
      return value as ProgramLinkRequestNotice
    }
  }
  getAsProgramLinkRequestDenied(value: LinkedMapNotice) : ProgramLinkRequestDeniedNotice {
    if( value && value.type === NoticeType.PROGRAM_LINK_REQUEST_DENIED){
      return value as ProgramLinkRequestDeniedNotice
    }
  }
  getAsProgramLinkRequestAwaitingResponse(value: LinkedMapNotice) : ProgramLinkRequestAwaitingResponseNotice {
    if( value && value.type === NoticeType.PROGRAM_LINK_REQUEST_AWAITING_RESPONSE){
      return value as ProgramLinkRequestAwaitingResponseNotice
    }
  }
  getAsProgramLinkRequestPrivateMap(value: LinkedMapNotice) : ProgramLinkRequestPrivateMapNotice {
    if( value && value.type === NoticeType.PROGRAM_LINK_REQUEST_PRIVATE_MAP){
      return value as ProgramLinkRequestPrivateMapNotice
    }
  }
  getAsProgramLinkRequestAwaitingPublic(value: LinkedMapNotice) : ProgramLinkRequestAwaitingPublicNotice {
    if( value && value.type === NoticeType.PROGRAM_LINK_REQUEST_AWAITING_PUBLIC){
      return value as ProgramLinkRequestAwaitingPublicNotice
    }
  }
  getAsProgramLinkRequestAwaitingCatalogUpdate(value: LinkedMapNotice) : ProgramLinkRequestAwaitingCatalogUpdate {
    if( value && value.type == NoticeType.PROGRAM_LINK_REQUEST_AWAITING_CATALOG_UPDATE){
      return value as ProgramLinkRequestAwaitingCatalogUpdate
    }
  }
  getAsProgramLinkRequestCatalogOutdated(value: LinkedMapNotice) : ProgramLinkRequestCatalogOutdated {
    if( value && value.type == NoticeType.PROGRAM_LINK_REQUEST_CATALOG_OUTDATED){
      return value as ProgramLinkRequestCatalogOutdated
    }
  }

  getAsProgramLinkRequestAwaitingApproval(value : LinkedMapNotice) : ProgramLinkRequestAwaitingApproval {
    if( value && value.type == NoticeType.PROGRAM_LINK_REQUEST_AWAITING_APPROVAL){
      return value as ProgramLinkRequestAwaitingApproval
    }
  }

  getAsProgramLinkRequestMapLinkApproval(value : LinkedMapNotice) : ProgramLinkRequestMapLinkApproval{
    if(value && value.type == NoticeType.PROGRAM_LINK_REQUEST_MAP_LINK_APPROVAL){
      return value as ProgramLinkRequestMapLinkApproval
    }
  }

  getAsProgramLinkUpdatedMapNotice(value : LinkedMapNotice) : ProgramLinkUpdatedMapNotice{
    if(value && value.type == NoticeType.PROGRAM_LINK_MAP_UPDATED){
      return value as ProgramLinkUpdatedMapNotice
    }
  }

  goToPendingMapLink(pendingMapLink: PendingMapLink) {
    //this.mapListService.pendingMapLink = pendingMapLink;

    this.router.navigate(
      ['/maps', 'programs', pendingMapLink.linkedProgramId, 'maps', 'list'],
      {
        queryParams: { selectedPendingMapLink: pendingMapLink.id } ,
        relativeTo: this.route.parent
      }
      );
  }

  goToPrivateMapForLink(programId: string, mapId: string){
    this.router.navigate(
      ['/maps', 'programs', programId, 'maps', mapId, 'builder'],
      {relativeTo: this.route.parent});
  }

  goToPublishMapForLink(){
    this.router.navigate(
      ['/publish', 'review'],
      {relativeTo: this.route.parent});
  }

  navigateToNewLinkedMapRequest(programId: string, mapId: string) {
    this.router.navigate(
      ['/maps', 'programs', programId, 'maps', mapId, 'builder'],
      {relativeTo: this.route.parent});
  }

  openConfirmDialog() {
    this.confirmDialogOpen = true;
  }

  approveMapLink(mapId: string, linkedMapId: string) {
    this.linkedMapsService.approveMapLink(mapId, linkedMapId).subscribe(() => {
      this.refreshNotificationsAndCloseConfirmDialog();
    })
  }

  deleteMapLink(mapId: string, linkedMapId: string) {
    this.linkedMapsService.deleteMapLink(mapId, linkedMapId).subscribe(() => {
      this.refreshNotificationsAndCloseConfirmDialog();
    })
  }

  hasCriticalDataIssues() {
    return false; // TODO: this may be a problem, need to handle in the response (one or more maps not previewable)
  }

  isPreviewPending(siteContentPreview: MapPreview) {
    return siteContentPreview.publishStatus === PublishStatus.PENDING;
  }

  isPreviewCompleted(siteContentPreview: MapPreview) {
    return siteContentPreview.publishStatus === PublishStatus.COMPLETED;
  }

  requestPreview(mapId: string, linkedMapId: string) {
    this.linkedMapsService.postLinkedMapPreview(mapId, linkedMapId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(mapPreview => {
        const tick$: Observable<number> = timer(0, 5000); // first check is at 0 seconds;


        this.previewPollingSub = tick$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(t => {

            const x = this.mapsService.getMapPreview(mapPreview.id)
              .pipe(takeUntil(this.unsubscribe$));

            x.subscribe(siteContentPreview => {
              if (siteContentPreview.publishStatus !== PublishStatus.PENDING) {
                this.previewPollingSub.unsubscribe();
              }
              this.currentSiteContentPreviewSource.next(siteContentPreview);
            });

            return x;
          });
      });
  }

  pendingMapLinkToOrFrom(pendingMapLink : PendingMapLink) : string{
    if(pendingMapLink.linkedCollegeName.toLocaleLowerCase().includes("csu") || pendingMapLink.linkedCollegeName.toLocaleLowerCase().includes("uc")){
      return "to";
    }
    else{
      return "from";
    }
  }
}
