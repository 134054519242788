import { Component, OnInit } from '@angular/core';
import {GeneralService} from "../general.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {of} from "rxjs";

@Component({
  selector: 'app-exclude-previous-catalog-years',
  templateUrl: './exclude-previous-catalog-years.component.html',
  styleUrls: ['./exclude-previous-catalog-years.component.css']
})
export class ExcludePreviousCatalogYearsComponent implements OnInit {
  private form: FormGroup;
  private previousCatalogYears: number[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private generalService: GeneralService,
              private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.initPreviousCatalogYears();
  }

  private initPreviousCatalogYears() {

    this.generalService.previouslyPublishedCatalogYears$(this.generalService.getCurrentlySelectedCatalogYear()).subscribe(
      (previousCatalogYears: number[]) => {
        this.previousCatalogYears = previousCatalogYears;
        this.initPreviousCatalogYearsForm(this.previousCatalogYears);
      }
    );
  }

  private initPreviousCatalogYearsForm(previousCatalogYears: number[]) {
    this.form = this.fb.group({
      yearsExcluded: this.fb.array([]),
    });

    previousCatalogYears.forEach(year => {
      if (this.generalService.getExcludedPreviousCatalogYears().includes(year)) {
        this.yearsExcludedControls.push(new FormControl(year));
      } else {
        this.yearsExcludedControls.push(new FormControl(false));
      }

    });
  }

  get yearsExcludedControls() {
    return this.form.get('yearsExcluded') as FormArray;
  }

  cancel(){
    this.generalService.changeExcludedPreviousCatalogYears(undefined);
    this.closeModal();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent, skipLocationChange: true });
  }

  submitForm() {
    // Filter empty values out of array.
    this.generalService.changeExcludedPreviousCatalogYears(this.form.value.yearsExcluded.filter(value => value));
    this.closeModal();
  }
}
