import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { ProgramGroupIcon } from '../../missing-types.type';
import { AcademicsService } from '../../academics.service';

import { IconSelectService } from './icon-select.service';

@Component({
  selector: 'app-icon-select',
  templateUrl: './icon-select.component.html',
  styleUrls: ['./icon-select.component.css']
})
export class IconSelectComponent implements OnInit {
  icons$: Observable<ProgramGroupIcon[]>;
  currentIcon: ProgramGroupIcon;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private iconSelectService: IconSelectService,
    private academicsService: AcademicsService,
  ) {}

  ngOnInit() {
    this.icons$ = this.academicsService.getProgramGroupIcons().pipe(share());
    this.currentIcon = this.iconSelectService.iconSelected$.value;
  }

  selectIcon(icon: ProgramGroupIcon) {
    this.iconSelectService.iconSelected$.next(icon);
    this.closeModal();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }
}
