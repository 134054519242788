import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ResetPasswordRequest
} from "../../../shared/programmapper-authoring.model";
import {ResetPasswordService} from "./reset-password.service";
import {HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "../../../shared/message/message.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  queryParam: String;
  private resetPasswordToken: string;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private resetPasswordService: ResetPasswordService,
    private messageService: MessageService,
    private router: Router) { }

  ngOnInit() {
    this.resetPasswordToken = this.route.snapshot.queryParams['resetToken'];
    this.renderer.addClass(document.body, 'l-staticfooterpage-white');
    this.initializeForm();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'l-staticfooterpage-white');
  }

  initializeForm() {
    this.form = this.fb.group({
      'password': ['', [Validators.required, Validators.minLength(8)]],
      'confirmPassword': ['', Validators.required],
    }, { validator: passwordsMatchValidator });
  }

  submitForm() {
    const resetPasswordRequest : ResetPasswordRequest = {
      resetToken: this.resetPasswordToken,
      password: this.form.controls.password.value
    };

    this.resetPasswordService.resetPasswordFromToken(resetPasswordRequest)
      .subscribe(() => {
          this.messageService.add(`Password Reset`, 'icon-notify');
          this.router.navigate(['/login']);
      },
      (error: HttpErrorResponse) => {
        this.messageService.add('Password Reset failed.', 'icon-alert',
          'authalert-error');
      });
  }
}

export const passwordsMatchValidator: ValidatorFn =
  (control: FormGroup): ValidationErrors | null =>
    (control.get('password').value !== control.get('confirmPassword').value) ?
      { 'passwordsDoNotMatch': true } : null;

