import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { DataIssue, ActivityLog } from '../missing-types.type';
// import { _ } from '../../../../shared/programmapper-api.model.d';
import { dataIssues, activityLogs } from '../publish.mock';
import { PublishService } from '../publish.service';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.css']
})
export class ActivityLogComponent implements OnInit {
  activityLogs: Observable<ActivityLog[]>;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private publishService: PublishService,
  ) { }

  ngOnInit() {
    this.activityLogs = this.publishService.getActivityLogs();
  }
}
