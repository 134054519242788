import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';

import { SelectedCollegeService } from '../../selected-college.service';
import { MessageService } from '../../../shared/message/message.service';
import {
  CollegeGeneralInfo, PublishedCurriculumSummary
} from '../../../shared/programmapper-authoring.model';

import { VersionsListService } from './versions-list/versions-list.service';
import { CollegeTermTypeWrapper } from '../missing-types.type';
import { GeneralService } from './general.service';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit, OnDestroy {
  form: FormGroup;
  listenNavigate$: Subscription;
  updateCallToAction$: Subscription;
  popupWillTriggerNavigationEnd = false;

  termTypes: CollegeTermTypeWrapper[];
  curriculumVersions: PublishedCurriculumSummary[];
  generalInfo: CollegeGeneralInfo;
  private createGeneralInfo: boolean;
  terms = terms;
  termDefaults = termDefaults;
  saving: boolean;
  private excludedPreviousCatalogYearsChangedSub: Subscription;
  private currentlyExcludedCatalogYears:number[] = [];
  private currentlySelectedVersion: PublishedCurriculumSummary; // note: might not be saved yet

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private versionsListService: VersionsListService,
    private generalService: GeneralService,
    private selectedCollegeService: SelectedCollegeService,
    //private ctaUpdateService: CtaUpdateService,
    private fb: FormBuilder) { }

  ngOnDestroy() {
    if (this.listenNavigate$ && ! this.listenNavigate$.closed) {
      this.listenNavigate$.unsubscribe();
    }
    if (this.updateCallToAction$ && ! this.updateCallToAction$.closed) {
      this.updateCallToAction$.unsubscribe();
    }
  }

  ngOnInit() {
    //
    //
    //
    //
    //
    // // Re-populate data on the page if the College is changed (which re-routes to ourself).
    // // However, ignore the routing events triggered from closing modals on this page.
    // if (! this.listenNavigate$) {
    //   this.listenNavigate$ = this.router.events.subscribe((ev: NavigationEnd) => {
    //     if (ev instanceof NavigationStart) {
    //
    //       if (ev.url.toLowerCase().includes('popup:')) {
    //         this.popupWillTriggerNavigationEnd = true;
    //       } else {
    //         if (! this.popupWillTriggerNavigationEnd) {
    //           this.form = null;
    //         }
    //       }
    //
    //     } else if (ev instanceof NavigationEnd) {
    //       if (! this.popupWillTriggerNavigationEnd) {
    //         this.ngOnInit();
    //       }
    //
    //       if (! ev.url.toLowerCase().includes('popup:') && this.popupWillTriggerNavigationEnd) {
    //         this.popupWillTriggerNavigationEnd = false;
    //       }
    //     }
    //   });
    // }

    const formData = forkJoin(this.generalService.getCollegeTermTypes(),
                              this.versionsListService.getCollegeCurriculumVersions(),
                              this.generalService.getCollegeGeneralInfo());

    formData.subscribe(
      ([termTypes, curriculumVersions, generalInfo]:
       [CollegeTermTypeWrapper[], PublishedCurriculumSummary[], CollegeGeneralInfo]) => {
        this.termTypes = termTypes;
        this.sortCurriculumVersions(curriculumVersions);
        this.curriculumVersions = curriculumVersions;

        if (isNullOrUndefined(generalInfo)) {
          this.generalInfo = {
            publishedCurriculumIri: null,
            themeColor: null,
            logo: null,
            defaultTermsPerYear: null,
            version: null,
            onlineCatalogUrl: null,
            generalAdvisingUrl: null,
            registrationUrl: null,
            applyNowUrl: null,
            newMapYears: null,
            termNames: null,
            hideMapYears: false,
            showPreviousCatalogYears: false,
            excludedPreviousCatalogYears : []
          };
          this.createGeneralInfo = true;
        } else {
          this.generalInfo = generalInfo;
          this.currentlyExcludedCatalogYears = this.generalInfo.excludedPreviousCatalogYears;
          let matches:PublishedCurriculumSummary[] = curriculumVersions.filter(curriculumVersion => curriculumVersion.publishedCurriculumIri == generalInfo.publishedCurriculumIri);
          if(matches.length == 0){
            throw new Error("previously selected published curriculum iri = " + generalInfo.publishedCurriculumIri + " is no longer available as a version. This indicates a server misconfiguration or a divergence between the curriculumservice and authoring databases being used.")
          }
          this.currentlySelectedVersion = matches[0];
          //this.excludedPreviousCatalogYears=[2018,2019,2020]
          this.createGeneralInfo = false;
        }

        this.instantiateForm();

        //  this.ctaUpdateService.programMapCTA$.next(generalInfo.programMapCTA);
       // this.listenForCTAUpdate();
      });
  }

  instantiateForm() {

    this.form = this.fb.group({
      defaultTermsPerYear: [this.generalInfo.defaultTermsPerYear, Validators.required],
      publishedCurriculumIri: [this.generalInfo.publishedCurriculumIri, Validators.required],
      onlineCatalogUrl: [this.generalInfo.onlineCatalogUrl, [Validators.required, Validators.pattern('^(https?:/\/\.*)') ]],
      generalAdvisingUrl: [this.generalInfo.generalAdvisingUrl, [Validators.pattern('^(https?:/\/\.*)') ]],
      registrationUrl: [this.generalInfo.registrationUrl, [Validators.pattern('^(https?:/\/\.*)') ]],
      applyNowUrl: [this.generalInfo.applyNowUrl, [Validators.pattern('^(https?:/\/\.*)') ]],
      newMapYears: [this.generalInfo.newMapYears || 2, Validators.required],
      // programMapCTA: this.generalInfo.programMapCTA,
      // displayApplyNowCTA: [this.generalInfo.displayApplyNowCTA, Validators.required],
      // displayProgramMapCTA: [this.generalInfo.displayProgramMapCTA, Validators.required],
      themeColor: [this.generalInfo.themeColor, [Validators.required, Validators.pattern('^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6}$)')]],
      logo: [this.generalInfo.logo, Validators.required],
      version: this.generalInfo.version,
      termNames: this.fb.array([], Validators.required),
      hideMapYears: [this.generalInfo.hideMapYears, Validators.required],
      showPreviousCatalogYears: [this.generalInfo.showPreviousCatalogYears, Validators.required],
      excludedPreviousCatalogYears: [this.currentlyExcludedCatalogYears]
    });

    if (this.generalInfo.termNames && this.generalInfo.termNames.length > 0) {
      this.updateTermNames(this.generalInfo.defaultTermsPerYear);
    } else {
      for (let x = 0; x < this.generalInfo.defaultTermsPerYear; x++) {
        this.termNameControls.push(new FormControl(''));
      }
    }
  }

  get termNameControls() {
    return this.form.get('termNames') as FormArray;
  }

  updateTermNames(numberTerms: number) {
    // Clear the form array.
    while (this.termNameControls.length) {
      this.termNameControls.removeAt(0);
    }

    for (let x = 0; x < numberTerms; x++) {
      this.termNameControls.push(this.fb.control(this.generalInfo.termNames[x]));
    }
  }

  changeTermNames(numberTerms: number) {
    while (this.termNameControls.length) {
      this.termNameControls.removeAt(0);
    }

    for (let count = 0; count < numberTerms; count++) {
      this.termNameControls.push(this.fb.control(this.termDefaults[numberTerms - 1][count]));
    }
  }

  sortCurriculumVersions(curriculumVersions: PublishedCurriculumSummary[]) {
    curriculumVersions.sort((a, b) => {
      if(a.year != b.year){
        return b.year - a.year;
      }
      return b.revision - a.revision;
    });
  }

  // listenForCTAUpdate() {
  //   this.updateCallToAction$ = this.ctaUpdateService.programMapCTA$.subscribe(
  //     (programMapCTA: CollegeProgramMapCTA) => {
  //       if (this.form) {
  //         this.form.patchValue({ programMapCTA: programMapCTA });
  //       }
  //     });
  // }

  onFileUpload(files: FileList, formControlName: string) {
    if (files && files.length) {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        this.form.patchValue({ [formControlName]: fileReader.result });
      };

      fileReader.readAsDataURL(files.item(0));
    }
  }

  curriculumVersionChanged(selectedPublishedCurriculumIri) {
    let selectedMatch = this.curriculumVersions.filter(publishedCurriculumSummary => publishedCurriculumSummary.publishedCurriculumIri == selectedPublishedCurriculumIri)[0];

    if( selectedMatch.year < this.currentlySelectedVersion.year){// if the newly selected year is older than the previously selected year
      //don't allow excluding of years that are the same or newer than the newly selected year
      this.currentlyExcludedCatalogYears = this.currentlyExcludedCatalogYears
          .filter(excludedPreviousCatalogYear =>  excludedPreviousCatalogYear < selectedMatch.year);
    }
    else {
      // if the newly selected year is the same or newer than the original year: do not modify exclusions
    }

    this.currentlySelectedVersion = selectedMatch;
  }

  submitForm() {
    this.saving = true;

    this.form.patchValue({'excludedPreviousCatalogYears': this.currentlyExcludedCatalogYears});

    if(this.createGeneralInfo){
      this.generalService.createCollegeGeneral(this.form.value).subscribe(
        (collegeGeneral: CollegeGeneralInfo) => {
          this.generalInfo = collegeGeneral;
          this.createGeneralInfo = false;
          this.messageService.add('College general information saved.', 'icon-notify');
          this.saving = false;
        },
        (error: HttpErrorResponse) => {
          this.messageService.add('College general information failed to save.',
            'icon-alert', 'authalert-error');
          this.saving = false;
        });
    }
    else {
      this.generalService.updateCollegeGeneral(this.form.value).subscribe(
        (collegeGeneral: CollegeGeneralInfo) => {
          this.generalInfo = collegeGeneral;
          this.messageService.add('College general information saved.', 'icon-notify');
          this.saving = false;
        },
        (error: HttpErrorResponse) => {
          this.messageService.add('College general information failed to save.',
            'icon-alert', 'authalert-error');
          this.saving = false;
        });

    }
  }

  openExclusionModal() {
    if(this.currentlySelectedVersion){
      this.generalService.initExcludedPreviousCatalogYears(this.currentlyExcludedCatalogYears, this.currentlySelectedVersion.year);

      this.excludedPreviousCatalogYearsChangedSub = this.generalService.excludedPreviousCatalogYearsChanged$.subscribe(
          excludedPreviousCatalogYears => {
            if(excludedPreviousCatalogYears) {
              this.currentlyExcludedCatalogYears = excludedPreviousCatalogYears;
              this.excludedPreviousCatalogYearsChangedSub.unsubscribe();
            }
            else {
              this.excludedPreviousCatalogYearsChangedSub.unsubscribe();
            }
          }
      )
      this.router.navigate([{ outlets: { popup: ['exclude-previous-catalog-years'] } }],
          { relativeTo: this.route, skipLocationChange: true },);
    }
  }

}

// TODO: The order of this array determines the defaults, may need to be changed.
export const terms = ['Fall', 'Winter', 'Spring', 'Summer'];

export const termDefaults = [['Fall'], ['Fall', 'Spring'], ['Fall', 'Winter', 'Spring'], ['Fall', 'Winter', 'Spring', 'Summer']];
