import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import {NoCollegesComponent} from "./no-colleges.component";
import {NoCollegesRoutingModule} from "./no-colleges-routing.module";

@NgModule({
  imports: [
    SharedModule,
    NoCollegesRoutingModule,
  ],
  declarations: [NoCollegesComponent]
})
export class NoCollegesModule { }
