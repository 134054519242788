
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

import {
  PublishedCourseListSummary, PublishedGeneralEducationAreaSummary,
} from "../../../../../shared/programmapper-authoring.model";
import {PrebuiltCourseListSelectService} from "../prebuilt-courselist-select.service";
import {max} from "rxjs/operators";

@Component({
  selector: 'app-confirm-prebuilt-courselist-units',
  templateUrl: './confirm-prebuilt-courselist-units.component.html'
})
export class ConfirmPrebuiltCourseListUnitsComponent implements OnInit {
  prebuiltCourseListOrGenEdArea: PublishedCourseListSummary | PublishedGeneralEducationAreaSummary
  form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private prebuiltCourseListSelectService: PrebuiltCourseListSelectService,
  ) { }

  ngOnInit() {
    this.prebuiltCourseListOrGenEdArea = this.prebuiltCourseListSelectService.selectedPrebuiltCourseList;
    this.instantiateForm();
  }

  instantiateForm() {
    this.form = this.fb.group({
      units: [null, [Validators.required, positiveOrZeroNumberValidator()]],
      minUnits: [{value: this.prebuiltCourseListOrGenEdArea.minUnits, disabled: true}, [Validators.required, positiveOrZeroNumberValidator()]],
      maxUnits: [{value: this.prebuiltCourseListOrGenEdArea.maxUnits, disabled: true}, [Validators.required, positiveOrZeroNumberValidator()]],
      unitsFilterCheckbox: [{value: null, disabled: false}],
      unitsRangeFilterCheckbox: [{value: null, disabled: true}]
    }, { validator: UnitsRangeValidator});
  }

  submitForm() {
    const unitsInput = this.form.get('units');
    const minUnitsInput = this.form.get('minUnits');
    const maxUnitsInput = this.form.get('maxUnits');

    let filterUnits = true;

    if (unitsInput.enabled) {
      const unitsFilterCheckbox = this.form.get('unitsFilterCheckbox');
      if(unitsFilterCheckbox.value){
        filterUnits = false;
      }
      this.prebuiltCourseListSelectService.selectUnitsForSelectedPrebuiltCourseList(unitsInput.value, unitsInput.value, filterUnits);
    } else if (minUnitsInput.enabled && maxUnitsInput.enabled) {
      const unitsRangeFilterCheckbox = this.form.get('unitsRangeFilterCheckbox');
      if(unitsRangeFilterCheckbox.value){
        filterUnits = false;
      }
      this.prebuiltCourseListSelectService.selectUnitsForSelectedPrebuiltCourseList(minUnitsInput.value, maxUnitsInput.value, filterUnits);
    }
    this.closeModal();
    return false;
  }

  goBack() {
    this.router.navigate([{ outlets: { popup: ['course-list-select'] } }],
      { relativeTo: this.route.parent,
        skipLocationChange: true });
  }

  cancel(){
    this.prebuiltCourseListSelectService.selectedPrebuiltCourseList = null;
    this.closeModal();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent,
        skipLocationChange: true });
  }

  disableInputs(event) {
    const unitsInput = this.form.get('units');
    const minUnitsInput = this.form.get('minUnits');
    const maxUnitsInput = this.form.get('maxUnits');
    const unitsFilterCheckbox = this.form.get('unitsFilterCheckbox');
    const unitsRangeFilterCheckbox = this.form.get('unitsRangeFilterCheckbox');

    if (event.target.value === 'singleUnits') {
      unitsInput.enable();
      minUnitsInput.disable();
      maxUnitsInput.disable();
      unitsFilterCheckbox.enable();
      unitsRangeFilterCheckbox.disable();
    } else if (event.target.value === 'rangeUnits') {
      unitsInput.disable();
      minUnitsInput.enable()
      maxUnitsInput.enable();
      unitsFilterCheckbox.disable();
      unitsRangeFilterCheckbox.enable();
    }
  }
}

function positiveOrZeroNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const value = control.value;
    if(value == null || "" == value){
      return null;
    }
    return (!isNaN(value) && +value >= 0 ) ? null : { 'positiveOrZeroNumberValidator': true };
  };
}

const UnitsRangeValidator: ValidatorFn = (fg: FormGroup) => {
  const unitsInput = fg.get('units');
  const minUnits = fg.get('minUnits').value;
  const maxUnits = fg.get('maxUnits').value;
  if(unitsInput.enabled){
    // single units field does not require min/max range validation
       return null;
  }
  else {
    if(minUnits == null || isNaN(minUnits)  || "" == minUnits
      || maxUnits == null || isNaN(maxUnits) || "" == maxUnits){
      return null;
    }
    if(minUnits <0 || maxUnits <0){
      return null;
    }
    return +minUnits <= +maxUnits
      ? null
      : { unitsRangeValidator: true };
  }
};

