import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../authentication.guard';
import { SelectedCollegeGuard } from '../selected-college.guard';

import { CtaUpdateComponent } from '../college/general/cta-update/cta-update.component';

import { CustomCourseChoiceUpdateComponent } from './common/custom-course-choice-update/custom-course-choice-update.component';
import { CourseGroupCreateComponent } from './common/custom-course-choice-create/custom-course-choice-create.component';

import { MapControlsComponent } from './common/map-controls/map-controls.component';
import { ConfirmNavigationComponent } from './common/confirm-navigation/confirm-navigation.component';

import { MapsResolveGuard } from './maps-resolve.guard';
import { MapsDeactivateGuard } from './maps-deactivate.guard';
import { ProgramDetailComponent } from './program-detail/program-detail.component';
import { ProgramListComponent } from './program-list/program-list.component';
import { MapListComponent } from './map-list/map-list.component';
import { MapDetailComponent } from './map-detail/map-detail.component';
import { MapCreateComponent } from './map-create/map-create.component';
import { BuilderComponent as MapCreateBuilderComponent } from './map-create/builder/builder.component';
import { PreviewComponent as MapCreatePreviewComponent } from './map-create/preview/preview.component';
import { MapUpdateResolverGuard } from './map-update/map-update-resolver.guard';
import { MapUpdateComponent } from './map-update/map-update.component';
import { BuilderComponent as MapUpdateBuilderComponent } from './map-update/builder/builder.component';
import { PreviewComponent as MapUpdatePreviewComponent } from './map-update/preview/preview.component';
import {CourseSelectComponent} from "./common/curriculum-item-select/course-select/course-select.component";
import {CourseListSelectComponent} from "./common/curriculum-item-select/course-list-select/course-list-select.component";
import {FixOrphanedMapsComponent} from "./program-list/fix-orphaned-maps/fix-orphaned-maps.component";
import {ConfirmRemoveTermComponent} from "./common/confirm-remove-term/confirm-remove-term.component";
import {LinkedMapsModalComponent} from "./common/linked-maps/linked-maps-modal/linked-maps-modal.component";
import {LinkedMapsAddModalComponent} from "./common/linked-maps/linked-maps-add-modal/linked-maps-add-modal.component";
import {LinkedMapsUnlinkConfirmModalComponent} from "./common/linked-maps/linked-maps-unlink-confirm-modal/linked-maps-unlink-confirm-modal.component";
import {AdvisingLinkModalComponent} from "./map-list/advising-link-modal/advising-link-modal.component";
import {AdditionalInfoLinkModalComponent} from "./map-list/additional-info-link-modal/additional-info-link-modal.component";
import {FixMissingMapModalComponent} from "./map-list/fix-missing-map-modal/fix-missing-map-modal.component";
import {LinkExistingMapModalComponent} from "./map-list/link-existing-map-modal/link-existing-map-modal.component";
import {PendingLinkedMapsUnlinkConfirmModalComponent} from "./common/linked-maps/pending-linked-maps-unlink-confirm-modal/pending-linked-maps-unlink-confirm-modal.component";
import {LinkedMapsUpdateApprovalModalComponent} from "./common/linked-maps/linked-maps-update-approval-modal/linked-maps-update-approval-modal.component";
import {LinkedMapsReviewApprovalModalComponent} from "./common/linked-maps/linked-maps-review-approval-modal/linked-maps-review-approval-model.component";
import {LinkedMapsPendingDeclinedUnlinkModalComponent} from "./common/linked-maps/linked-maps-pending-declined-unlink-modal/linked-maps-pending-decliend-unlink-modal.component";
import {LinkedMapsReviewActiveModalComponent} from "./common/linked-maps/linked-maps-review-active-modal/linked-maps-review-active-modal.component";
import {LinkedMapsUnlinkPendingConfirmModalComponent} from "./common/linked-maps/linked-maps-unlink-pending-confirm-modal/linked-maps-unlink-pending-confirm-modal.component";
import {MilestoneCreateComponent} from "./common/milestone-create/milestone-create.component";
import {MilestoneUpdateComponent} from "./common/milestone-update/milestone-update.component";
import {
  ConfirmCourseUnitsComponent
} from "./common/curriculum-item-select/confirm-course-units/confirm-course-units.component";
import {
  ConfirmPrebuiltCourseListUnitsComponent
} from "./common/curriculum-item-select/confirm-prebuilt-courselist-units/confirm-prebuilt-courselist-units.component";
import {CoursesForListSelectComponent} from "./common/curriculum-item-select/courses-for-list-select/courses-for-list-select.component";
import {
  ConfirmCourseUnitsForListComponent
} from "./common/curriculum-item-select/confirm-course-units-for-list/confirm-course-units-for-list.component";
import {CopyMapModalComponent} from "./map-list/copy-map-modal/copy-map-modal.component";
import { CourseNotesUpdateModalComponent } from './common/course-notes-update-modal/course-notes-update-modal.component';

const routes: Routes = [
  {
    path: 'maps',
    canActivateChild: [AuthenticationGuard, SelectedCollegeGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/maps/programs',
      },
      {
        path: 'programs',
        component: ProgramListComponent,
        children: [
          {
            path: 'fix-orphaned-maps',
            component: FixOrphanedMapsComponent,
            outlet: 'popup',
          }
        ]
      },
      {
        path: 'programs/:programId',
        redirectTo: 'programs/:programId/maps',
        pathMatch: 'full',
      },
      {
        path: 'programs/:programId',
        component: ProgramDetailComponent,
        resolve: {
          mapsData: MapsResolveGuard,
        },
        children: [
          {
            path: 'maps',
            pathMatch: 'full',
            redirectTo: 'maps/list',
          },
          {
            path: 'maps',
            component: MapDetailComponent,
            children: [
              {
                path: 'list',
                component: MapListComponent,
                children: [
                  {
                    path: 'edit-advising-link',
                    component: AdvisingLinkModalComponent,
                    outlet: 'popup',
                  },
                  {
                    path: 'edit-additional-info-link',
                    component: AdditionalInfoLinkModalComponent,
                    outlet: 'popup',
                  },
                  {
                    path: 'fix-missing-map',
                    component: FixMissingMapModalComponent,
                    outlet: 'popup',
                  },
                  {
                    path: 'link-existing-map',
                    component: LinkExistingMapModalComponent,
                    outlet: 'popup',
                  },
                  {
                    path: 'cta-update',
                    component: CtaUpdateComponent,
                    outlet: 'popup',
                  },
                  {
                    path: 'copy-map',
                    component: CopyMapModalComponent,
                    outlet: 'popup',
                  },
                ]
              },
              {
                path: 'create',
                component: MapCreateComponent,
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'builder',
                  },
                  {
                    path: 'builder',
                    component: MapCreateBuilderComponent,
                    canDeactivate: [MapsDeactivateGuard],
                    children: [
                      {
                        path: 'course-select',
                        component: CourseSelectComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'courses-for-list-select',
                        component: CoursesForListSelectComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'course-list-select',
                        component: CourseListSelectComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'milestone-create',
                        component: MilestoneCreateComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'milestone-update',
                        component: MilestoneUpdateComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'confirm-course-units',
                        component: ConfirmCourseUnitsComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'confirm-course-units-for-list',
                        component: ConfirmCourseUnitsForListComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'confirm-prebuilt-courselist-units',
                        component: ConfirmPrebuiltCourseListUnitsComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'custom-course-choice-create',
                        component: CourseGroupCreateComponent,
                        outlet: 'subPopup',
                      },
                      {
                        path: 'custom-course-choice-update',
                        component: CustomCourseChoiceUpdateComponent,
                        outlet: 'subPopup',
                      },
                      {
                        path: 'map-controls',
                        component: MapControlsComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'confirm-leave',
                        component: ConfirmNavigationComponent,
                        outlet: 'confirmLeave',
                      },
                      {
                        path: 'confirm-remove-term',
                        component: ConfirmRemoveTermComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'linked-maps',
                        component: LinkedMapsModalComponent,
                        outlet: 'subPopup',
                      },
                      {
                        path: 'linked-maps-add',
                        component: LinkedMapsAddModalComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'linked-maps-unlink',
                        component: LinkedMapsUnlinkConfirmModalComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'linked-maps-unlink-pending-confirm',
                        component: LinkedMapsUnlinkPendingConfirmModalComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'linked-maps-review-active-link',
                        component: LinkedMapsReviewActiveModalComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'pending-linked-maps-unlink',
                        component: PendingLinkedMapsUnlinkConfirmModalComponent,
                        outlet: 'popup',
                      },
                    ],
                  },
                  {
                    path: 'preview/:previewUuid',
                    component: MapCreatePreviewComponent,
                    canDeactivate: [MapsDeactivateGuard],
                    children: [
                      {
                        path: 'confirm-leave',
                        component: ConfirmNavigationComponent,
                        outlet: 'confirmLeave',
                      },
                    ],
                  },
                ]
              },
              {
                path: ':mapId',
                component: MapUpdateComponent,
                resolve: {
                  mapUpdateData: MapUpdateResolverGuard,
                },
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'builder',
                  },
                  {
                    path: 'builder',
                    component: MapUpdateBuilderComponent,
                    canDeactivate: [MapsDeactivateGuard],
                    children: [
                      {
                        path: 'course-select',
                        component: CourseSelectComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'courses-for-list-select',
                        component: CoursesForListSelectComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'course-list-select',
                        component: CourseListSelectComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'milestone-create',
                        component: MilestoneCreateComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'milestone-update',
                        component: MilestoneUpdateComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'confirm-course-units',
                        component: ConfirmCourseUnitsComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'confirm-course-units-for-list',
                        component: ConfirmCourseUnitsForListComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'confirm-prebuilt-courselist-units',
                        component: ConfirmPrebuiltCourseListUnitsComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'custom-course-choice-create',
                        component: CourseGroupCreateComponent,
                        outlet: 'subPopup',
                      },
                      {
                        path: 'custom-course-choice-update',
                        component: CustomCourseChoiceUpdateComponent,
                        outlet: 'subPopup',
                      },
                      {
                        path: 'course-notes-update',
                        component: CourseNotesUpdateModalComponent,
                        outlet: 'subPopup',
                      },
                      {
                        path: 'map-controls',
                        component: MapControlsComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'confirm-leave',
                        component: ConfirmNavigationComponent,
                        outlet: 'confirmLeave',
                      },
                      {
                        path: 'confirm-remove-term',
                        component: ConfirmRemoveTermComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'linked-maps',
                        component: LinkedMapsModalComponent,
                        outlet: 'subPopup',
                      },
                      {
                        path: 'linked-maps-add',
                        component: LinkedMapsAddModalComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'linked-maps-review-active-link',
                        component: LinkedMapsReviewActiveModalComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'linked-maps-unlink',
                        component: LinkedMapsUnlinkConfirmModalComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'linked-maps-unlink-pending-confirm',
                        component: LinkedMapsUnlinkPendingConfirmModalComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'pending-linked-maps-unlink',
                        component: PendingLinkedMapsUnlinkConfirmModalComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'linked-maps-update-approval',
                        component: LinkedMapsUpdateApprovalModalComponent,
                        outlet: 'popup',
                      },
                      {
                        path: 'linked-maps-review-approval',
                        component: LinkedMapsReviewApprovalModalComponent,
                        outlet: 'popup',
                      },
                      {
                        path:'linked-maps-pending-declined-unlink',
                        component: LinkedMapsPendingDeclinedUnlinkModalComponent,
                        outlet: 'popup',
                      }
                    ],
                  },
                  {
                    path: 'preview/:previewUuid',
                    component: MapUpdatePreviewComponent,
                    canDeactivate: [MapsDeactivateGuard],
                    children: [
                      {
                        path: 'confirm-leave',
                        component: ConfirmNavigationComponent,
                        outlet: 'confirmLeave',
                      },
                    ],
                  },
                ]
              },
            ]
          },
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MapsRoutingModule { }
