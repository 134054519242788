import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {MilestoneMapCard} from "../../../shared/programmapper-authoring.model";
import {MilestoneData} from "../missing-types.type";

@Injectable({
  providedIn: 'root'
})
export class MilestoneService {
  milestoneAdded$: Subject<MilestoneData> = new Subject();

  milestoneCardForEdit: MilestoneMapCard;

  constructor() { }
}
