import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.css']
})
export class SearchbarComponent {
  inputHasValue = false;
  @Input() placeholder = '';
  @Input() ariaLabel = '';
  @Input() fields: string[];
  @Input() caseInsensitive = true;
  disabled = false;

  @Output() results: EventEmitter<any[]> = new EventEmitter();

  @Input()
  set objects(value: any[]) {
    value = value || [];
    if (this._objects.length === 0 && value.length > 0) {
      this._objects = value;
    }
  }
  get objects() { return this._objects; }
  private _objects = [];

  filterObjects(filterString: string) {
    (filterString) ? this.inputHasValue = true: this.inputHasValue = false;

    const results = this._objects.filter(
      (object: any) => {
        return this.fields.some(
          (field: string) => {
            if (typeof(object[field]) === 'string') {
              if (this.caseInsensitive) {
                return object[field].toLowerCase().includes(
                  filterString.toLowerCase());
              } else {
                return object[field].includes(filterString);
              }
            }
          });
      });

    this.results.emit(results);
  }
}
