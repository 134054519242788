import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, ValidatorFn, Validators} from '@angular/forms';

import {MapControlsService} from '../map-controls/map-controls.service';

import {CustomCourseChoiceService} from '../custom-course-choice.service';
import {CustomCourseChoiceMixin} from '../custom-course-choice.mixin';
import {CustomCourseChoiceMapCard, MapCardType} from "../../../../shared/programmapper-authoring.model";
import {CourseSelectService} from "../curriculum-item-select/course-select.service";

@Component({
  selector: 'app-custom-course-choice-create',
  templateUrl: './custom-course-choice-create.component.html',
  styleUrls: ['./custom-course-choice-create.component.css']
})
export class CourseGroupCreateComponent extends CustomCourseChoiceMixin implements OnInit {

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected fb: FormBuilder,
    protected courseSelectService: CourseSelectService,
    protected groupService: CustomCourseChoiceService,
    protected mapControlsService: MapControlsService,
  ) {
    super(route, router, fb, courseSelectService, groupService, mapControlsService);
  }

  ngOnInit() {
    this.calculatedMaxUnits = 0;
    this.calculatedMinUnits = 0;
    this.courseSelectService.initialize([]);
    this.initializeForm();
  }

  initializeForm() {
    const id: string = Math.random().toString(36).replace('0.', '');
    this.form = this.fb.group({
      id: id,
      title: ['', Validators.required],
      shortDescription: ['Choose a course', Validators.required],
      longDescription: ['Choose a course from the following', [Validators.required, Validators.maxLength(1000)]],
      specifyUnits: [{value: null, disabled: true}, [Validators.required, positiveOrZeroNumberValidator()]],
      type: [MapCardType.CUSTOM_COURSE_CHOICE],
    });
  }

  submitForm(): boolean {

    const customCourseGroup : CustomCourseChoiceMapCard = this.form.value;
    customCourseGroup.courseReferences = this.tableItems;
    customCourseGroup.type = MapCardType.CUSTOM_COURSE_CHOICE;
    const unitsInput = this.form.get('specifyUnits');

    if (!unitsInput.disabled) {
      customCourseGroup.isCalculateUnits = false;
      customCourseGroup.minUnits = Number(unitsInput.value).valueOf();
      customCourseGroup.maxUnits = Number(unitsInput.value).valueOf();
    } else{
      customCourseGroup.isCalculateUnits = true;
      customCourseGroup.minUnits = Number(this.calculatedMinUnits).valueOf();
      customCourseGroup.maxUnits = Number(this.calculatedMaxUnits).valueOf();
    }

    this.customCourseListService.addCustomCourseList(customCourseGroup);
    this.closeModalEmpty();
    return false;
  }
}

function positiveOrZeroNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const value = control.value;
    if(value == null || "" == value){
      return null;
    }
    return (!isNaN(value) && +value >= 0) ? null : { 'positiveOrZeroNumberValidator': true };
  };
}
