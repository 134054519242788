import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable } from 'rxjs';

import {
  CollegeUser,
  UserInvitationCreateRequest
} from "../../../shared/programmapper-authoring.model";
import {ApiService} from "../../../shared/core/api.service";
import {tap} from "rxjs/internal/operators";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private userChangedSource = new BehaviorSubject<boolean>(true);
  private _userChanged$ = this.userChangedSource.asObservable();

  constructor(private apiService: ApiService) { }


  get userChanged$(): Observable<boolean> {
    return this._userChanged$;
  }

  getCollegeUsers(): Observable<CollegeUser[]> {
    return this.apiService.getCollege<CollegeUser[]>('users');
  }

  getInvitedUser(invitedUserId: string): Observable<CollegeUser> {
    return this.apiService.getCollege<CollegeUser>('invited-users/' + invitedUserId);
  }

  updateInvitedUser(collegeUser: CollegeUser): Observable<CollegeUser> {
    return this.apiService.putCollege<CollegeUser>(collegeUser, 'invited-users/' + collegeUser.id)
      .pipe( tap(() => this.userChangedSource.next(true)));
  }

  createCollegeUser(userInvitationCreateRequest: UserInvitationCreateRequest): Observable<CollegeUser> {
    return this.apiService.postCollege<CollegeUser, UserInvitationCreateRequest>(userInvitationCreateRequest, 'invited-users')
      .pipe( tap(() => this.userChangedSource.next(true)));
  }

  deleteCollegeUser(email: string): Observable<boolean> {
    return this.apiService.postCollege<boolean, string>(
      email, 'remove-user-from-college')
      .pipe( tap(() => this.userChangedSource.next(true)));
  }

  resendInvite(collegeUser: CollegeUser): Observable<boolean> {
    return this.apiService.postCollege<boolean, any>('{}', 'invited-users/'+collegeUser.id+'/invitation');
  }
}
