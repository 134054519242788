import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { MapControlsAction, MapControlsConfig } from './map-controls.type';

@Injectable({
  providedIn: 'root'
})
export class MapControlsService {
  mapItem$: BehaviorSubject<any> = new BehaviorSubject(null);
  mapAction$: Subject<MapControlsAction> = new Subject<MapControlsAction>();
  config: MapControlsConfig;

  constructor() { }

  initialize<T>(mapItem: T, config: MapControlsConfig = {}) {
    this.mapItem$.next(mapItem);
    this.config = config;
  }
}
