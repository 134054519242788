import {PublishedCurriculumSummary} from "../shared/programmapper-authoring.model";


export const versions: PublishedCurriculumSummary[] = [
  {
    name: "2018.2",
    originCurriculumId: "d9f2e6dd-5224-d011-ac4c-2403a7ca8269",
    publishedCurriculumIri: "urn:uuid:18f3814b-1f26-25a5-76fd-0bd3e770902a",
    revision: 2,
    year: 2018,
  },
  {
    name: "2018.1",
    originCurriculumId: "a103907c-4004-bdbf-0dc0-c3b1ed3c08de",
    publishedCurriculumIri: "urn:uuid:a3b32220-ca75-fded-3537-b2e6031015a6",
    revision: 1,
    year: 2018,
  },
  {
    name: "2018.0",
    originCurriculumId: "88c5fc75-8709-ee07-5e8c-9b9f4413f584",
    publishedCurriculumIri: "urn:uuid:c86b2528-300d-3a80-287d-fb4f9cf299fa",
    revision: 0,
    year: 2018,
  },
  {
    name: "2019.1",
    originCurriculumId: "a103907c-4004-bdbf-0dc0-c3b1ed3c08de",
    publishedCurriculumIri: "urn:uuid:a3b32220-ca75-fded-3537-b2e6031015a6",
    revision: 1,
    year: 2019,
  },
  {
    name: "2019.0",
    originCurriculumId: "88c5fc75-8709-ee07-5e8c-9b9f4413f584",
    publishedCurriculumIri: "urn:uuid:c86b2528-300d-3a80-287d-fb4f9cf299fa",
    revision: 0,
    year: 2019,
  },
  {
    name: "2019.2",
    originCurriculumId: "88c5fc75-8709-ee07-5e8c-9b9f4413f584",
    publishedCurriculumIri: "urn:uuid:c86b2528-300d-3a80-287d-fb4f9cf299fa",
    revision: 2,
    year: 2019,
  }
];

export const selectedVersion: PublishedCurriculumSummary = {
  name: "2018.2",
  originCurriculumId: "d9f2e6dd-5224-d011-ac4c-2403a7ca8269",
  publishedCurriculumIri: "urn:uuid:18f3814b-1f26-25a5-76fd-0bd3e770902a",
  revision: 2,
  year: 2018,
};

export const versionInfo: CurriculumVersionInfo = {
  versionsList: versions,
  currentSelectedVersion: selectedVersion,
};

export interface CurriculumVersionInfo {
  versionsList: PublishedCurriculumSummary[],
  currentSelectedVersion: PublishedCurriculumSummary,
}