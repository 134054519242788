import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MessageService } from '../../../../shared/message/message.service';


import { UsersService } from '../users.service';
import {CollegeUser} from "../../../../shared/programmapper-authoring.model";

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
  form: FormGroup;
  deleteConfirmed = false;
  user: CollegeUser;
  saving = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private messageService: MessageService,
    private usersService: UsersService) { }

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      const userId: string = paramMap.get('userId');
      this.usersService.getInvitedUser(userId).subscribe(
        (collegeUser: CollegeUser) => {
          this.user = collegeUser;
          this.instantiateForm();
        });
    });
  }

  instantiateForm() {
    this.form = this.fb.group({
      id: [this.user.id, Validators.required],
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
      status: [this.user.status, Validators.required],
    });
  }

  submitForm() {
    this.saving = true;

    this.usersService.updateInvitedUser(this.form.value).subscribe(
      (collegeUser: CollegeUser) => {
        this.user = collegeUser;
        this.messageService.add('College user successfully updated.', 'icon-notify');
        this.closeModal();
      },
      (error: HttpErrorResponse) => {
        this.messageService.add('College user could not update.', 'icon-alert', 'authalert-error');
        this.closeModal();
      });
  }

  deleteThis() {
    this.usersService.deleteCollegeUser(this.user.email)
    .subscribe((success: boolean) => {
      this.messageService.add('User successfully deleted.', 'icon-notify');
      this.closeModal();
      },
      (error: HttpErrorResponse) => {
        this.messageService.add('User could not be deleted.', 'icon-alert', 'authalert-error');
      });
  }

  // resendInvite() {
  //   this.usersService.resendInvite(this.user).subscribe((success: boolean) => {
  //     this.messageService.add(`Invitation sent to ${this.user.email}`, 'icon-notify');
  //     this.closeModal();
  //   });
  // }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }

}
