import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { ApiService } from '../../shared/core/api.service';
import {
  SiteContentPreview, DataIssue, SiteContentPublish, PublishedSiteVersion, MapExport, MapExportCreateRequest
} from '../../shared/programmapper-authoring.model';

import { ActivityLog } from './missing-types.type';
import {activityLogs, publishedSiteVersions} from './publish.mock';
import {LocalStorageService} from 'ngx-webstorage';
import {SelectedCollegeService} from "../selected-college.service";

@Injectable({
  providedIn: 'root'
})
export class PublishService {

  constructor(private apiService: ApiService,
              private localStorageService: LocalStorageService,
              private selectedCollegeService: SelectedCollegeService) { }

  getDataIssues(): Observable<DataIssue[]> {
    return this.apiService.getCollege<DataIssue[]>('data-issues');
  }

  getActivityLogs(): Observable<ActivityLog[]> {
    return of(activityLogs).pipe(delay(1000));
  }

  getPublishedMockSiteVersions(): Observable<PublishedSiteVersion[]> {
    return of(publishedSiteVersions).pipe(delay(2000));
  }

  getPublishedSiteVersions(): Observable<PublishedSiteVersion[]>{
    return this.apiService.getCollege<PublishedSiteVersion[]>('published-site-versions');
  }


  createSiteContentPreview(): Observable<SiteContentPreview> {
    return this.apiService.postEmpty<SiteContentPreview>(`colleges/${this.selectedCollegeService.selectedCollegeId}/site-content-previews`);
  }

  createSiteContentExport(publishedSiteVersion: PublishedSiteVersion): Observable<MapExport> {
    return this.apiService.postCollege<MapExport, MapExportCreateRequest>({publishedSiteContentId: publishedSiteVersion.publishedSiteContentId, publishedYear: publishedSiteVersion.year, publishedRevision: publishedSiteVersion.revision }, `map-exports`);
  }

  createSiteContentPublish(): Observable<SiteContentPublish> {
    return this.apiService.postEmpty<SiteContentPublish>(`colleges/${this.selectedCollegeService.selectedCollegeId}/site-content-publishes`);
  }

  getSiteContentPreview(id: string): Observable<SiteContentPreview> {
    return this.apiService.getCollege<SiteContentPreview>(`site-content-previews/${id}`);
  }

  getMapExport(id: string): Observable<MapExport> {
    return this.apiService.getCollege<MapExport>(`map-exports/${id}`);
  }

  getSiteContentPublish(id: string): Observable<SiteContentPublish> {
    return this.apiService.getCollege<SiteContentPublish>(`site-content-publishes/${id}`);
  }

  getMapExportUrl(year: number, revision: number, publishedSiteContentId: string, token: any) {
    return this.apiService.collegePath( `curriculum-years/${year}/revision/${revision}/published-site-contents/${publishedSiteContentId}/maps/csv-export.csv`) +"?t=" + token;
  }
}
