import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {tap} from 'rxjs/internal/operators';
import {MessageService} from './shared/message/message.service';
import {Router} from '@angular/router';


@Injectable()
/* log the user out and send them to the login page, in case of any 401 (authentication failed) error response on a REST API call */
export class ApiResponseInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
              private messageService: MessageService,
              private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap( (response :HttpEvent<any>) => {
        if (response instanceof HttpResponse ) {
          let httpResponse:HttpResponse<any> = response as HttpResponse<any>;
          if(httpResponse.headers.has("Authorization")
          && httpResponse.headers.get("Authorization").startsWith("Bearer ")){
            const authHeader = httpResponse.headers.get("Authorization");
            const newToken = authHeader.split("Bearer ")[1]
            this.authenticationService.updateToken(newToken);
          }
        }
      }),
      catchError(err => {
        if (err.status === 401) {
          // TODO: a better exclusion mechanism here
          if(request.url.indexOf("/invited-user?invitationToken") > -1)
          {
            this.messageService.add('Sign-up failed. Invitation token is expired, or invalid, or already used', 'icon-alert',
              'authalert-error');
          }
          else if(request.url.endsWith("/active-users")){
            // user create (from token) should not redirect to the login page on auth failure.
            // It should instead stay on the invitation submit form and display a message about how the token was invalid.
          }
          else {
            this.authenticationService.logOutAndRedirect();
          }
          return throwError(err);
        }
        else if (err.status === 500) {

          this.messageService.add('This application could not retrieve a response from the server.  Please try again later.', 'icon-alert',
            'authalert-error')

          return throwError(err);
        }
        else {
          this.messageService.add('Unexpected network error.  Please check your internet connection', 'icon-alert',
            'authalert-error')
          return throwError(err);
        }

      })
    );
  }
}
