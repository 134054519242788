import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiService } from '../../shared/core/api.service';
import {
  CollegeAcademicsForAuthoring, ProgramGroupForAuthoring,
  ProgramGroupCreateRequest, PublishedProgramSummary,
} from '../../shared/programmapper-authoring.model';

import { ProgramGroupIcon } from './missing-types.type';

@Injectable({
  providedIn: 'root'
})
export class AcademicsService {

  constructor(private apiService: ApiService,
              ) {}

  getPrograms(): Observable<PublishedProgramSummary[]> {

    return this.apiService
      .getCollege<PublishedProgramSummary[]>(`programs`);
  }

  getAcademicsSiteContent(): Observable<CollegeAcademicsForAuthoring> {
    return this.apiService.getCollege<CollegeAcademicsForAuthoring>(
      'college-academics');
  }

  createAcademics(academicsSiteContent: CollegeAcademicsForAuthoring): Observable<CollegeAcademicsForAuthoring> {
    return this.apiService.postCollege<CollegeAcademicsForAuthoring, CollegeAcademicsForAuthoring>(academicsSiteContent, `college-academics`);
  }

  putAcademicsSiteContent(academicsSiteContent: CollegeAcademicsForAuthoring):
    Observable<CollegeAcademicsForAuthoring> {
      return this.apiService.putCollege<CollegeAcademicsForAuthoring>(academicsSiteContent, `college-academics`);
  }

  getProgramGroup(programUuid: string): Observable<ProgramGroupForAuthoring> {
    return this.apiService.getCollege(`program-groups/${programUuid}`);
  }

  getProgramGroupIcons(): Observable<ProgramGroupIcon[]> {
    return of(this.programGroupIcons);
  }

  getProgramGroupIcon(iconId: number): Observable<ProgramGroupIcon> {
    const programGroupIcon: ProgramGroupIcon = this.programGroupIcons.filter(
      (programGroupIcon: ProgramGroupIcon) => programGroupIcon.id === iconId).pop();

    return of(programGroupIcon);
  }

  updateProgramGroup(programGroup: ProgramGroupForAuthoring): Observable<ProgramGroupForAuthoring> {
    return this.apiService.putCollege<ProgramGroupForAuthoring>(
      programGroup, `program-groups/${programGroup.id}`);
  }

  createProgramGroup(programGroupCreateRequest: ProgramGroupCreateRequest): Observable<ProgramGroupForAuthoring> {
    return this.apiService.postCollege<ProgramGroupForAuthoring, ProgramGroupCreateRequest>(programGroupCreateRequest,
                                                          'program-groups');
  }

  deleteProgramGroup(programGroup: ProgramGroupForAuthoring): Observable<boolean> {
    return this.apiService.deleteCollege(`program-groups/${programGroup.id}`);
  }


 private programGroupIcons: ProgramGroupIcon[] = [
   {
     id: 0,
     title: 'No Icon',
     svgId: ''
   },
  {
    id: 1,
    title: 'Health Sciences',
    svgId: 'interestclustericon-healthsciences'
  },
  {
    id: 2,
    title: 'Public Safety',
    svgId: 'interestclustericon-publicsafety',
  },
  {
    id: 3,
    title: 'Agriculture, Nutrition, & Culinary Arts',
    svgId: 'interestclustericon-agriculture',
  },
  {
    id: 4,
    title: 'Science, Technology, Engineering, & Math',
    svgId: 'interestclustericon-stem',
  },
  {
    id: 5,
    title: 'Social & Behavioral Sciences',
    svgId: 'interestclustericon-humanservices',
  },

  {
    id: 6,
    title: 'Education',
    svgId: 'interestclustericon-education',
  },
  {
    id: 7,
    title: 'Arts, Humanities, & Communication',
    svgId: 'interestclustericon-arts',
  },
  {
    id: 8,
    title: 'Industrial & Transportation Technology',
    svgId: 'interestclustericon-industrial',
  },
  {
    id: 9,
    title: 'Personal & Career Exploration',
    svgId: 'interestclustericon-career',
  },
  {
    id: 10,
    title: 'Business',
    svgId: 'interestclustericon-business',
  },
];

}
