import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

import {PublishedCourseSummary,
} from "../../../../../shared/programmapper-authoring.model";
import {CourseSelectService} from '../course-select.service';


@Component({
  selector: 'app-confirm-course-units-for-list',
  templateUrl: './confirm-course-units-for-list.component.html'
})
export class ConfirmCourseUnitsForListComponent implements OnInit {
  courseSummary: PublishedCourseSummary
  form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private curriculumItemSelectService: CourseSelectService,
  ) { }

  ngOnInit() {
    this.courseSummary = this.curriculumItemSelectService.selectedCourse;
    this.instantiateForm();
  }

  instantiateForm() {
    this.form = this.fb.group({
      units: [null, [Validators.required, unitsValidator(this.courseSummary.minUnits, this.courseSummary.maxUnits)]],
      minUnits: [{value: this.courseSummary.minUnits, disabled: true}, [Validators.required, unitsValidator(this.courseSummary.minUnits, this.courseSummary.maxUnits)]],
      maxUnits: [{value: this.courseSummary.maxUnits, disabled: true}, [Validators.required, unitsValidator(this.courseSummary.minUnits, this.courseSummary.maxUnits)]]
    }, { validator: UnitsRangeValidator});
  }

  submitForm() {
    const unitsInput = this.form.get('units');
    const minUnitsInput = this.form.get('minUnits');
    const maxUnitsInput = this.form.get('maxUnits');

    if (!unitsInput.disabled) {
      this.curriculumItemSelectService.selectUnitsForSelectedCourse(unitsInput.value, unitsInput.value);
    } else if (!minUnitsInput.disabled && !maxUnitsInput.disabled) {
      this.curriculumItemSelectService.selectUnitsForSelectedCourse(minUnitsInput.value, maxUnitsInput.value);
    }
    this.goBack();
    return false;
  }

  goBack() {
    this.router.navigate([{ outlets: { popup: ['courses-for-list-select'] } }],
      { relativeTo: this.route.parent,
        skipLocationChange: true });
  }

  cancel(){
    this.curriculumItemSelectService.selectedCourse = null;
    this.closeModal();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent,
        skipLocationChange: true });
  }

  disableInputs(event) {
    const unitsInput = this.form.get('units');
    const minUnitsInput = this.form.get('minUnits');
    const maxUnitsInput = this.form.get('maxUnits');

    if (event.target.value === 'singleUnits') {
      unitsInput.enable();
      minUnitsInput.disable();
      maxUnitsInput.disable();
    } else if (event.target.value === 'rangeUnits') {
      unitsInput.disable();
      minUnitsInput.enable()
      maxUnitsInput.enable();
    }
  }
}

const UnitsRangeValidator: ValidatorFn = (fg: FormGroup) => {
  const unitsInput = fg.get('units');
  const minUnits = fg.get('minUnits').value;
  const maxUnits = fg.get('maxUnits').value;
  if(unitsInput.enabled){
    // single units field does not require min/max range validation
    return null;
  }
  else {
    if(minUnits == null || isNaN(minUnits)  || "" == minUnits
      || maxUnits == null || isNaN(maxUnits) || "" == maxUnits){
      return null;
    }
    if(minUnits <0 || maxUnits <0){
      return null;
    }
    return +minUnits <= +maxUnits
      ? null
      : { unitsRangeValidator: true };
  }
};

function unitsValidator(min: number, max: number): ValidatorFn {

  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const units = control.value;
    return (!isNaN(units) && +units >= +min && +units <= +max) ? null : { 'unitsValidator': true };
  };
}
