import { Injectable } from '@angular/core';
import { Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';

import {AuthenticationService} from "../../../authentication.service";

@Injectable({
  providedIn: 'root'
})
export class SignUpGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const invitationToken = next.queryParamMap.get('invitationToken');

    if (! invitationToken) {
      this.router.navigate(['/', 'login']);
      return false;
    }
    this.authenticationService.logOut();
    return true;
  }
}
