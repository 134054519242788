import { Injectable } from '@angular/core';
import {ProgramMapSummary} from "../../../shared/programmapper-authoring.model";
import {BehaviorSubject, ReplaySubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProgramListService {

  listChanged$ : Subject<boolean> = new Subject();

  unconfirmedTransfers: ProgramMapSummary[];

  constructor() { }

  listChanged() {
    this.listChanged$.next(true);
  }
}
