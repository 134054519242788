import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ProgramGroupIcon } from '../../missing-types.type';

@Injectable({
  providedIn: 'root'
})
export class IconSelectService {
  iconSelected$: BehaviorSubject<ProgramGroupIcon> = new BehaviorSubject(null);

  constructor() { }

}
