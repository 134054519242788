import { NgModule } from '@angular/core';
import { CommonModule as CoreCommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ProgramSelectComponent } from './program-select/program-select.component';
import { IconSelectComponent } from './icon-select/icon-select.component';

import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [
    CoreCommonModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  declarations: [ProgramSelectComponent, IconSelectComponent],
  exports: [CoreCommonModule, RouterModule, ReactiveFormsModule]
})
export class CommonModule { }
