import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {MapCardType, MilestoneMapCard} from "../../../../shared/programmapper-authoring.model";
import {MilestoneService} from "../milestone.service";
import {MilestoneData} from "../../missing-types.type";
import {passwordsMatchValidator} from "../../../../anonymous/login/reset-password/reset-password.component";
import {linkTextValidator, linkUrlValidator} from "../builder.mixin";

@Component({
  selector: 'app-milestone-create',
  templateUrl: './milestone-create.component.html',
  styleUrls: ['./milestone-create.component.css']
})
export class MilestoneCreateComponent implements OnInit {
  private form: FormGroup;

  constructor(
    protected fb: FormBuilder,
    protected milestoneService: MilestoneService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    const id: string = Math.random().toString(36).replace('0.', '');
    this.form = this.fb.group({
      id: id,
      title: ['', [Validators.required, , Validators.maxLength(100)]],
      shortDescription: ['', [Validators.required, Validators.maxLength(100)]],
      longDescription: ['', [Validators.required, Validators.maxLength(1000)]],
      linkUrl: [null, [Validators.pattern('^(https?:/\/\.*)') ]],
      linkText: [null],
    }, { validators: [linkUrlValidator, linkTextValidator]});
  }

  submitForm(): boolean {
    const milestoneData = this.form.value as MilestoneData;
    this.milestoneService.milestoneAdded$.next(milestoneData);
    return false;
  }

  cancel() {
    this.milestoneService.milestoneAdded$.next(undefined);
  }
}


