import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CollegeRoutingModule } from './college-routing.module';
import { GeneralModule } from './general/general.module';
import { AboutModule } from './about/about.module';
import { UsersModule } from './users/users.module';
import { CollegeComponent } from './college.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CollegeRoutingModule,
    GeneralModule,
    AboutModule,
    UsersModule
  ],
  declarations: [CollegeComponent]
})
export class CollegeModule { }
