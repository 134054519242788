import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { VersionsListService } from './versions-list.service';
import {PublishedCurriculumSummary} from "../../../../shared/programmapper-authoring.model";

@Component({
  selector: 'app-versions-list',
  templateUrl: './versions-list.component.html',
  styleUrls: ['./versions-list.component.css']
})
export class VersionsListComponent implements OnInit {
  curriculumVersions: Observable<PublishedCurriculumSummary[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private versionsListService: VersionsListService) { }

  ngOnInit() {
    this.curriculumVersions =
      this.versionsListService.getCollegeCurriculumVersions();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }
}
