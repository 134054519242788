import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {forkJoin, Observable, of} from "rxjs";
import {
  CurriculumElementSummary, CurriculumElementType, PublishedCourseListSummary,
  PublishedGeneralEducationAreaSummary
} from "../../../../../shared/programmapper-authoring.model";
import {MapsService} from "../../../maps.service";
import {mergeMap} from "rxjs/internal/operators";
import {PrebuiltCourseListSelectService} from "../prebuilt-courselist-select.service";

@Component({
  selector: 'app-course-list-select',
  templateUrl: './course-list-select.component.html',
  styleUrls: ['./course-list-select.component.css']
})
export class CourseListSelectComponent implements OnInit {

  courseLists: PrebuiltCourseListOrGenEdAreaWithDisplayTitle [];

  onPage = 1;
  perPage = 10;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected prebuiltCourseListSelectService: PrebuiltCourseListSelectService,
    protected mapsService: MapsService,
) {}

  ngOnInit() {
    if (this.prebuiltCourseListSelectService.selectedPrebuiltCourseListAndUnits$.observers.length === 0) {
      // if no one is listening for a prebuilt course list or gen ed area to be added, modal was likely restored from URL
      this.closeModal();
    } else {
      this.getCurriculumItemsObservable().subscribe(
        (curriculumItems: PrebuiltCourseListOrGenEdAreaWithDisplayTitle []) => {
          this.courseLists = curriculumItems
        });
    }
  }

  selectCourseListWithDefaultUnits(courseListOrGenEdArea: PublishedCourseListSummary | PublishedGeneralEducationAreaSummary): void {
    this.prebuiltCourseListSelectService.selectPrebuiltCourseListWithDefaultUnits(courseListOrGenEdArea);
    this.closeModal();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent,
        skipLocationChange: true });
  }

  openUnitsSelect(courseListOrGenEdArea: PublishedCourseListSummary | PublishedGeneralEducationAreaSummary){
      this.openPrebuiltCourseListUnitsSelect(courseListOrGenEdArea);
  }

  private openPrebuiltCourseListUnitsSelect(courseListSummary: PublishedCourseListSummary) {
    this.prebuiltCourseListSelectService.selectPrebuiltCourseListWithDeferredUnitsSelection(courseListSummary);
    this.router.navigate([{
        outlets: { popup: ['confirm-prebuilt-courselist-units'] } }],
      { relativeTo: this.route.parent, skipLocationChange: true });
  }

  private getCurriculumItemsObservable() : Observable<PrebuiltCourseListOrGenEdAreaWithDisplayTitle[]> {

    return forkJoin(
      this.mapsService.getCourseLists(),
      this.mapsService.getGenEdAreas()
    ).pipe(
      mergeMap(
        ([courseLists, generalEdAreas]: [PublishedCourseListSummary[], PublishedGeneralEducationAreaSummary[]]) => {
          const combinedCourseListAndGenEdArea: PrebuiltCourseListOrGenEdAreaWithDisplayTitle[] = new Array();
          courseLists.forEach(courseList =>
            combinedCourseListAndGenEdArea.push(
              Object.assign(courseList, {displayTitle: this.getTitleDisplay(courseList)})
          ));
          generalEdAreas.forEach(generalEdArea =>
            combinedCourseListAndGenEdArea.push(
              Object.assign(generalEdArea, {displayTitle: this.getTitleDisplay(generalEdArea)})
          ));
          return of(combinedCourseListAndGenEdArea);
        }
      )
    )
  }

  getTitleDisplay(curriculumItem: CurriculumElementSummary) {

    switch(curriculumItem.type){
      case CurriculumElementType.COURSE_LIST: {
        return (curriculumItem as PublishedCourseListSummary).title;
      }
      case CurriculumElementType.GENERAL_EDUCATION_AREA: {
        const genEdArea = (curriculumItem as PublishedGeneralEducationAreaSummary);
          return genEdArea.generalEducationPattern + " / " + genEdArea.area + " / " + genEdArea.title;
      }
      default : {
        throw new Error("curriculumType " + curriculumItem.type + " not supported");
      }
    }
  }

}

/* the naive table component's sorting and searching does not work when the display title is calculated (vs a field value)
* This wrapper object provides a field for displayTitle
*/
export interface PrebuiltCourseListOrGenEdAreaWithDisplayTitle{
  displayTitle: string
}
