import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationHiddenService {
  private data = new BehaviorSubject(false);
  data$ = this.data.asObservable();

  constructor() { }

  changeData(data: boolean) {
    this.data.next(data)
  }
}
