import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {CollegeSummary, ProgramWithMap, PublishedProgramSummary} from '../../../shared/programmapper-authoring.model';

@Component({
  selector: 'app-map-update',
  templateUrl: './map-update.component.html',
  styleUrls: ['./map-update.component.css']
})
export class MapUpdateComponent implements OnInit {
  program: PublishedProgramSummary;
  private programWithMap: ProgramWithMap;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.parent.parent.data.subscribe(
      (data: { mapsData: [PublishedProgramSummary, ProgramWithMap, CollegeSummary[]] }) => {
        const [program, programWithMap, ..._] = data.mapsData;
        this.program = program;
        this.programWithMap = programWithMap;
    });
  }

}
