import { DataIssue, ActivityLog } from './missing-types.type';
import {PublishedSiteVersion} from "../../shared/programmapper-authoring.model";

export const dataIssues: DataIssue[] = [
  {
    id: 1,
    severity: 'CRITICAL',
    description: 'Lorem ipsum',
    program: 1,
    programName: 'Biology - Emphasis Human (A.S. Degree For Transfer)',
  },
  {
    id: 2,
    severity: 'CRITICAL',
    description: 'Lorem ipsum',
    program: 2,
    programName: 'Music (A.A. Degree For Transfer)',
  },
  {
    id: 3,
    severity: 'WARNING',
    description: 'Lorem ipsum',
    program: 3,
    programName: 'Studio Arts (A.A. Degree For Transfer)',
  },
];

export const activityLogs: ActivityLog[] = [
  {
    id: 1,
    date: '05/04/18 03:02 PM',
    description: 'Publish Content',
    collegeUser: 1,
    userName: 'Marcel Abbott',
  },
  {
    id: 2,
    date: '05/04/18 03:02 PM',
    description: 'Update Curriculum Version',
    collegeUser: 1,
    userName: 'Marcel Abbott',
  },
  {
    id: 3,
    date: '05/04/18 03:02 PM',
    description: 'Update College Logo',
    collegeUser: 3,
    userName: 'Pierre Vance',
  },
  {
    id: 4,
    date: '05/04/18 03:02 PM',
    description: 'Update Curriculum Version',
    collegeUser: 3,
    userName: 'Pierre Vance',
  },
  {
    id: 5,
    date: '05/04/18 03:02 PM',
    description: 'Publish Content',
    collegeUser: 2,
    userName: 'Lance Crawford',
  },
];

export const publishedSiteVersions: PublishedSiteVersion[] = [
  {
    publishedSiteContentId: "12345",
    year: 2021,
    revision: 12,
    publishedSiteUrl: "http://test1.com",
    mapDeepLinksUrl: "http://test1.com",
    isDefaultActiveOnProd: true,
  },
  {
    publishedSiteContentId: "05745dc9-5d95-40c6-9b8d-893b394bdb8b",
    year: 2020,
    revision: 41,
    mapDeepLinksUrl: "http://test2.com",
    isDefaultActiveOnProd: false,
  },
  {
    publishedSiteContentId: "573e01b6-1b83-4649-89a4-4039a39c47b9",
    year: 2018,
    revision: 9,
    mapDeepLinksUrl: "http://test3.com",
    isDefaultActiveOnProd: false,
  },
];
