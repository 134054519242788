import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ProgramWithMap, PublishedProgramSummary} from "../../../../shared/programmapper-authoring.model";
import {MapsService} from "../../maps.service";
import {MapListService} from "../map-list.service";

@Component({
  selector: 'app-advising-link-modal',
  templateUrl: './advising-link-modal.component.html',
})
export class AdvisingLinkModalComponent implements OnInit {
  saving = false;
  form: FormGroup;
  program: ProgramWithMap;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private mapsService: MapsService,
              private mapListService: MapListService) { }

  ngOnInit() {
    this.initProgram();
  }

  private initProgram() {
    const programId: string = this.route.parent.parent.parent.snapshot.params.programId;

    this.mapsService.getProgramWithMap(programId).subscribe(
      (program: ProgramWithMap) => {
        this.program = program;
        // TODO: remove this.
        this.program.advisingLink = program.advisingLink;
        this.initAdvisingLinkForm();
      }
    );
  }

  private initAdvisingLinkForm() {
    this.form = this.fb.group({
      advisingLink: [this.program.advisingLink ? this.program.advisingLink: '', [Validators.pattern('^(https?:/\/\.*)') ]],
    });
  }

  get advisingLink(): AbstractControl { return this.form.get('advisingLink'); }

  submitForm() {
    console.log("ID", this.program.masterRecordId);
    console.log("VALUE", this.form.value.advisingLink);
    this.mapsService.updateProgramAdvisingLink(this.program.masterRecordId, this.form.value.advisingLink).subscribe(
      result => {
        this.closeModal();
      }
    )
  }

  closeModal() {
    this.mapListService.updateMapList.next(true);

    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent, skipLocationChange: true });
  }

  cancel() {
    this.closeModal();
  }
}
