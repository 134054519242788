export enum MapControlsAction {
  EDIT = 'EDIT',
  REMOVE = 'REMOVE',
  MOVE_UP = 'MOVE_UP',
  MOVE_DOWN = 'MOVE_DOWN',
  MARK_RECOMMENDED = 'MARK_RECOMMENDED',
}

export interface MapControlsConfig {
  showRecommend?: boolean;
  showEdit?: boolean;
  showRemove?: boolean;
}
