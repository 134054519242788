import { Component, OnInit, Renderer2 } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import {combineLatest} from 'rxjs';

import { GeneralService } from '../../../college/general/general.service';

import { MessageService } from '../../../../shared/message/message.service';
import {MapStatus, ProgramMap, ProgramWithMap, PublishedProgramSummary,
} from '../../../../shared/programmapper-authoring.model';

import { BuilderMixin } from '../../common/builder.mixin';
import { CustomCourseChoiceService } from '../../common/custom-course-choice.service';
import { MapControlsService } from '../../common/map-controls/map-controls.service';
import { ConfirmNavigationService } from '../../common/confirm-navigation/confirm-navigation.service';

import { MapsService } from '../../maps.service';

import { trigger, state, style, transition, animate } from '@angular/animations';
import {CourseSelectService} from "../../common/curriculum-item-select/course-select.service";
import {ConfirmRemoveTermService} from "../../common/confirm-remove-term/confirm-remove-term.service";
import {LinkedMapsService} from "../../common/linked-maps/linked-maps.service";
import {SelectedYearAndVersionService} from "../../../selected-year-and-version.service";
import {MilestoneService} from "../../common/milestone.service";
import {PrebuiltCourseListSelectService} from "../../common/curriculum-item-select/prebuilt-courselist-select.service";
import { CourseMapCardChoiceService } from '../../common/course-map-card-choice.service';

@Component({
  selector: 'app-builder-update',
  templateUrl: '../../common/builder.component.html',
  styleUrls: ['./builder.component.css'],
  animations: [
      trigger('slidePanel', [
          state('inactive', style({
              height: '0'
          })),
          state('active', style({
              height: '*'
          })),
          transition('inactive => active', animate('200ms ease-in')),
          transition('active => inactive', animate('200ms ease-out'))
      ]),
  ]
})
export class BuilderComponent extends BuilderMixin implements OnInit {
  templateTitle = 'Edit Map';
  templateCanDelete = true;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected fb: FormBuilder,
    protected generalService: GeneralService,
    protected mapsService: MapsService,
    protected customCourseListService: CustomCourseChoiceService,
    protected milestoneService : MilestoneService,
    protected mapControlsService: MapControlsService,
    protected confirmNavigationService: ConfirmNavigationService,
    protected renderer: Renderer2,
    protected messageService: MessageService,
    protected courseSelectService: CourseSelectService,
    protected prebuiltCourseListSelectService: PrebuiltCourseListSelectService,
    protected confirmRemoveTermService: ConfirmRemoveTermService,
    protected linkedMapsService: LinkedMapsService,
    protected selectedYearAndVersionService: SelectedYearAndVersionService,
    protected courseMapCardChoiceService: CourseMapCardChoiceService,
  ) {
    super(route, router, fb, generalService, mapsService,
          customCourseListService,
          milestoneService,
          courseSelectService, prebuiltCourseListSelectService, mapControlsService,
          confirmNavigationService, renderer,
          messageService, confirmRemoveTermService, linkedMapsService, selectedYearAndVersionService, courseMapCardChoiceService);
  }

  ngOnInit() {

    const mapsData = this.route.parent.parent.parent.data;
    const mapUpdateData = this.route.parent.data;



    combineLatest(mapsData, mapUpdateData).subscribe(
      ([mapsData, mapUpdateData]: [
        { mapsData: [PublishedProgramSummary, ProgramWithMap, string[]] },
        { mapUpdateData: [ProgramMap, string[]] }
      ]) => {
        const [program, ..._] = mapsData.mapsData;
        this.program = program;
        [this.map, this.pathwaysTitles] = mapUpdateData.mapUpdateData;
        this.addIfMissing(this.map.transferTo, this.pathwaysTitles);

          super.ngOnInit();
      }
    );
  }

  initializeMapTable(initialTermsPerYear: number, initialYears: number){

    if(this.mapsService.hasUnsavedMapState()) {
      const [unsavedMapFormData, unsavedMapDataIssues] = this.mapsService.unsavedMapState;
      if (unsavedMapFormData.programMasterRecordId === this.program.masterRecordId) {
        this.initializeFromUnsavedState(unsavedMapFormData, unsavedMapDataIssues);
      }
      else {
        this.mapsService.clearUnsavedMapState(); // it was for a different map
        this.initializeFromPreviouslySavedMap();
      }
    }
    else{
      this.initializeFromPreviouslySavedMap();
    }
  }

  private initializeFromPreviouslySavedMap() {
    this.table = this.convertMapYearsToTable(this.map.table);
    this.setErrors(this.map.errors);
  }

  protected submitForm() {
    this.saving = true;

    if (this.form.value.emphasis === '') {
      this.form.patchValue({emphasis: null});
    }

    const formData: ProgramMap = Object.assign({}, this.form.value);
    formData.table = super.convertTableToMapYearTable(this.table);

    this.mapsService.updateMap(formData).subscribe(
      (response: any) => {
        this.messageService.add(`Map successfully updated.`, 'icon-notify');
        this.confirmNavigationService.allowLeave$.next(true);
        this.mapsService.clearUnsavedMapState();
        this.router.navigate(['..'], {relativeTo: this.route.parent});
      },
      (error: HttpErrorResponse) => {
        this.messageService.add(`Map failed to update.`, 'icon-alert', 'authalert-error');
        this.saving = false;
      }
    );
  }

  initializeForm() {
      this.form = this.fb.group({
        id: [this.map.id, Validators.required],
        version : [this.map.version, Validators.required],
        programMasterRecordId: [this.map.programMasterRecordId, Validators.required],
        transferTo: [this.map.transferTo, Validators.required],
        mapStatus: [this.map.mapStatus, Validators.required],
        status: [(this.map.mapStatus === MapStatus.PUBLIC) ? true : false, Validators.required],
        isDefaultMap: [this.map.isDefaultMap, Validators.required],
        emphasis: [this.map.emphasis],
        // isIncludingBridgeTerm: [false, Validators.required],
        // isIncludesDevelopmental: [this.map.isIncludesDevelopmental, Validators.required],
        // isDisplayCustomCTA: [this.map.isDisplayCustomCTA, Validators.required],
      });

    if(this.mapsService.hasUnsavedMapState()) {
      const [unsavedMapFormData, , ..._] = this.mapsService.unsavedMapState;

      if (unsavedMapFormData.programMasterRecordId === this.program.masterRecordId) {
        super.overrideFormFromUnsavedMapState(unsavedMapFormData, false, this.map.id, this.map.version);
      }
    }

    this.form.controls.status.valueChanges.subscribe(
      (status: boolean) => {
        this.form.controls.mapStatus.setValue(
          (status) ? MapStatus.PUBLIC : MapStatus.PRIVATE);
      });
  }

  deleteThis() {
    this.mapsService.deleteMap(this.map.id).subscribe((success: boolean) => {
      this.confirmNavigationService.allowLeave$.next(true);
      this.mapsService.clearUnsavedMapState();
      this.router.navigate(['..'], { relativeTo: this.route.parent });
      this.messageService.add('Map successfully deleted', 'icon-notify');
    });
  }

  private addIfMissing(pathwayTitle: string, pathwayTitles: string[]) {
    if(pathwayTitles.indexOf(pathwayTitle) === -1 ){
      pathwayTitles.unshift(pathwayTitle);
    }
  }
}
