import { Component, OnInit } from '@angular/core';
import { Observable, throwError, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormBuilder} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { CollegeAcademicsForAuthoring, ProgramGroupForAuthoringSummary } from '../../../shared/programmapper-authoring.model';

import { MapControlsService } from '../../maps/common/map-controls/map-controls.service';
import { MapControlsAction } from '../../maps/common/map-controls/map-controls.type';

import { ProgramGroupIcon } from '../missing-types.type';

import { AcademicsService } from '../academics.service';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-program-group-list',
  templateUrl: './program-group-list.component.html',
  styleUrls: ['./program-group-list.component.css']
})
export class ProgramGroupListComponent implements OnInit {
  programGroups: ProgramGroupForAuthoringSummary[];
  programGroupIcons: ProgramGroupIcon[];
  academicsSiteContent: CollegeAcademicsForAuthoring;
  updatingOrder = false;
  activeMapControl$: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private academicsService: AcademicsService,
    private mapControlsService: MapControlsService,
  ) { }

  ngOnInit() {
    this.academicsService.getAcademicsSiteContent()
      .subscribe((academicsSiteContent: CollegeAcademicsForAuthoring) => {

        if(isNullOrUndefined(academicsSiteContent)){
          this.academicsService.createAcademics({
            programGroups: [],
            version: 0
          }).subscribe(
            (createdAcademicsSiteContent: CollegeAcademicsForAuthoring) => {
              this.initialize(createdAcademicsSiteContent);
            }
          )
        }
        else {
          this.initialize(academicsSiteContent);
        }
      });

    this.academicsService.getProgramGroupIcons().subscribe(
      (programGroupIcons: ProgramGroupIcon[]) =>
      this.programGroupIcons = programGroupIcons);
  }

  private initialize(academicsSiteContent: CollegeAcademicsForAuthoring) {
    this.academicsSiteContent = academicsSiteContent;
    this.programGroups = academicsSiteContent.programGroups;
  }

  getIcon(iconId: number): Observable<ProgramGroupIcon> {
    return this.academicsService.getProgramGroupIcon(iconId);
  }

  ngOnDestroy() {
    if (this.activeMapControl$ && ! this.activeMapControl$.closed) {
      this.activeMapControl$.unsubscribe();
    }
  }

  updateOrder(objectList: any[], index: number,  newIndex: number) {
    if (newIndex >= 0 && newIndex < objectList.length) {
      this.updatingOrder = true;
      const objectListCopy = new Array(...objectList);

      const movee = objectList[index],
        moved = objectList[newIndex];

      objectList[index] = moved;
      objectList[newIndex] = movee;

      this.academicsService.putAcademicsSiteContent(this.academicsSiteContent)
        .pipe(
          catchError((err: any, caught: Observable<CollegeAcademicsForAuthoring>) => {
            this.programGroups = objectListCopy;  // Revert order
            this.updatingOrder = false;
            return throwError(err);
          }),
        ).subscribe((academicsSiteContent: CollegeAcademicsForAuthoring) => {
          this.updatingOrder = false;
        });
    }
  }


  openMapControlsFor(programGroup: ProgramGroupForAuthoringSummary, groupIndex: number) {
    this.mapControlsService.initialize<ProgramGroupForAuthoringSummary>(
      programGroup, { showRemove: false, showEdit: true });

    // TODO: Don't duplicate util
    if (this.activeMapControl$ && ! this.activeMapControl$.closed) {
      this.activeMapControl$.unsubscribe();
    }

    this.activeMapControl$ = this.mapControlsService.mapAction$.subscribe(
      (action: MapControlsAction) => {
        switch (action) {
          case MapControlsAction.EDIT:
            this.router.navigate(
              ['..', 'program-group-update', programGroup.programGroupId, {
                outlets: { popup: null } }], { relativeTo: this.route });
            break;
          case MapControlsAction.MOVE_UP:
            this.updateOrder(this.programGroups, groupIndex, groupIndex - 1);
            this.closePopups();
            break;
          case MapControlsAction.MOVE_DOWN:
            this.updateOrder(this.programGroups, groupIndex, groupIndex + 1);
            this.closePopups();
            break;
          default:
            break;
        }
    });

    this.router.navigate([{ outlets: { popup: ['map-controls'] } }],
                         { relativeTo: this.route, skipLocationChange: true });
  }

  closePopups() {
    this.router.navigate([{ outlets: { popup: null, subPopup: null } }],
                         { relativeTo: this.route, skipLocationChange: true });
  }
}
