import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthenticatedRoutingModule} from "./authenticated-routing.module";
import {SharedModule} from "../shared/shared.module";
import {ChooseYearModalComponent} from "./choose-year-modal/choose-year-modal.component";

@NgModule({
  imports: [
    CommonModule,
    AuthenticatedRoutingModule,
    SharedModule,
  ],
  declarations: [ChooseYearModalComponent],
})
export class AuthenticatedModule { }
