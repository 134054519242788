import { Component, Renderer2 } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import {
  ProgramGroupCreateRequest,
  ProgramGroupForAuthoring
} from '../../../shared/programmapper-authoring.model.d';
import { MessageService } from '../../../shared/message/message.service';

import { ProgramSelectService } from '../common/program-select/program-select.service';
import { IconSelectService } from '../common/icon-select/icon-select.service';

import { AcademicsService } from '../academics.service';
import { ProgramGroupMixin } from '../program-group.mixin';

@Component({
  selector: 'app-program-group-create',
  templateUrl: '../common/group.component.html',
  styleUrls: ['./program-group-create.component.css']
})
export class ProgramGroupCreateComponent extends ProgramGroupMixin {
  templateTitle = 'Add Program Group';
  templateSaveButtonText = 'Add Group';
  templateCanDelete = false;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private fb: FormBuilder,
    private messageService: MessageService,
    private academicsService: AcademicsService,
    protected programSelectService: ProgramSelectService,
    protected iconSelectService: IconSelectService,
    protected renderer: Renderer2,
  ) {
    super(route, router, programSelectService, iconSelectService, renderer);
    this.renderer.removeClass(document.body, 'l-staticfooterpage');
    this.renderer.addClass(document.body, 'l-fixedfooterpage');
  }

  ngOnInit() {
    this.programSelectService.initialize([]);
    super.listenForProgramAdditions();

    this.iconSelectService.iconSelected$.next(null);
    super.listenForIconSelection();

    this.initializeForm();
  }


  initializeForm() {

    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(60)]],
      description: ['', [Validators.required,  Validators.maxLength(750)]],
      iconId: [null, Validators.required],
    });
  }

  cancelButton() {
    // Reset form
    this.initializeForm();
    // Reset selected icon
    this.programGroupIcon = null;
    this.iconSelectService.iconSelected$.next(null);
    // Reset Programs we show
    this.programReferences = [];
    // Reset Progrms modal knows we've chosen
    this.programSelectService.initialize([]);
  }

  submitForm() {
    this.saving = true;
    const formData: ProgramGroupCreateRequest = Object.assign({}, this.form.value);
    formData.programs = this.programReferences;

    this.academicsService.createProgramGroup(formData).subscribe(
      (programGroup: ProgramGroupForAuthoring) => {
        this.messageService.add('Program group sucessfully created.', 'icon-notify');
        this.router.navigate(['..'], { relativeTo: this.route });
      },
      (error: HttpErrorResponse) => {
        this.messageService.add('Program group failed to submit.', 'icon-alert', 'authalert-error');
        this.saving = false;
      }
    );
  }
}
