import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../../../shared/core/api.service';
import {PublishedCurriculumSummary} from "../../../../shared/programmapper-authoring.model";

@Injectable({
  providedIn: 'root'
})
export class VersionsListService {

  constructor(private apiService: ApiService) {}

  getCollegeCurriculumVersions(): Observable<PublishedCurriculumSummary[]> {
    return this.apiService.getCollege<PublishedCurriculumSummary[]>('curriculums');
  }
}
