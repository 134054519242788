import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit, OnDestroy {

  constructor( private renderer: Renderer2 ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, 'l-staticfooterpage-white');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'l-staticfooterpage-white');
  }
}
