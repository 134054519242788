import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MessageService } from '../../../shared/message/message.service';
import {ResetPasswordService} from "../reset-password/reset-password.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private messageService: MessageService,
    private resetPasswordService: ResetPasswordService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.renderer.addClass(document.body, 'l-staticfooterpage-white');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'l-staticfooterpage-white');
  }

  initializeForm() {
    this.form = this.fb.group({
      'email': ['', [Validators.required, Validators.email]],
    });
  }

  submitForm() {
    this.resetPasswordService.requestForgotPasswordEmail(this.form.value)
      .subscribe((response: boolean) => {
          this.messageService.add(`Email sent to ${this.form.controls.email.value}`, 'icon-notify');
      },
      (error: HttpErrorResponse) => {
        this.messageService.add(`Email failed to send to ${this.form.controls.email.value}`, 'icon-alert', 'authalert-error');
      });

  }
}
