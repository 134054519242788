import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { PublishedProgramSummary } from "../../../../shared/programmapper-authoring.model";

@Injectable({
  providedIn: 'root'
})
export class ProgramSelectService {
  programGroupPrograms$: BehaviorSubject<PublishedProgramSummary[]> = new BehaviorSubject([]);
  programSelected$: Subject<PublishedProgramSummary> = new Subject();

  selectProgram(program: PublishedProgramSummary) {
    this.programSelected$.next(program);
    this.addProgram(program);
  }

  initialize(programs: PublishedProgramSummary[]) {
    this.programGroupPrograms$.next(new Array(...programs));
  }

  addProgram(addedProgram: PublishedProgramSummary) {
    const selectedPrograms = this.programGroupPrograms$.value;
    selectedPrograms.push(addedProgram);
    this._setSelectedPrograms(selectedPrograms);
  }

  removeProgram(removedProgram: PublishedProgramSummary) {
    const selectedPrograms = this.programGroupPrograms$.value;
    this._setSelectedPrograms(selectedPrograms.filter(
      (program: PublishedProgramSummary) => {
        return (program.masterRecordId !== removedProgram.masterRecordId);
    }));
  }

  private _setSelectedPrograms(programs: PublishedProgramSummary[]) {
    this.programGroupPrograms$.next(programs);
  }

  clearSelectedPrograms() {
    this.programGroupPrograms$.next([]);
  }
}
