import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../authentication.guard';
import { SelectedCollegeGuard } from '../selected-college.guard';

import { PublishComponent } from './publish.component';

import { ReviewComponent } from './review/review.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import {MapExportComponent} from "./map-export/map-export.component";

const routes: Routes = [
  {
    path: 'publish',
    component: PublishComponent,
    canActivate: [AuthenticationGuard, SelectedCollegeGuard],
    canActivateChild: [AuthenticationGuard, SelectedCollegeGuard],
    children: [
      {
        path: '',
        redirectTo: 'review',
        pathMatch: 'full',
      },
      {
        path: 'review',
        component: ReviewComponent,
      },
      {
        path: 'activity-log',
        component: ActivityLogComponent,
      },
      {
        path: 'export',
        component: MapExportComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublishRoutingModule { }
