import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { LoginGuard } from './login.guard';
import { SignUpGuard } from './sign-up/sign-up.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignUpFromInvitationComponent } from './sign-up-from-invitation/sign-up-from-invitation.component';

const routes: Routes = [{
  path: 'login',
  children: [
    {
      path: '',
      pathMatch: 'full',
      component: LoginComponent,
      canActivate: [LoginGuard]
    },
    {
      path: 'sign-up',
      canActivateChild: [SignUpGuard],
      children: [
        {
          path: 'invitation',
          component: SignUpFromInvitationComponent,
        },
      ],
    },
    {
      path: 'forgot-password',
      component: ForgotPasswordComponent,
    },
    {
      path: 'reset-password',
      component: ResetPasswordComponent,
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
