import {Component, OnDestroy, OnInit} from '@angular/core';

import { AcademicsService } from '../../academics/academics.service';
import {
  MapStatus, ProgramMapSummary, ProgramWithMap,
  PublishedProgramSummary
} from '../../../shared/programmapper-authoring.model';

import { MapsService } from '../maps.service';
import {isNullOrUndefined} from "util";
import {ProgramListService} from "./program-list.service";
import {Subscription} from "rxjs/index";
import {ActivatedRoute, Router} from "@angular/router";
import {LinkedMapsService} from "../common/linked-maps/linked-maps.service";

@Component({
  selector: 'app-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.css']
})
export class ProgramListComponent implements OnInit, OnDestroy {

  componentLoaded = false;
  programs: PublishedProgramSummary[];
  programsWithMaps: ProgramWithMap[];

  onPage = 1;
  perPage = 10;

  totalMaps;
  totalActiveMaps;
  totalOrphanMaps;
  totalPrivateMaps;
  totalTransferMaps;
  totalPublicTransferMaps;
  totalIncompleteTransferMaps;

  private listChangedSubscription: Subscription;

  unconfirmedTransfers: ProgramMapSummary[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private academicsSerice: AcademicsService,
    private mapsService: MapsService,
    private programListService: ProgramListService,
    private linkedMapsService: LinkedMapsService
  ) { }

  ngOnInit() {
    this.academicsSerice.getPrograms().subscribe((programs: PublishedProgramSummary[]) => {
      this.programs = programs;
      this.loadProgramWithMaps();
    });
    this.listChangedSubscription = this.programListService.listChanged$.subscribe(
      () => {
        this.getUnconfirmedTransfers();
        this.componentLoaded = false;
        this.loadProgramWithMaps();
      });

    this.getUnconfirmedTransfers();
  }

  getUnconfirmedTransfers() {
    this.unconfirmedTransfers = [];

    // TODO load the unconfirmed transfers from server.
    //this.totalIncompleteTransferMaps = this.unconfirmedTransfers.length;
  }

  ngOnDestroy(): void {
    if (this.listChangedSubscription && ! this.listChangedSubscription.closed) {
      this.listChangedSubscription.unsubscribe();
    }
  }

  private loadProgramWithMaps() {

    this.mapsService.getMappedPrograms().subscribe((mappedPrograms: ProgramWithMap[]) => {
      this.programsWithMaps = mappedPrograms;
      this.initMapTotals(this.programs, this.programsWithMaps);
      this.componentLoaded = true;
    });
  }

  initMapTotals(programs: PublishedProgramSummary[], programsWithMaps: ProgramWithMap[]) {
    this.totalMaps = 0;
    this.totalActiveMaps = 0;
    this.totalOrphanMaps = 0;
    this.totalPrivateMaps = 0;
    this.totalTransferMaps = 0;
    this.totalPublicTransferMaps = 0;
    this.totalIncompleteTransferMaps = 0;

    var publishedProgramsByMasterRecordId : {} = programs.reduce(function(map, publishedProgram) {
      map[publishedProgram.masterRecordId] = publishedProgram;
      return map;
    }, {});

    if (!isNullOrUndefined(programsWithMaps)) {

      // program is an orphan (published program with matching master record id was removed)
      programsWithMaps.forEach((programWithMap) => {
        if (!isNullOrUndefined(programWithMap.maps) && programWithMap.maps.length > 0) {
          // has at least one map
          this.totalMaps += this.getTotalMapCount(programWithMap.maps);
          if(isNullOrUndefined(publishedProgramsByMasterRecordId[programWithMap.masterRecordId])){
            // map is an orphan (published program with matching master record id was removed)
            this.totalOrphanMaps+=programWithMap.maps.length;
          }
          else{
            this.totalActiveMaps += this.getActiveMapCount(programWithMap.maps);
            this.totalPrivateMaps += this.getPrivateMapCount(programWithMap.maps);
            this.totalTransferMaps += this.getTransferMapCount(programWithMap.maps);
            this.totalPublicTransferMaps+= this.getPublicTransferMapCount(programWithMap.maps);
            this.totalIncompleteTransferMaps += this.getIncompleteTransferMapCount(programWithMap.maps);
          }
        }
      });
    }
  }

  getTransferMapCount(maps: ProgramMapSummary[]): number {
    if(isNullOrUndefined(maps)){
      return 0;
    }

     let count = 0;

    maps.forEach(
      (map) => {
        if (map.linkedMaps) {
          count = count + map.linkedMaps.length;
        }
      }
    );

    return count;
  }

  getPublicTransferMapCount(maps: ProgramMapSummary[]): number {
    if(isNullOrUndefined(maps)){
      return 0;
    }

    let count = 0;

    maps.forEach(
      (map) => {
        if (map.linkedMaps) {
            count = count + this.linkedMapsService.countPublicCompletedLinks(map);
        }
      }
    );

    return count;
  }

  getIncompleteTransferMapCount(maps: ProgramMapSummary[]): number {
    if(isNullOrUndefined(maps)){
      return 0;
    }

    let count = 0;

    maps.forEach(
      (map) => {
        if (map.linkedMaps) {
          count = count + this.linkedMapsService.countIncompleteMapLinks(map);
        }
      }
    );
    return count;
  }

  getActiveMapCount(maps: ProgramMapSummary[]): number {
    if(isNullOrUndefined(maps)){
      return 0;
    }
    return maps.filter((map: ProgramMapSummary) => map.status === MapStatus.PUBLIC).length;
  }

  orphanMapModalClosed(){
    this.componentLoaded = false;
    this.loadProgramWithMaps();
  }

  getPrivateMapCount(maps: ProgramMapSummary[]): number {
    if(isNullOrUndefined(maps)){
      return 0;
    }
    return maps.filter((map: ProgramMapSummary) => map.status === MapStatus.PRIVATE).length;
  }

  getTotalMapCount(maps: ProgramMapSummary[]): number {
    if(isNullOrUndefined(maps)){
      return 0;
    }
    return maps.length;
  }

  mapsHaveError(maps: ProgramMapSummary[]): boolean {
    return maps.some((map: ProgramMapSummary) =>
                     map.errors.length > 0);
  }

  getProgramWithMap(program : PublishedProgramSummary) : ProgramWithMap {
    const matches:ProgramWithMap[]= this.programsWithMaps.filter( programWithMap => programWithMap.masterRecordId === program.masterRecordId);
    if(matches.length == 1){
      return matches[0];
    }
    else{
      return undefined;
    }
  }

  navigateToNotifications() {
    this.router.navigate(['/notifications']);
  }

}
