import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { Message } from './message.type';
import { MessageService } from './message.service';

import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-message',
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*',
      })),
      state('closed', style({
        height: '0',
      })),
      transition('open => closed', [
        animate('200ms ease-in')
      ]),
      transition('closed => open', [
        animate('200ms ease-out')
      ]),
    ]),
  ],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit, OnDestroy {
  messages$: Observable<Message[]>;
  messageAdded$: Subscription;

  constructor(
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.messages$ = this.messageService.messages$;
    this.messageAdded$ = this.messageService.messageAdded$.subscribe(
      (message: Message) => {
        setTimeout(() => message.isOpen = true, 1);

        if (message.iconId === 'icon-alert') {
          setTimeout(() => message.isOpen = false, 250000);
          setTimeout(() => this.removeMessage(message), 250100);
        } else {
          if (message.persist) {
            setTimeout(() => message.isOpen = false, 250000);
            setTimeout(() => this.removeMessage(message), 250100);
          } else {
            setTimeout(() => message.isOpen = false, 10000);
            setTimeout(() => this.removeMessage(message), 10100);
          }
        }

    });
  }

  ngOnDestroy() {
    this.messageAdded$.unsubscribe();
  }

  removeMessage(removeMessage: Message) {
    removeMessage.isOpen = false;
    // Remove message after animation is complete.
    setTimeout(() => this.messageService.removeMessage(removeMessage), 200);
  }
}
