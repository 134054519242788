import {EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, timer } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';

import { MessageService } from '../../../shared/message/message.service';
import {
  CollegeSummary, MapPreview, PublishedProgramSummary,
  PublishStatus
} from '../../../shared/programmapper-authoring.model';

import { MapsService } from '../maps.service';

import { ConfirmNavigationService } from './confirm-navigation/confirm-navigation.service';

import { AuthenticationHiddenService} from "../../authentication-hidden.service";


export class PreviewMixin implements OnInit, OnDestroy {
  program: PublishedProgramSummary;
  previewStatusPoll$;
  previewUuid: string;
  previewUrl: SafeResourceUrl;
  routingFromNoticeModal: Boolean = false;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected mapsService: MapsService,
    protected authenticationHiddenService: AuthenticationHiddenService,
    protected sanitizer: DomSanitizer,
    protected messageService: MessageService,
    protected confirmNavigationService: ConfirmNavigationService,
  ) { }

  ngOnInit() {
    if(this.route.snapshot.queryParams['publishedProgramSummary']){
      this.routingFromNoticeModal = true;
      this.authenticationHiddenService.changeData(true);
    }

    if(!this.routingFromNoticeModal){
      this.route.parent.parent.parent.data.subscribe(
        (data: { mapsData: [PublishedProgramSummary, CollegeSummary[]] }) => {
          const [program, ..._] = data.mapsData;
          this.program = program;
        });
    }
    else{
      //set this.program in a different way
      this.program = this.route.snapshot.queryParams['publishedProgramSummary'];
    }


    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.previewUuid = paramMap.get('previewUuid');

      const getMapPreview = this.mapsService.getMapPreview.bind(this.mapsService, this.previewUuid);

      this.previewStatusPoll$ = timer(0, 1000).pipe(concatMap(getMapPreview))
        .subscribe((mapPreviewSummary: MapPreview) => {
          if (mapPreviewSummary.publishStatus !== PublishStatus.PENDING) {
            this.previewStatusPoll$.unsubscribe();
          }

          switch (mapPreviewSummary.publishStatus) {
            case PublishStatus.COMPLETED:
              this.previewUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl(mapPreviewSummary.mapPreviewUrl);
              break;
            case PublishStatus.ERROR:
              this.messageService.add(
                `The server is unable to generate a preview for this map.`,
                'icon-alert', 'authalert-error');
                this.router.navigate(['builder'], {
                  queryParams: { previewUuid: this.previewUuid,},
                  relativeTo: this.route.parent,
                });
              break;
          }
        });
    });
  }

  ngOnDestroy(): void {
    if(this.routingFromNoticeModal){
      this.authenticationHiddenService.changeData(false);
    }
    if(this.previewStatusPoll$ && ! this.previewStatusPoll$.closed){
      this.previewStatusPoll$.unsubscribe();
    }
  }


  canDeactivate(nextPath: string): Observable<boolean> {
    return this.confirmNavigationService.allowLeave$.pipe(
      tap((canLeave: boolean) => {

        if (! canLeave) {
          this.router.navigate([{ outlets: {
            confirmLeave: ['confirm-leave', { next: nextPath }]
          } }], { relativeTo: this.route, skipLocationChange: true });
        }

      })
    );
  }

}
