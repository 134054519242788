import { Injectable } from '@angular/core';
import {
  CreateUserFromInvitationRequest,
  CreateUserFromInvitationResponse, ForgotPasswordRequest, ResetPasswordRequest
} from "../../../shared/programmapper-authoring.model";
import {ApiService} from "../../../shared/core/api.service";
import {Observable} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(
    private apiService: ApiService) { }

  resetPasswordFromToken(resetPasswordFromTokenRequest: ResetPasswordRequest ) {
    return this.apiService.post<boolean,
      ResetPasswordRequest>(resetPasswordFromTokenRequest, `password-resets`);
  }

  requestForgotPasswordEmail(createForgotPasswordEmailRequest: ForgotPasswordRequest) : Observable<boolean> {
    return this.apiService.post<boolean,
      ForgotPasswordRequest>(createForgotPasswordEmailRequest, `forgot-password-emails`);
  }
}
