import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { Message } from './message.type';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  messageAdded$: Subject<Message> = new Subject();
  messages$: BehaviorSubject<Message[]> = new BehaviorSubject([]);

  messageId = 0;

  constructor() { }

  /**
   * @param iconId use 'icon-notify' for status messages, 'icon-alert' for errors.
   * @param classes Not needed for status, use 'authalert-error' for errors.
   */
  add(message: string, iconId: string = '', classes: string | string[] = '', persist: boolean = false) {
    const messages = this.messages$.value;

    const messageObject: Message = {
      id: this.messageId,
      text: message,
      classes: classes,
      isOpen: false,
      iconId: iconId,
      persist: persist,
    };

    this.messageId++;

    messages.push(messageObject);
    this.messages$.next(messages);

    this.messageAdded$.next(messageObject);
  }

  removeMessage(removeMessage: Message) {
    let messages = this.messages$.value;

    messages = messages.filter((message: Message) =>
                               message.id !== removeMessage.id);

    this.messages$.next(messages);
  }
}
