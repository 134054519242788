import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ConfirmRemoveTermService} from "./confirm-remove-term.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-confirm-remove-term',
  templateUrl: './confirm-remove-term.component.html',
  styleUrls: ['./confirm-remove-term.component.css']
})
export class ConfirmRemoveTermComponent implements OnInit, OnDestroy {

  removeTermSub: Subscription;
  termName: string;

  constructor(protected router: Router,
              private route: ActivatedRoute,
              private confirmRemoveTermService: ConfirmRemoveTermService) { }

  ngOnInit() {
    this.removeTermSub = this.confirmRemoveTermService.termNameObservable$.subscribe(
      (termName) => {
        this.termName = termName;
      }
    )
  }

  ngOnDestroy() {
    this.removeTermSub.unsubscribe();
  }

  cancel() {
    this.confirmRemoveTermService.removeTermSubject.next(false);
    this.closeModal();
  }

  remove() {
    this.confirmRemoveTermService.removeTermSubject.next(true);
    this.closeModal();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }], {relativeTo: this.route.parent, skipLocationChange: true});
  }
}
