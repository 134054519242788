import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProgramWithMap } from "../../../../shared/programmapper-authoring.model";
import { MapsService } from "../../maps.service";
import { MapListService } from "../map-list.service";

@Component({
  selector: 'app-additional-info-link-modal',
  templateUrl: './additional-info-link-modal.component.html',
  styleUrls: ['./additional-info-link-modal.component.scss']
})
export class AdditionalInfoLinkModalComponent implements OnInit {
  saving = false;
  form: FormGroup;
  program: ProgramWithMap;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private mapsService: MapsService,
    private mapListService: MapListService) { }

  ngOnInit() {
    this.initProgram();
  }

  private initProgram() {
    const programId: string = this.route.parent.parent.parent.snapshot.params.programId;

    this.mapsService.getProgramWithMap(programId).subscribe(
      (program: ProgramWithMap) => {
        this.program = program;
        // TODO: remove this.
        this.program.additionalInfoLink = program.additionalInfoLink;
        this.initInfoLinkForm();
      }
    );
  }

  private initInfoLinkForm() {
    this.form = this.fb.group({
      additionalInfoLink: [this.program.additionalInfoLink ? this.program.additionalInfoLink: '', [Validators.pattern('^(https?:/\/\.*)') ]],
    });
  }

  get additionalInfoLink(): AbstractControl { return this.form.get('additionalInfoLink'); }

  submitForm() {
    console.log("ID", this.program.masterRecordId);
    console.log("VALUE", this.form.value.advisingLink);
    this.mapsService.updateProgramAdditionalInfoLink(this.program.masterRecordId, this.form.value.additionalInfoLink).subscribe(
      result => {
        this.closeModal();
      }
    )
  }

  closeModal() {
    this.mapListService.updateMapList.next(true);

    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent, skipLocationChange: true });
  }

  cancel() {
    this.closeModal();
  }
}
