import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject, Subject, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmRemoveTermService {
  removeTermSubject: Subject<boolean> = new Subject();
  removeTermObservable$ = this.removeTermSubject.asObservable();
  termNameSubject: BehaviorSubject<string> = new BehaviorSubject('');
  termNameObservable$ = this.termNameSubject;

  constructor() {}
}
