import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MapListService} from "../map-list.service";
import {PendingMapLink} from "../../../../shared/programmapper-authoring.model";
import {LinkedMapsService} from "../../common/linked-maps/linked-maps.service";
import {MessageService} from "../../../../shared/message/message.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-fix-missing-map-modal',
  templateUrl: './fix-missing-map-modal.component.html',
})
export class FixMissingMapModalComponent implements OnInit {

  pathwayTo: string;
  private pendingMapLink: PendingMapLink;
  showConfirmModal: boolean;
  saving = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private mapListService: MapListService,
              private linkedMapsService: LinkedMapsService,
              private messageService: MessageService,) { }

  ngOnInit() {
    this.pathwayTo = this.mapListService.pendingMapLink.map.transferTo;
    this.pendingMapLink = this.mapListService.pendingMapLink;
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent, skipLocationChange: true });
  }

  navigateToMapCreate() {
    this.router.navigate(['../', 'create', 'builder', { outlets: { popup: null } }],
      { relativeTo: this.route.parent });
  }

  openLinkExistingMapModal() {
    this.router.navigate([{ outlets: { popup: ['link-existing-map'] } }],
      { relativeTo: this.route.parent, skipLocationChange: true });
  }

  declineLink() {
    this.saving = true;

    this.linkedMapsService.declinePendingMapLink(this.pendingMapLink.map.mapId, this.pendingMapLink.linkedProgramId).subscribe(
      (response: boolean) => {
        this.showConfirmModal = false;
        this.closeModal();
        this.mapListService.updateMapList.next(true);
        this.messageService.add('Link successfully declined.', 'icon-notify');
      },
      (error: HttpErrorResponse) => {
        this.messageService.add('Error, declining link failed.',
          'icon-alert', 'authalert-error');
        this.saving = false;
      },
    );
  }

}
