import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

import { MessageService } from '../../../shared/message/message.service';

import { UsersService } from './users.service';
import {CollegeUser} from "../../../shared/programmapper-authoring.model";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {

  collegeUsers: CollegeUser[];
  private usersSub: Subscription;
  private usersChangedSub: Subscription;
  usersLoaded = false;

  constructor(
    private messageService: MessageService,
    private usersService: UsersService) { }

  ngOnInit() {
    this.loadCollegeUsers();
    this.usersChangedSub = this.usersService.userChanged$.subscribe(() => this.loadCollegeUsers())
  }

  ngOnDestroy(): void {
    if(this.usersChangedSub){
      this.usersChangedSub.unsubscribe();
    }
    this.usersSub.unsubscribe();
  }

  private loadCollegeUsers() {
    this.usersLoaded = false;

    this.usersSub = this.usersService.getCollegeUsers().subscribe(users => {
      this.collegeUsers = users;
      this.usersLoaded = true;
    });
  }

  resendInvite(collegeUser: CollegeUser) {
    this.usersService.resendInvite(collegeUser).subscribe((success: boolean) => {
      this.messageService.add(`Invitation sent to ${collegeUser.email}`, 'icon-notify');
    });
  }

  deleteUser(email: string) {
    this.usersService.deleteCollegeUser(email)
      .subscribe((success: boolean) => {
          this.messageService.add('User successfully deleted.', 'icon-notify');
        },
        (error: HttpErrorResponse) => {
          this.messageService.add('User could not be deleted.', 'icon-alert', 'authalert-error');
        });
  }
}
