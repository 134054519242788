import { Component, OnInit } from '@angular/core';
import {CourseSelectService} from "../course-select.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  CurriculumElementSummary,
   PublishedCourseSummary
} from "../../../../../shared/programmapper-authoring.model";
import {MapsService} from "../../../maps.service";

@Component({
  selector: 'app-courses-for-list-select',
  templateUrl: './courses-for-list-select.component.html'
})
export class CoursesForListSelectComponent implements OnInit {
  selectableCourses: PublishedCourseSummary[];

  onPage = 1;
  perPage = 10;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected courseSelectService: CourseSelectService,
    protected mapsService: MapsService,
  ) {}

  ngOnInit() {
    if (this.courseSelectService.selectedCourseAndUnits$.observers.length === 0) {
      // if no one is listening for a course to be added, modal was likely restored from URL
      this.closeModal();
    } else {
      this.mapsService.getCourses().subscribe(
        (curriculumItems: PublishedCourseSummary[]) => this.selectableCourses = curriculumItems);
    }
  }

  isCurriculumItemAlreadySelected(curriculumItem: CurriculumElementSummary): boolean {
    const selectedCurriculumItems = this.courseSelectService.allSelectedCurriculumItemsByMasterRecordId$.value;
    return selectedCurriculumItems.includes(curriculumItem.masterRecordId);
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent,
        skipLocationChange: true });
  }

  get allowMultiple() {
    return false; // TODO: return true here? to enable multiple select?
  }

  openUnitsSelect(publishedCourseSummary: PublishedCourseSummary){
    this.courseSelectService.selectCourseWithDeferredUnitsSelection(publishedCourseSummary);

    this.router.navigate([{
        outlets: { popup: ['confirm-course-units-for-list'] } }],
      { relativeTo: this.route.parent, skipLocationChange: true });
  }

  selectCurriculumItemWithDefaultUnits(curriculumItem: PublishedCourseSummary): void {
    this.courseSelectService.selectCourseWithDefaultUnits(curriculumItem);
  }

}
