import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';


import { AcademicsService } from '../../academics.service';

import { ProgramSelectService } from './program-select.service';
import { PublishedProgramSummary } from "../../../../shared/programmapper-authoring.model";

@Component({
  selector: 'app-program-select',
  templateUrl: './program-select.component.html',
  styleUrls: ['./program-select.component.css']
})
export class ProgramSelectComponent implements OnInit, OnDestroy {
  programs: PublishedProgramSummary[];
  selectedPrograms: PublishedProgramSummary[];
  programGroupPrograms$: Subscription;

  onPage = 1;
  perPage = 13;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private academicsService: AcademicsService,
    private programSelectService: ProgramSelectService
  ) { }

  ngOnInit() {
    const allPrograms$ = this.academicsService.getPrograms();
    const programGroupPrograms$ =
      this.programSelectService.programGroupPrograms$;

    allPrograms$.subscribe((allPrograms: PublishedProgramSummary[]) => {
      this.programs = allPrograms;
    });

    this.programGroupPrograms$ = programGroupPrograms$.subscribe(
      (programGroupPrograms: PublishedProgramSummary[]) => {
        this.selectedPrograms = programGroupPrograms;
    });
  }

  ngOnDestroy() {
    this.programGroupPrograms$.unsubscribe();
    // this.programSelectService.clearSelectedPrograms();
  }

  selectProgram(program: PublishedProgramSummary) {
    this.programSelectService.selectProgram(program);
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }

  isAdded(program: PublishedProgramSummary) {
    return this.selectedPrograms
      .map((program: PublishedProgramSummary) => program.masterRecordId)
      .includes(program.masterRecordId);
  }
}
