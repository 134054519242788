import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import {
  PublishedCourseListSummary, PublishedGeneralEducationAreaSummary,
} from "../../../../shared/programmapper-authoring.model";
import {isNullOrUndefined} from "util";

@Injectable({
  providedIn: 'root'
})
export class PrebuiltCourseListSelectService {

  selectedPrebuiltCourseList: PublishedCourseListSummary | PublishedGeneralEducationAreaSummary;
  private selectedMinUnits : number;
  private selectedMaxUnits : number;

  selectedPrebuiltCourseListAndUnits$ : Subject<SelectedPrebuiltCourseList | PublishedGeneralEducationAreaSummary> = new Subject();

  // min and max units are the same - no need to select card units
  selectPrebuiltCourseListWithDefaultUnits(selectedPrebuiltCourseList: PublishedCourseListSummary | PublishedGeneralEducationAreaSummary): void {
    if(selectedPrebuiltCourseList.minUnits !== selectedPrebuiltCourseList.maxUnits){
      throw new Error("min and max units must be the same to use this method.");
    }

    this.selectedPrebuiltCourseList = selectedPrebuiltCourseList;
    this.selectedMinUnits = Number(selectedPrebuiltCourseList.minUnits).valueOf();
    this.selectedMaxUnits = Number(selectedPrebuiltCourseList.minUnits).valueOf();

    this.selectedMinUnits = selectedPrebuiltCourseList.minUnits;
    this.selectedMaxUnits = selectedPrebuiltCourseList.minUnits;
    this.selectedPrebuiltCourseListAndUnits$.next({prebuiltCourseList:selectedPrebuiltCourseList, minUnits: this.selectedMinUnits, maxUnits:this.selectedMaxUnits, filterUnits: true})
  }

  selectPrebuiltCourseListWithDeferredUnitsSelection(selectedPrebuiltCourseList: PublishedCourseListSummary| PublishedGeneralEducationAreaSummary) {
    this.selectedMaxUnits = undefined;
    this.selectedMinUnits = undefined;
    this.selectedPrebuiltCourseList = selectedPrebuiltCourseList;
  }

  selectUnitsForSelectedPrebuiltCourseList(selectedMinUnit: number, selectedMaxUnit: number, filterUnits: boolean) {
    if(isNullOrUndefined(this.selectedPrebuiltCourseList)){
      throw new Error("must select a prebuilt course list first before setting units for it");
    }
    if(isNaN(selectedMinUnit) || isNaN(selectedMaxUnit)){
      throw new Error("min and max units must be numbers");
    }
    this.selectedMaxUnits = Number(selectedMaxUnit).valueOf();
    this.selectedMinUnits = Number(selectedMinUnit).valueOf();
    this.selectedPrebuiltCourseListAndUnits$.next({prebuiltCourseList:this.selectedPrebuiltCourseList, minUnits:this.selectedMinUnits, maxUnits:this.selectedMaxUnits, filterUnits: filterUnits})
  }
}

export interface SelectedPrebuiltCourseList {
  prebuiltCourseList: PublishedCourseListSummary| PublishedGeneralEducationAreaSummary
  minUnits: number;
  maxUnits: number;
  filterUnits: boolean;
}
