import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../authentication.guard';
import { SelectedCollegeGuard } from '../selected-college.guard';

import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserUpdateComponent } from './user-update/user-update.component';

const routes: Routes = [
  {
    path: 'account',
    canActivateChild: [AuthenticationGuard, SelectedCollegeGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/account/user-update',
      },
      {
        path: 'user-update',
        component: UserUpdateComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
