import { Injectable } from '@angular/core';

import { LocalStorageService } from 'ngx-webstorage';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {ApiService} from './shared/core/api.service';
import {Router} from '@angular/router';
import {isNullOrUndefined} from 'util';
import { AccountService } from './authenticated/account/account.service';
import {TokenResponse} from './shared/programmapper-auth.model';
import {SelectedCollegeService} from "./authenticated/selected-college.service";
import {ConfirmNavigationService} from "./authenticated/maps/common/confirm-navigation/confirm-navigation.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  redirectUrl: string;

  get isLoggedIn() : boolean {
    return this.localStorageService.retrieve('token');
  }

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private accountService: AccountService,
    private selectedCollegeService: SelectedCollegeService,
    private confirmNavigationService: ConfirmNavigationService,
    private router: Router
  ) { }

  logIn(email: string, password: string): Observable<boolean> {
    return this.apiService.postAuth<TokenResponse, any>({ email, password }, `authentication-tokens` )
      .pipe( map(userData => {
        if (!isNullOrUndefined(userData) && !isNullOrUndefined(userData.token)) {
          // login successful if there's a jwt token in the response
          this.updateToken(userData.token);
          this.accountService.setCurrentUser(
            {firstName: userData.firstName, lastName: userData.lastName, email: email});
          return true;
        }
        return false;
      }));
  }

  logOut(): void {
    this.localStorageService.clear('token');
    this.localStorageService.clear('currentUser');
    this.selectedCollegeService.clearCollege();

    this.confirmNavigationService.allowLeave$.next(true);
  }

  logOutAndRedirect(): void {
    this.logOut();
    this.router.navigate(['/login']);
  }

  updateToken(newToken: string) {
    this.localStorageService.store('token', newToken);
  }
}
