import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { AcademicsRoutingModule } from './academics-routing.module';
import { CommonModule as AcademicsCommonModule } from './common/common.module';
import { ProgramGroupListComponent } from './program-group-list/program-group-list.component';
import { ProgramGroupCreateComponent } from './program-group-create/program-group-create.component';
import { ProgramGroupUpdateComponent } from './program-group-update/program-group-update.component';

@NgModule({
  imports: [
    AcademicsCommonModule,
    SharedModule,
    AcademicsRoutingModule,
  ],
  declarations: [ProgramGroupListComponent, ProgramGroupCreateComponent, ProgramGroupUpdateComponent]
})
export class AcademicsModule { }
