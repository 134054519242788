import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {MessageService} from "../../../../shared/message/message.service";
import {
  ProgramMapBatchMoveRequest, ProgramMapMoveRequest, ProgramWithMap,
  PublishedProgramSummary
} from "../../../../shared/programmapper-authoring.model";
import {UsersService} from "../../../college/users/users.service";
import {AcademicsService} from "../../../academics/academics.service";
import {MapsService} from "../../maps.service";
import {isNullOrUndefined} from "util";
import {ProgramListService} from "../program-list.service";

@Component({
  selector: 'app-fix-orphaned-maps',
  templateUrl: './fix-orphaned-maps.component.html',
  styleUrls: ['./fix-orphaned-maps.component.css']
})
export class FixOrphanedMapsComponent implements OnInit {
  form: FormGroup;
  orphanMapsCount : number;
  saving = false;

  private programs: PublishedProgramSummary[];
  private programsWithMaps: ProgramWithMap[];
  private componentLoaded: boolean;
  private orphanedProgramsWithMaps : ProgramWithMap[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private usersService: UsersService,
    private fb: FormBuilder,
    private academicsService: AcademicsService,
    private mapsService: MapsService,
    private programListService: ProgramListService,
  ) { }

  ngOnInit() {
    this.academicsService.getPrograms().subscribe((programs: PublishedProgramSummary[]) => {
      this.programs = programs;

      this.mapsService.getMappedPrograms().subscribe( (mappedPrograms: ProgramWithMap[]) =>
      {
        this.programsWithMaps = mappedPrograms;
        this.initOrphanedMaps(programs, mappedPrograms);
        this.initForm();
        this.componentLoaded = true;
      });

    });
  }

  private initForm() {

    const mapSelector: FormGroup = new FormGroup({});

    this.orphanedProgramsWithMaps.forEach(
      orphanedProgramWithMaps=> {
        orphanedProgramWithMaps.maps.forEach(map => {
          const formFieldId : string = 'map-' + map.mapId;
          const control: FormControl = new FormControl(null);
          mapSelector.addControl(formFieldId, control);
        })
      });

    this.form = mapSelector;

  }

  submitForm() {
    this.saving = true;
//    let ProgramMapBatchMoveRequest
    let mapsToMove: ProgramMapMoveRequest[] = [];

    this.orphanedProgramsWithMaps.forEach(
      orphanedProgramWithMaps=> {
        orphanedProgramWithMaps.maps.forEach(map => {
          const formFieldId : string = 'map-' + map.mapId;
          const programToMoveTo = this.form.value[formFieldId];
          if(!isNullOrUndefined(programToMoveTo)){
            const mapMoveRequest : ProgramMapMoveRequest = {
              mapId: map.mapId,
              programMasterRecordId: programToMoveTo
            }
            mapsToMove.push( mapMoveRequest)
          }
        })
      });

    const programMapBatchMoveRequest: ProgramMapBatchMoveRequest = {
      programMapMoveRequests : mapsToMove
    }

    this.mapsService.moveMaps(programMapBatchMoveRequest).subscribe(
        () => {
          this.messageService.add(`Maps moved`,
            'icon-notify');
          this.closeModal();
        },
        (error: HttpErrorResponse) => {
          this.messageService.add(`Maps failed to move`,
            'icon-alert', 'authalert-error');
          this.closeModal();
        }
    );

  }

  closeModal() {
    this.programListService.listChanged();
    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent});
  }

  private initOrphanedMaps(programs: PublishedProgramSummary[], programsWithMaps: ProgramWithMap[]) : ProgramWithMap[]{

    var publishedProgramsByMasterRecordId : {} = programs.reduce(function(map, publishedProgram) {
      map[publishedProgram.masterRecordId] = publishedProgram;
      return map;
    }, {});

    if (isNullOrUndefined(programsWithMaps)) {
      return [];
    }
    this.orphanMapsCount = 0;

    let orphanMaps: ProgramWithMap[] = programsWithMaps.filter((programWithMap) => {
      if (!isNullOrUndefined(programWithMap.maps) && programWithMap.maps.length > 0) {
        // has at least one map
        if(isNullOrUndefined(publishedProgramsByMasterRecordId[programWithMap.masterRecordId])){
          this.orphanMapsCount+=programWithMap.maps.length;
          return true;
        }
      }
    });
    this.orphanedProgramsWithMaps =  orphanMaps;
  }

}
