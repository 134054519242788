import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { CollegeService } from './college/college.service';

import { SelectedCollegeService } from './selected-college.service';
import {CollegeSummary} from "../shared/programmapper-authoring.model";
import {isNullOrUndefined} from "util";

@Injectable({
  providedIn: 'root'
})
export class SelectedCollegeGuard implements CanActivate {

  constructor(
    private router: Router,
    private selectedCollegeService: SelectedCollegeService,
    private collegeService: CollegeService,
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.checkOrFetchCollege();
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      return this.canActivate(next, state);
  }

  checkOrFetchCollege(): Observable<boolean> | boolean {

    if (this.selectedCollegeService.selectedCollegeId) {
      return of(true);
    } else {
      return this.selectPreferredOrFirstAvailableCollege();
    }
  }

  // if the user has access to their preferred (ie most recently selected) college, select that college
  // otherwise, select the first college in the list that the user does have access to.
  selectPreferredOrFirstAvailableCollege(): Observable<boolean> {
    return this.collegeService.getColleges().pipe(
      mergeMap((colleges: CollegeSummary[]) => {
        if (colleges.length) {
          let college;
          const preferredCollegeId = this.selectedCollegeService.getPreferredCollegeId();
          if(!isNullOrUndefined(preferredCollegeId)){
            // if they have a preferred (ie previously selected) college id (local storage)
            const preferredColleges : CollegeSummary[] = colleges.filter(college => college.id === preferredCollegeId);
            if(preferredColleges.length > 0){
              // if they have access to their preferred college, use it
              college = preferredColleges[0];
            }
            else{
              // no access to the preferred college
              // use the first college they have access to, and reset the preferred college id
              college = colleges[0];
              this.selectedCollegeService.clearPreferredCollegeId()
            }
          }
          else{
            // otherwise, use the first college they have access to
            college = colleges[0];
          }
          return of(this.selectedCollegeService.selectCollege(college));
        } else {
          // no available colleges for this user
          this.router.navigate(['no-colleges']);
          return of(false);
        }
      }),
    );
  }

}
