import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthenticationGuard} from '../authentication.guard';
import {SelectedCollegeGuard} from '../selected-college.guard';
import {NotificationsComponent} from './notifications.component';
import {IssuesComponent} from './issues/issues.component';
import {NotificationsPanelComponent} from './notifications-panel/notifications-panel.component';

const routes: Routes = [
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthenticationGuard, SelectedCollegeGuard],
    canActivateChild: [AuthenticationGuard, SelectedCollegeGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'notifications'
      },
      {
        path: 'notifications',
        component: NotificationsPanelComponent,
      },
      {
        path: 'issues',
        component: IssuesComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule { }
