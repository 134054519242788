import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { MessageService } from '../../../shared/message/message.service';
import {CollegeAbout} from '../../../shared/programmapper-authoring.model';

import { CollegeMediaType } from '../missing-types.type';

import { AboutService } from './about.service';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  form: FormGroup;

  mediaTypes: CollegeMediaType[];
  collegeAbout: CollegeAbout;
  private createCollegeAbout: boolean;
  saving = false;

  constructor(
    private messageService: MessageService,
    private aboutService: AboutService,
    private fb: FormBuilder) { }

  ngOnInit() {
    const formData = forkJoin(this.aboutService.getMediaTypes(),
                              this.aboutService.getCollegeAbout());

    formData.subscribe(
      ([mediaTypes, collegeAbout]:
       [CollegeMediaType[], CollegeAbout]) => {
        this.mediaTypes = mediaTypes;

        if (isNullOrUndefined(collegeAbout)) {
          this.collegeAbout = {version: 0};
          this.createCollegeAbout = true;
        } else {
          this.collegeAbout = collegeAbout;
          this.createCollegeAbout = false;
        }
        this.instantiateForm();
      });
  }

  instantiateForm() {
    this.form = this.fb.group({
      title: [this.collegeAbout.title, [Validators.maxLength(40)]],
      description: [this.collegeAbout.description, [Validators.maxLength(750)]],
      version: [this.collegeAbout.version],
      customProgramMapTitle: [this.collegeAbout.customProgramMapTitle || null, [Validators.maxLength(24)]],
      //      name: [programGroup.name, [Validators.required, Validators.maxLength(60)]],
      // displayAboutContent: [this.collegeAbout.displayAboutContent, Validators.required],
      // mediaType: [this.collegeAbout.mediaType ? this.collegeAbout.mediaType : ''],
      // mediaIdentifier: [this.collegeAbout.mediaIdentifier],
      // imageUrl: [this.collegeAbout.imageUrl],
    } /*, { validator: [mediaIdentifierRequired, imageRequired] }*/
    );
  }

  onFileUpload(files: FileList, formControlName: string) {
    if (files && files.length) {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        this.form.patchValue({ [formControlName]: fileReader.result });
      };

      fileReader.readAsDataURL(files.item(0));
    }
  }

  // updateMediaType() {
  //   if (this.form.value.mediaType === '') {
  //     delete this.form.value.mediaType;
  //   }
  // }

  submitForm() {
    this.saving = true;

    if (typeof this.form.value.customProgramMapTitle === 'string' && this.form.value.customProgramMapTitle.trim() === '') {
      this.form.patchValue({customProgramMapTitle: null});
    }

    if (this.createCollegeAbout) {
      this.aboutService.createCollegeAbout(this.form.value).subscribe(
        (collegeAbout: CollegeAbout) => {
          this.collegeAbout = collegeAbout;
          this.messageService.add('About the college information updated.',
            'icon-notify');
          this.saving = false;
        },
        (error: HttpErrorResponse) => {
          this.messageService.add('About the college information failed to update.',
            'icon-alert', 'authalert-error');
          this.saving = false;
        });
    } else {
      this.aboutService.updateCollegeAbout(this.form.value).subscribe(
        (collegeAbout: CollegeAbout) => {
          this.collegeAbout = collegeAbout;
          this.messageService.add('About the college information updated.',
            'icon-notify');
          this.saving = false;
        },
        (error: HttpErrorResponse) => {
          this.messageService.add('About the college information failed to update.',
            'icon-alert', 'authalert-error');
          this.saving = false;
        });
    }
  }
}

// export const mediaIdentifierRequired: ValidatorFn =
//   (control: FormGroup): ValidationErrors | null =>
//     (control.get('mediaType').value === MediaType.YOUTUBE &&
//       !control.get('mediaIdentifier').value) ?
//       { mediaIdentifierRequired: true } : null;
//
// export const imageRequired: ValidatorFn =
//   (control: FormGroup): ValidationErrors | null =>
//     (control.get('mediaType').value === MediaType.IMAGE &&
//       !control.get('imageUrl').value) ?
//       { imageRequired: true } : null;
