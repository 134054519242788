import { OnDestroy, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import {
  PublishedProgramSummary, ProgramGroupForAuthoring,
  ProgramReference
} from '../../shared/programmapper-authoring.model.d';

import { ProgramGroupIcon } from './missing-types.type';

import { ProgramSelectService } from './common/program-select/program-select.service';
import { IconSelectService } from './common/icon-select/icon-select.service';

export class ProgramGroupMixin implements OnDestroy {
  form: FormGroup;
  programReferences: ProgramReference[] = [];
  programGroupIcon: ProgramGroupIcon;
  iconSelectSubscription$: Subscription;
  programSelectSubscription$: Subscription;
  messageDropdownState = 'inactive';
  saving = false;
  disableRemoveTimeout = false; // Prevent double click remove.

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected programSelectService: ProgramSelectService,
    protected iconSelectService: IconSelectService,
    protected renderer: Renderer2,
  ) {}

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'l-fixedfooterpage');
    this.renderer.addClass(document.body, 'l-staticfooterpage');

    if (this.iconSelectSubscription$ && ! this.iconSelectSubscription$.closed) {
      this.iconSelectSubscription$.unsubscribe();
    }
    if (this.programSelectSubscription$ && ! this.programSelectSubscription$.closed) {
      this.programSelectSubscription$.unsubscribe();
    }
  }

  listenForIconSelection() {
    this.iconSelectSubscription$ = this.iconSelectService.iconSelected$.subscribe(
      (icon: ProgramGroupIcon) => {
        if (this.form && icon) {
          this.programGroupIcon = icon;
          this.form.controls.iconId.setValue(icon.id);
        }
    });
  }

  listenForProgramAdditions() {
    this.programSelectSubscription$ = this.programSelectService.programSelected$
      .subscribe((program: PublishedProgramSummary) => {
        this.programReferences.push({
          masterRecordId: program.masterRecordId,
          title: program.title,
          award: program.award.shortTitle
        });
      });
  }

  removeProgram(program: PublishedProgramSummary) {
    this.disableRemoveTimeout = true;

    setTimeout(() => {
      this.disableRemoveTimeout = false;
    }, 1000)

    this.programReferences = this.programReferences.filter(
      (p: ProgramReference) => p.masterRecordId !== program.masterRecordId);

    this.programSelectService.removeProgram(program);
  }

  openIconSelect(programGroup: ProgramGroupForAuthoring | void) {
    if (programGroup) {
      this.router.navigate([{ outlets: { popup: ['icon-select', programGroup.id] } }],
                           { relativeTo: this.route, skipLocationChange: true });
    } else {
      this.router.navigate([{ outlets: { popup: ['icon-select'] } }],
                           { relativeTo: this.route, skipLocationChange: true });
    }
  }

  openAddProgram(programGroup: ProgramGroupForAuthoring | void) {
    if (programGroup) {
      this.router.navigate([{ outlets: { popup: ['program-select', programGroup.id] } }],
                           { relativeTo: this.route, skipLocationChange: true });
    } else {
      this.router.navigate([{ outlets: { popup: ['program-select'] } }],
                           { relativeTo: this.route, skipLocationChange: true });
    }
  }

  toggleMessageDropdown() {
    this.messageDropdownState = (this.messageDropdownState === 'inactive' ?
                                 'active' : 'inactive');
  }
}
