import { Component, OnInit, Renderer2 } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { GeneralService } from '../../../college/general/general.service';

import { MessageService } from '../../../../shared/message/message.service';
import {
  ErrorSeverity,
  MapPreview,
  MapPreviewCreateRequest,
  MapStatus, ProgramMap,
  ProgramMapCreateRequest, ProgramWithMap, PublishedProgramSummary,
} from '../../../../shared/programmapper-authoring.model';

import { BuilderMixin } from '../../common/builder.mixin';
import { CustomCourseChoiceService } from '../../common/custom-course-choice.service';
import { MapControlsService } from '../../common/map-controls/map-controls.service';
import { ConfirmNavigationService } from '../../common/confirm-navigation/confirm-navigation.service';

import { MapsService } from '../../maps.service';
import {CourseSelectService} from "../../common/curriculum-item-select/course-select.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ConfirmRemoveTermService} from "../../common/confirm-remove-term/confirm-remove-term.service";
import {LinkedMapsService} from "../../common/linked-maps/linked-maps.service";
import {SelectedYearAndVersionService} from "../../../selected-year-and-version.service";
import {MilestoneService} from "../../common/milestone.service";
import {MapListService} from "../../map-list/map-list.service";
import {PrebuiltCourseListSelectService} from "../../common/curriculum-item-select/prebuilt-courselist-select.service";
import { CourseMapCardChoiceService } from '../../common/course-map-card-choice.service';


@Component({
  selector: 'app-builder',
  templateUrl: '../../common/builder.component.html',
  styleUrls: ['./builder.component.css'],
  animations: [
    trigger('slidePanel', [
      state('inactive', style({
        height: '0'
      })),
      state('active', style({
        height: '*'
      })),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out'))
    ]),
  ]
})
export class BuilderComponent extends BuilderMixin implements OnInit {
  isCreateMapComponent = true;
  templateTitle = 'Add Map';
  templateCanDelete = false;
  private programWithMap: ProgramWithMap;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected fb: FormBuilder,
    protected generalService: GeneralService,
    protected mapsService: MapsService,
    protected customCourseListService: CustomCourseChoiceService,
    protected milestoneService: MilestoneService,
    protected mapControlsService: MapControlsService,
    protected confirmNavigationService: ConfirmNavigationService,
    protected renderer: Renderer2,
    protected messageService: MessageService,
    protected courseSelectService: CourseSelectService,
    protected prebuiltCourseListSelectService: PrebuiltCourseListSelectService,
    protected confirmRemoveTermService: ConfirmRemoveTermService,
    protected linkedMapsService: LinkedMapsService,
    protected selectedYearAndVersionService: SelectedYearAndVersionService,
    protected mapListService: MapListService,
    protected courseMapCardChoiceService: CourseMapCardChoiceService,
  ) {
    super(route, router, fb, generalService, mapsService,
          customCourseListService,
          milestoneService,
          courseSelectService, prebuiltCourseListSelectService, mapControlsService,
          confirmNavigationService, renderer,
          messageService, confirmRemoveTermService, linkedMapsService, selectedYearAndVersionService, courseMapCardChoiceService);
    this.renderer.removeClass(document.body, 'l-staticfooterpage');
    this.renderer.addClass(document.body, 'l-fixedfooterpage');
  }

  ngOnInit() {

    this.route.parent.parent.parent.data.subscribe(
      (data: { mapsData: [PublishedProgramSummary, ProgramWithMap, string[]] }) => {

        [this.program, this.programWithMap, this.pathwaysTitles] = data.mapsData;

        super.ngOnInit();
      });
  }

  initializeMapTable(initialTermsPerYear: number, initialYears: number){

    if(this.mapsService.hasUnsavedMapState()) {
      const [unsavedMapFormData, unsavedMapDataIssues] = this.mapsService.unsavedMapState;
      if (unsavedMapFormData.programMasterRecordId === this.program.masterRecordId) {
        this.initializeFromUnsavedState(unsavedMapFormData, unsavedMapDataIssues);
      }
      else {
        this.mapsService.clearUnsavedMapState(); // it was for a different map
        this.initializeEmptyMap(initialTermsPerYear, initialYears);
      }
    }
    else{
      this.initializeEmptyMap(initialTermsPerYear, initialYears);
    }
  }

  private initializeEmptyMap(initialTermsPerYear: number, initialYears: number) {
    this.table = this.initializeTermTables(initialTermsPerYear, initialYears);
    super.setErrors([]);
  }

  initializeTermTables(initialTermsPerYear: number, initialYears: number) {

    const years = [];

    for (let year = 1; year <= initialYears; year++) {
      const terms = this.createYearWithEmptyTerms(initialTermsPerYear, year - 1);
      years.push(terms);
    }

    return years;
  }

  initializeForm() {

    this.form = this.fb.group({
      programMasterRecordId: [this.program.masterRecordId, Validators.required],
      transferTo: [null, Validators.required],
      mapStatus: [MapStatus.PRIVATE],
      status: [false, Validators.required],
      isDefaultMap: [false, Validators.required],
      emphasis: [null],
      // isIncludesDevelopmental: [false, Validators.required],
      // isIncludingBridgeTerm: [false, Validators.required],
      // isDisplayCustomCTA: [false, Validators.required],
    });

    if(this.mapsService.hasUnsavedMapState()) {
      const [unsavedMapFormData, ..._] = this.mapsService.unsavedMapState;

      if (unsavedMapFormData.programMasterRecordId === this.program.masterRecordId) {
        super.overrideFormFromUnsavedMapState(unsavedMapFormData, true, null, null);
      }
    }
    this.form.controls.status.valueChanges.subscribe(
      (status: boolean) => {
        this.form.controls.mapStatus.setValue(
          (status) ? MapStatus.PUBLIC : MapStatus.PRIVATE);
      });
  }

  createMapPreview() {
    const formData: MapPreviewCreateRequest = Object.assign({}, this.form.value);
    formData.table = this.convertTableToMapYearTable(this.table);

    if(this.form.controls.id){
      formData.originalMapId = this.form.controls.id.value;
    }

    if(this.mapListService.pendingMapLink){
      formData.linkedMapIds = undefined;
      formData.pendingMapLinkId = this.mapListService.pendingMapLink.id;
    }

    this.mapsService.postMapPreview(formData).subscribe(
      (mapPreview: MapPreview) => {

        if(mapPreview.errors){
          this.setErrors(mapPreview.errors);
          this.setOurUnsavedMapState();
        }

        if(mapPreview.errors &&
          mapPreview.errors.filter(error => error.severity == ErrorSeverity.CRITICAL)
            .length > 0
        ){
          return;
        }
        else {
          this.router.navigate(['..', 'preview', mapPreview.id],
            { relativeTo: this.route});
        }
      },
      (error: HttpErrorResponse) => {
        this.messageService.add(
          `The server is unable to generate a preview for this map.`,
          'icon-alert', 'authalert-error');
      });
  }

  private setOurUnsavedMapState() {
    const formData: MapPreviewCreateRequest = Object.assign({}, this.form.value);
    formData.table = this.convertTableToMapYearTable(this.table);
    this.mapsService.setUnsavedMapState(formData, this.errors);
    this.componentHasChanges = true;
  }

  protected submitForm() {
    this.saving = true;

    if (this.form.value.emphasis === '') {
      this.form.patchValue({emphasis: null});
    }

    const formData: ProgramMapCreateRequest = Object.assign({}, this.form.value);

    formData.table = super.convertTableToMapYearTable(this.table);

    this.mapsService.createMap(formData).subscribe(
      (response: ProgramMap) => {
        this.messageService.add(`Map successfully created.`, 'icon-notify');
        if(this.mapListService.pendingMapLink){
          this.linkedMapsService.completeMapLinkWithoutLinkedProgramId(response.id, this.mapListService.pendingMapLink.id).subscribe(bool => {
            this.mapListService.pendingMapLink = undefined;
            if(bool){
              this.messageService.add(`Map successfully linked.`, 'icon-notify');
            }
            else{
              this.messageService.add(`An Error occured linking your Map`, 'icon-alert', 'authalert-error');
            }
          });
        }
        this.confirmNavigationService.allowLeave$.next(true);
        this.mapsService.clearUnsavedMapState();
        this.router.navigate(['..'],
            {relativeTo: this.route.parent,});
      },
      (error: HttpErrorResponse) => {
        this.messageService.add(`Map creation failed.`, 'icon-alert', 'authalert-error');
        this.saving = false;
      }
    );
  }
}
