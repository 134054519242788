import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {CustomCourseChoiceMapCard} from "../../../shared/programmapper-authoring.model";

@Injectable({
  providedIn: 'root'
})
export class CustomCourseChoiceService {

  private _customCourseListMapCardForEdit: CustomCourseChoiceMapCard;

  private _customCourseListMapCardAddedSource : Subject<CustomCourseChoiceMapCard> = new Subject();
  customCourseListMapCardAdded$ = this._customCourseListMapCardAddedSource.asObservable();

  private _customCourseListMapCardUpdatedSource : Subject<CustomCourseChoiceMapCard> = new Subject();
  customCourseListMapCardUpdated$ = this._customCourseListMapCardUpdatedSource.asObservable();

  public selectCustomCourseListMapCardForEdit(customCourseChoiceMapCard: CustomCourseChoiceMapCard){
    this._customCourseListMapCardForEdit = customCourseChoiceMapCard;
  }

  getSelectedCustomCourseListMapCard(): CustomCourseChoiceMapCard {
    return this._customCourseListMapCardForEdit;
  }

  clearSelectedCustomCourseListMapCard() {
    this._customCourseListMapCardForEdit = null;
  }

  addCustomCourseList(customCourseListMapCard: CustomCourseChoiceMapCard) {
    if(isNaN(customCourseListMapCard.minUnits) || isNaN(customCourseListMapCard.maxUnits)){
      throw new Error("min and max units must be numbers");
    }
    this._customCourseListMapCardAddedSource.next(customCourseListMapCard);
  }

  updateCustomCourseListMapCard(customCourseListMapCard:CustomCourseChoiceMapCard) {
    if(isNaN(customCourseListMapCard.minUnits) || isNaN(customCourseListMapCard.maxUnits)){
      throw new Error("min and max units must be numbers");
    }
    this._customCourseListMapCardUpdatedSource.next(customCourseListMapCard);
  }
}
