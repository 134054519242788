import { Component, OnInit } from '@angular/core';
import {
  MapLink,
  PendingMapLink,
  ProgramMap,
  PublishedProgramSummary
} from "../../../../../shared/programmapper-authoring.model";
import {ActivatedRoute, Router} from "@angular/router";
import {LinkedMapsService} from "../linked-maps.service";
import {MessageService} from "../../../../../shared/message/message.service";

@Component({
  selector: 'app-pending-linked-maps-unlink-confirm-modal',
  templateUrl: './pending-linked-maps-unlink-confirm-modal.component.html'
})
export class PendingLinkedMapsUnlinkConfirmModalComponent implements OnInit {

  program: PublishedProgramSummary;
  pendingMapToUnlink: PendingMapLink;
  sourceMap: ProgramMap;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private linkedMapsService: LinkedMapsService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.program = this.linkedMapsService.program;
    this.pendingMapToUnlink = this.linkedMapsService.pendingMapToUnlink;
    this.sourceMap = this.linkedMapsService.sourceMap;
  }

  cancel() {
    this.closeModal();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { subPopup: ['linked-maps'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );
  }

  unlinkPending() {
    // delete the mapToUnlink.
    // TODO: error handle response here
//     this.linkedMapsService.deletePendingMapLink(this.linkedMapsService.sourceMap.id,
//       pendingLinkedProgramId).subscribe(
//       (response: boolean) => {
// //        this.messageService.add(`Link successfully removed.`, 'icon-notify');
//         this.closeModal();
//       },
//     );

    this.router.navigate([{ outlets: { popup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { popup: ['linked-maps-unlink-pending-confirm'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );



  }
}
