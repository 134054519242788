import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { DataIssue } from '../../shared/programmapper-authoring.model';

import { ActivityLog } from './missing-types.type';
// import { _ } from '../shared/programmapper-api.model.d';
import { dataIssues, activityLogs } from './publish.mock';
import { PublishService } from './publish.service';

@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.css']
})
export class PublishComponent implements OnInit {
  activityLogs: Observable<ActivityLog[]>;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private publishService: PublishService,
  ) { }

  ngOnInit() {
    this.activityLogs = this.publishService.getActivityLogs();
  }

}
