import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LocalStorageService } from 'ngx-webstorage';

import { CoreModule } from './core.module';
import { ConfigService } from './config.service';
import { MessageService } from '../message/message.service';
import {SelectedCollegeService} from "../../authenticated/selected-college.service";
import {CollegeGeneralInfo} from "../programmapper-authoring.model";

@Injectable({
  providedIn: CoreModule,
})
export class ApiService {
    _token: any;

    get token(): any {
        this._token = this._token || this.localStorageService.retrieve('token');
        return this._token;
    }

    constructor(private http: HttpClient,
                private selectedCollegeService: SelectedCollegeService,
                private configService: ConfigService,
                private messageService: MessageService,
                private localStorageService: LocalStorageService) {}

    collegePath(apiPath: string): string {
      if(!this.selectedCollegeService.getSelectedCollegeId()){
        throw new Error("college must be selected first");
        return;
      }
      const apiHost = this.configService.getConfig('apiHost');
      return `${apiHost}/colleges/${this.selectedCollegeService.getSelectedCollegeId()}/${apiPath}`;
    }

    delete<T>(apiPath: string): Observable<T> {
      const apiHost = this.configService.getConfig('apiHost');

      return this.http.delete<T>(`${apiHost}/${apiPath}`);
    }

    deleteCollege<T>(apiPath: string): Observable<T> {
      return this.http.delete<T>(this.collegePath(apiPath));
    }

    get<T>(apiPath: string): Observable<T> {
      const apiHost = this.configService.getConfig('apiHost');

      return this.http.get<T>(`${apiHost}/${apiPath}`)
        .pipe(
          catchError((err: any, caught: Observable<T>) => {
            return throwError(err);
          }), );
    }

    getCollege<T>(apiPath: string): Observable<T> {
      return this.http.get<T>(this.collegePath(apiPath));
    }


    putCollege<T>(payload: T, apiPath: string): Observable<T>  {
      const apiHost = this.configService.getConfig('apiHost');
      return this.http.put<T>(this.collegePath(apiPath), payload);
    }

    post<T, B>(payload: B, apiPath: string): Observable<T> {
      const apiHost = this.configService.getConfig('apiHost');
      return this.http.post<T>(`${apiHost}/${apiPath}`, payload, );
    }

    postCollege<T, B>(payload: B, apiPath: string): Observable<T> {
      const apiHost = this.configService.getConfig('apiHost');
      return this.http.post<T>(this.collegePath(apiPath), payload);

    }

    postEmpty<T>(apiPath: string): Observable<T> {
      const apiHost = this.configService.getConfig('apiHost');
      return this.http.post<T>(`${apiHost}/${apiPath}`, null );
    }

    postAuth<T, B>(payload: B, apiPath: string): Observable<T>{
      const apiHost = this.configService.getConfig('apiHostAuth');
      return this.http.post<T>(`${apiHost}/${apiPath}`, payload,);
    }

    put<T>(payload: T, apiPath: string): Observable<T> {
      const apiHost = this.configService.getConfig('apiHost');

      return this.http.put<T>(`${apiHost}/${apiPath}`, payload);
    }

}

type SiteContentKey = 'collegeAboutId' | 'collegeAcademicsId' | 'collegeForAuthoringId' | 'collegeGeneralId';
