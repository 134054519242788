import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { MessageModule } from '../../shared/message/message.module';

import { PublishRoutingModule } from './publish-routing.module';
import { PublishComponent } from './publish.component';
import { ReviewComponent } from './review/review.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { MapExportComponent } from './map-export/map-export.component';

@NgModule({
  imports: [
    CommonModule,
    MessageModule,
    SharedModule,
    PublishRoutingModule
  ],
  declarations: [PublishComponent, ReviewComponent, ActivityLogComponent, MapExportComponent]
})
export class PublishModule { }
