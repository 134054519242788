import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BuilderComponent } from './builder/builder.component';
import { PreviewComponent } from './preview/preview.component';
import { MapUpdateComponent } from './map-update.component';

import { MessageModule } from '../../../shared/message/message.module';

import { CommonModule } from '../common/common.module';
import {SharedModule} from '../../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MessageModule,
    SharedModule
  ],
  exports: [
    PreviewComponent
  ],
  declarations: [BuilderComponent, PreviewComponent, MapUpdateComponent]
})
export class MapUpdateModule { }
