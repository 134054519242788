import { Injectable } from '@angular/core';
import {ApiService} from "../shared/core/api.service";
import {BehaviorSubject, forkJoin, Observable, of} from "rxjs";
import {MediaType, PublishedCurriculumSummary} from "../shared/programmapper-authoring.model";
import {LocalStorageService} from "ngx-webstorage";
import {CurrentUser} from "./account/missing-types.type";
import {CurriculumVersionInfo, versionInfo} from "./selected-year-and-version.mock";

@Injectable({
  providedIn: 'root'
})
export class SelectedYearAndVersionService {

  hasNewerVersion = new BehaviorSubject<boolean>(null);
  hasNewerVersion$ = this.hasNewerVersion.asObservable();

  // currentYear: BehaviorSubject<number> = new BehaviorSubject(null);
  // currentYear$ = this.currentYear.asObservable();

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService) {}

  getCollegeCurriculumVersions(): Observable<PublishedCurriculumSummary[]> {
    return this.apiService.getCollege<PublishedCurriculumSummary[]>('curriculums');
  }

  // setCurrentYear(year: number) {
  //   this.localStorageService.store('currentYear', year);
  //   this.currentYear.next(year);
  // }
  //
  // getCurrentYear() {
  //   const currentYear = this.localStorageService.retrieve('currentYear');
  //
  //   if (currentYear) {
  //     this.currentYear.next(currentYear);
  //   }
  //
  //   return this.currentYear$;
  // }

  setSelectedCurriculumVersion(version: PublishedCurriculumSummary) {
    // TODO: save selected curriculum version to server.
  }

  getSelectedCurriculumVersion(): Observable<CurriculumVersionInfo> {
    // TODO: retrieve selected curriculum version from server.
    return of(versionInfo);
  }

}




