import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmNavigationService {
  allowLeave$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}
}
