import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';

import { MapsService } from './maps.service';
import {ProgramWithMap, PublishedProgramSummary} from "../../shared/programmapper-authoring.model";

@Injectable({
  providedIn: 'root'
})
export class MapsResolveGuard implements Resolve<[PublishedProgramSummary, ProgramWithMap, string[]]> {
  constructor(
    private router: Router,
    private mapsService: MapsService,
  ) {}

  resolve(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<[PublishedProgramSummary, ProgramWithMap, string[]]> {

      const programMasterRecordId = next.paramMap.get('programId'),
        getProgram$ = this.mapsService.getPublishedProgram(programMasterRecordId),
        getProgramWithMap$ = this.mapsService.getProgramWithMap(programMasterRecordId),
        getColleges$ = this.mapsService.getProgramTransferToColleges(programMasterRecordId);

      return combineLatest(getProgram$, getProgramWithMap$, getColleges$);

  }
}
