import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {
  LinkedMapNotice,
} from '../../shared/programmapper-authoring.model';
import {ApiService} from '../../shared/core/api.service';
import {share} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private notificationCountSubject = new Subject;
  notificationCount$ = this.notificationCountSubject.asObservable();

  constructor(private apiService: ApiService) { }

  forceNoticeRetrieval(){
    this.getLinkedMapNoticeCount().subscribe(count =>{
      this.notificationCountSubject.next(count);
    })
  }

  // This is called once from authenticated.component.
  getLinkedMapIssues(): Observable<LinkedMapNotice[]> {
    //return of([]);
    return this.apiService.getCollege<LinkedMapNotice[]>(`linked-map-issues`)
      .pipe(share());
  }

  // updateNotifications(notifications: AuthoringNotification[]): Observable<AuthoringNotification[]> {
  //   return this.apiService.putCollege<AuthoringNotification[]>(notifications, 'notificaitons');
  // }

  getLinkedMapNoticeCount() : Observable<number>{
    return this.apiService.getCollege<number>(`linked-map-notices-count`)
      .pipe(share());
  }
}
