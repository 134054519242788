import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationsComponent } from './notifications.component';
import { IssuesComponent } from './issues/issues.component';
import { NotificationsPanelComponent } from './notifications-panel/notifications-panel.component';
import {SharedModule} from "../../shared/shared.module";

@NgModule({
  declarations: [NotificationsComponent, IssuesComponent, NotificationsPanelComponent],
    imports: [
        CommonModule,
        NotificationsRoutingModule,
        SharedModule
    ]
})
export class NotificationsModule { }
