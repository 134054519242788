import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TableModule } from '../../../../shared/table/table.module';
import {CourseSelectComponent} from "./course-select/course-select.component";
import {CourseListSelectComponent} from "./course-list-select/course-list-select.component";
import {SharedModule} from '../../../../shared/shared.module';
import { ConfirmCourseUnitsComponent } from './confirm-course-units/confirm-course-units.component';
import {
  ConfirmPrebuiltCourseListUnitsComponent
} from "./confirm-prebuilt-courselist-units/confirm-prebuilt-courselist-units.component";
import {CoursesForListSelectComponent} from "./courses-for-list-select/courses-for-list-select.component";
import { ConfirmCourseUnitsForListComponent } from './confirm-course-units-for-list/confirm-course-units-for-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TableModule,
    SharedModule,
  ],
  declarations: [CourseSelectComponent, CoursesForListSelectComponent, CourseListSelectComponent, ConfirmCourseUnitsComponent, ConfirmCourseUnitsForListComponent,ConfirmPrebuiltCourseListUnitsComponent, ConfirmCourseUnitsForListComponent]
})
export class CurriculumItemSelectModule { }
