import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageService } from '../../../../shared/message/message.service';

import { UsersService } from '../users.service';
import {CollegeUser} from "../../../../shared/programmapper-authoring.model";
import {SelectedCollegeService} from "../../../selected-college.service";
import {filter, tap} from "rxjs/internal/operators";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
  form: FormGroup;
  saving = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private usersService: UsersService,
    private fb: FormBuilder,
    private selectedCollegeService: SelectedCollegeService) { }


  ngOnInit() {
    const collegeId = this.selectedCollegeService.selectedCollegeId;
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      collegeId: [collegeId, Validators.required],
    });

  }

  submitForm() {
      this.saving = true;
      let emailTaken = false;
      this.usersService.getCollegeUsers()
        .pipe (
          map(users =>{
            return users.filter(user => {
              return user.email == this.form.controls.email.value;
            })
          })
        ).subscribe(users =>{

        if(users.length > 0){
          this.messageService.add(`An account already exists for ${this.form.controls.email.value} Use a different email, or ask the user to reset their password to regain access to authoring.`,
            'icon-notify', 'authalert-error');
          this.saving = false;
        }
        else{

          this.usersService.createCollegeUser(this.form.value).subscribe(
            (collegeUser: CollegeUser) => {
              this.messageService.add(`Invitation sent to ${collegeUser.email}`,
                'icon-notify');
              this.closeModal();
            },
            (error: HttpErrorResponse) => {
              this.messageService.add(`Invitation failed to send to ${this.form.controls.email.value}`,
                'icon-alert', 'authalert-error');
              this.closeModal();
            });
        }

      });
    }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }

}
