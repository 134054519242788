import { Directive, OnInit, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[table-cell]'
})
export class TableCellDirective {
  @Input('table-cell') name: string;

  constructor(private templateRef: TemplateRef<any>) { }

  getTemplateRef() {
    return this.templateRef;
  }
}
