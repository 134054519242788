import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {CollegeSummary, ProgramWithMap, PublishedProgramSummary} from '../../../shared/programmapper-authoring.model';

@Component({
  selector: 'app-map-create',
  templateUrl: './map-create.component.html',
  styleUrls: ['./map-create.component.css']
})
export class MapCreateComponent implements OnInit {
  program: PublishedProgramSummary;
  programWithMap: ProgramWithMap;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.parent.parent.data.subscribe((data: { mapsData: [PublishedProgramSummary, ProgramWithMap, CollegeSummary[]] }) => {
      const [program, programWithMap, ..._] = data.mapsData;
      this.program = program;
      this.programWithMap = programWithMap;
    });
  }

}
