import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';

import { CommonModule as MapsCommonModule } from './common/common.module';
import { MapsRoutingModule } from './maps-routing.module';
import { MapListComponent } from './map-list/map-list.component';
import { MapDetailComponent } from './map-detail/map-detail.component';
import { MapCreateModule } from './map-create/map-create.module';
import { MapUpdateModule } from './map-update/map-update.module';
import { ProgramListComponent } from './program-list/program-list.component';
import { ProgramDetailComponent } from './program-detail/program-detail.component';
import {FixOrphanedMapsComponent} from "./program-list/fix-orphaned-maps/fix-orphaned-maps.component";
import { AdvisingLinkModalComponent } from './map-list/advising-link-modal/advising-link-modal.component';
import { FixMissingMapModalComponent } from './map-list/fix-missing-map-modal/fix-missing-map-modal.component';
import { LinkExistingMapModalComponent } from './map-list/link-existing-map-modal/link-existing-map-modal.component';
import { CopyMapModalComponent } from './map-list/copy-map-modal/copy-map-modal.component';
import { AdditionalInfoLinkModalComponent } from './map-list/additional-info-link-modal/additional-info-link-modal.component';
import { CourseNotesUpdateModalComponent } from './common/course-notes-update-modal/course-notes-update-modal.component';

@NgModule({
  imports: [
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    MapsCommonModule,
    MapsRoutingModule,
    MapCreateModule,
    MapUpdateModule,
  ],
  declarations: [MapListComponent, MapDetailComponent, ProgramListComponent, ProgramDetailComponent, FixOrphanedMapsComponent, AdvisingLinkModalComponent, FixMissingMapModalComponent, LinkExistingMapModalComponent, CopyMapModalComponent, AdditionalInfoLinkModalComponent, CourseNotesUpdateModalComponent]
})
export class MapsModule { }
