import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { MessageService } from '../../../../shared/message/message.service';

import { ConfirmNavigationService } from '../../common/confirm-navigation/confirm-navigation.service';
import { PreviewMixin } from '../../common/preview.mixin';
import { MapsService } from '../../maps.service';
import {AuthenticationHiddenService} from "../../../authentication-hidden.service";

@Component({
  selector: 'app-preview',
  templateUrl: '../../common/preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent extends PreviewMixin implements OnInit {
  templateTitle = 'Add Map';

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected mapsService: MapsService,
    protected sanitizer: DomSanitizer,
    protected messageService: MessageService,
    protected authenticationHiddenService: AuthenticationHiddenService,
    protected confirmNavigationService: ConfirmNavigationService,
  ) { super(route, router, mapsService, authenticationHiddenService, sanitizer, messageService, confirmNavigationService); }

  ngOnInit (){
    super.ngOnInit();
  }
}
