import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnChanges {
  @Input() onPage = 1;
  @Input() perPage: number;
  @Input() itemCount: number;

  @Input() background = true;

  @Output('selectPage') selectPage$: EventEmitter<number> = new EventEmitter();

  totalPages: number;
  pageIterator: any[];
  onCenterPage = false;
  noEllipsis = false;

  edgeShowStartPages = 4;
  edgeShowEndPages = 4;

  centerShowStartPages = 1;
  centerShowEndPages = 1;
  centerShowPagesBefore = 4;
  centerShowPagesAfter = 4;

  constructor() { }

  ngOnChanges() {
    if (this.itemCount && this.perPage) {
      this.totalPages = Math.ceil(this.itemCount / this.perPage);
      this.pageIterator = new Array(this.totalPages)
        .fill(undefined).map((_, index) => index);
      this.noEllipsis = this.totalPages < 12;
      this.onCenterPage =
        (this.onPage > this.edgeShowStartPages &&
         this.onPage < this.totalPages - (this.edgeShowEndPages - 1));
    }
  }

  selectPage(pageNumber: number): void {
    if (pageNumber >= 1 &&
        pageNumber <= this.totalPages &&
        pageNumber !== this.onPage) {
      this.selectPage$.emit(pageNumber);
    }
  }

  selectPageBetween(pageBeforeEllipsis: number, pageAfterEllipsis: number) {
    this.selectPage(pageBeforeEllipsis + Math.floor((pageAfterEllipsis - pageBeforeEllipsis) / 2));
  }
}
