import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { LocalStorageService } from 'ngx-webstorage';

import { MessageService } from '../../../shared/message/message.service';

import { SignUpService } from '../sign-up/sign-up.service';
import {
  CollegeUser,
  CreateUserFromInvitationResponse
} from '../../../shared/programmapper-authoring.model';
import {AccountService} from '../../../authenticated/account/account.service';
import {AuthenticationService} from '../../../authentication.service';


@Component({
  selector: 'app-sign-up-from-invitation',
  templateUrl: './sign-up-from-invitation.component.html',
  styleUrls: ['./sign-up-from-invitation.component.css']
})
export class SignUpFromInvitationComponent implements OnInit, OnDestroy {
  form: FormGroup;
  invitationToken: string;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private signUpService: SignUpService,
    private messageService: MessageService,
    private localStorageService: LocalStorageService,
    private accountService: AccountService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, 'l-staticfooterpage-white');

    // TODO
    // this is a workaround, the problem is ngOnInit is getting called after the form is submitted,
    // which tries to getInvitedUserByToken and fails which throws 401.
    if (!this.authenticationService.isLoggedIn) {
      this.getInvitedUser();
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'l-staticfooterpage-white');
  }

  getInvitedUser() {
    this.invitationToken = this.route.snapshot.queryParams['invitationToken'];

    const formData = this.signUpService.getInvitedUserByToken(this.invitationToken);

    formData.subscribe(
      (invitedUser: CollegeUser) => {
        this.initializeForm(invitedUser);
      });
  }

  initializeForm(invitedUser: CollegeUser) {
    this.form = this.fb.group({
      'email' : [invitedUser.email],
      'firstName' :[invitedUser.firstName, Validators.required],
      'lastName':[invitedUser.lastName, Validators.required],
      'password': ['', [Validators.required, Validators.minLength(8)]],
      'confirmPassword': ['', Validators.required],
    }, { validator: passwordsMatchValidator });
  }

  submitForm() {
    this.signUpService.createUserFromInvitation({
      firstName: this.form.controls.firstName.value,
      lastName: this.form.controls.lastName.value,
      password: this.form.controls.password.value,
      invitationToken: this.invitationToken,
    }).subscribe((response: CreateUserFromInvitationResponse) => {
      const token = response.authorizationToken;
      if (token) {
        this.localStorageService.store('token', token);
        this.accountService.setCurrentUser(
          {
            firstName: this.form.controls.firstName.value,
            lastName: this.form.controls.lastName.value,
            email: this.form.controls.email.value
          });
        this.router.navigate(['/']);
      } else {
        this.messageService.add('Sign-up failed.', 'icon-alert',
                                'authalert-error');
      }
    },
      (error: HttpErrorResponse) => {
        this.messageService.add('Sign-up failed.', 'icon-alert',
                                'authalert-error');
      });
  }
}

export const passwordsMatchValidator: ValidatorFn =
  (control: FormGroup): ValidationErrors | null =>
    (control.get('password').value !== control.get('confirmPassword').value) ?
      { 'passwordsDoNotMatch': true } : null;
