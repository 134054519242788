import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { PagerComponent } from './pager.component';
import { SearchbarComponent } from './searchbar.component';
import { TableCellDirective } from './table-cell.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [TableComponent, PagerComponent, SearchbarComponent, TableCellDirective],
  exports: [TableComponent, TableCellDirective, PagerComponent, SearchbarComponent]
})
export class TableModule { }
