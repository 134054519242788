import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableModule } from './table/table.module';
import { MessageModule } from './message/message.module';
import { CharacterCounterComponent } from './character-counter/character-counter.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';

@NgModule({
  imports: [
    CommonModule,
    MessageModule,
    TableModule,
  ],
  declarations: [CharacterCounterComponent, LoadingIndicatorComponent],
  exports: [MessageModule, CharacterCounterComponent, TableModule, LoadingIndicatorComponent]
})
export class SharedModule { }
