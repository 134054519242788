import {
  Inject,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {AuthenticationService} from "../authentication.service";
import {map, share} from "rxjs/operators";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {AccountService} from "./account/account.service";
import {SelectedCollegeService} from "./selected-college.service";
import {isNullOrUndefined} from "util";
import {CurrentUser} from "./account/missing-types.type";
import {GeneralService} from "./college/general/general.service";
import {
  CollegeGeneralInfo,
  CollegeSummary,
  PublishedCurriculumSummary
} from "../shared/programmapper-authoring.model";
import {CollegeService} from "./college/college.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import cssVars from "css-vars-ponyfill";
import {SelectedYearAndVersionService} from "./selected-year-and-version.service";
import {MessageService} from "../shared/message/message.service";
import {NotificationsService} from './notifications/notifications.service';
import {filter} from "rxjs/internal/operators";
import { AuthenticationHiddenService} from "./authentication-hidden.service";

@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.css'],
  animations: [
    trigger('slidePanel', [
      state('inactive', style({
        height: '0'
      })),
      state('active', style({
        height: '*'
      })),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out'))
    ]),
  ]
})
export class AuthenticatedComponent  implements OnInit, OnDestroy {
  collegeGeneral: CollegeGeneralInfo;
  private _colleges$: Observable<CollegeSummary[]>;
  hasNewSiteContent = false;
  userInitials: string;
  currentUser$: Observable<CurrentUser>;
  versionsSub: Subscription;
  currentYear: number;
  newerVersions: PublishedCurriculumSummary[];
  saving = false;
  hidden = false;

  slideoutState = false;
  dropdownState: any = {
    userMenu: 'inactive',
    loginMenu: 'inactive',
  };
  @ViewChild('main') main: ElementRef;
  selectedCollegeId: string;

  get isLoggedIn() {
    return this.authenticationService.isLoggedIn;
  }

  constructor(
    // TODO: use proper window injection (see student app)
    //@Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    private router: Router,
    private collegeService: CollegeService,
    private generalService: GeneralService,
    private selectedCollegeService: SelectedCollegeService,
    private authenticationService: AuthenticationService,
    private authenticationHiddenService: AuthenticationHiddenService,
    private accountService: AccountService,
    protected route: ActivatedRoute,
    protected selectedYearAndVersionService: SelectedYearAndVersionService,
    private messageService: MessageService,
    private notificationsService: NotificationsService
  ) {

  }

  get colleges$(): Observable<CollegeSummary[]> {
    if (isNullOrUndefined(this._colleges$)) {
      this._colleges$ = this.collegeService.getColleges().pipe(
        map((data) => {
          data.sort((a, b) => {
            return a.name < b.name ? -1 : 1;
          });
          return data;
        }),
        share());
    }
    return this._colleges$;
  }

  ngOnInit() {
    this.listenForCollegeSelection();
    this.listenForCollegeGeneralInfoUpdate();
    this.setUserInitials();
    this.listenForModals();
    this.listenForHiddenUpdate();

    this.router.events
      // ...if event is a navigation end and if target is home page...
      .pipe(filter(event =>
        event instanceof NavigationEnd,
      ))
      // ...load data.
      .subscribe(() => {
        if(this.selectedCollegeId){
          this.notificationsService.forceNoticeRetrieval();
        }
      });

  }

  ngOnDestroy() {
    if (this.versionsSub && !this.versionsSub.closed) {
      this.versionsSub.unsubscribe();
    }
  }

  getCurrentYear() {
    this.currentYear = undefined;

    this.versionsSub = this.selectedYearAndVersionService.getSelectedCurriculumVersion().subscribe(
      (versionInfo) => {

        if (versionInfo && versionInfo.currentSelectedVersion) {
          const currentVersion = versionInfo.currentSelectedVersion;
          this.currentYear = versionInfo.currentSelectedVersion.year;
          const currentVersionNumber: number = currentVersion.revision;
          this.checkIfSelectedVersionIsLatest(this.currentYear, versionInfo.versionsList, currentVersionNumber);
        } else {
          this.selectedYearAndVersionService.hasNewerVersion.next(false);
        }
      }
    );
  }

  private checkIfSelectedVersionIsLatest(currentYear: number, versionsList: PublishedCurriculumSummary[], currentVersionNumber: number) {
    this.newerVersions = versionsList.filter(
      (version) => {
        return version.year === currentYear && version.revision > currentVersionNumber;
      }
    );
    this.selectedYearAndVersionService.hasNewerVersion.next(this.newerVersions.length > 0);
  }

  saveLatestVersionForYear() {
    this.saving = true;

    let newestVersion;
    let revisionNumber = 0;

    this.newerVersions.forEach(
      (version) => {
        if (version.revision > revisionNumber) {
          revisionNumber = version.revision;
          newestVersion = version;
        }
      }
    );

    this.selectedYearAndVersionService.setSelectedCurriculumVersion(newestVersion);
  }

  listenForModals() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.toLowerCase().indexOf('popup') !== -1) {
          this.renderer.addClass(document.body, 'has-modal');
        } else {
          this.renderer.removeClass(document.body, 'has-modal');
        }
      }
    });
  }

  setUserInitials() {
    this.currentUser$ = this.accountService.getCurrentUser();

    this.currentUser$.subscribe(value => {
      if (value !== null) {
        this.userInitials = value.firstName.trim().charAt(0).toUpperCase() +  value.lastName.trim().charAt(0).toUpperCase();
      }
    });
  }

  listenForHiddenUpdate(){
    this.authenticationHiddenService.data$.subscribe(
      isHidden => this.hidden = isHidden
    )
  }

  listenForCollegeGeneralInfoUpdate() {
    this.generalService.collegeGeneralUpdated$.subscribe(
      (collegeGeneral: CollegeGeneralInfo) => {
        this.collegeGeneral = collegeGeneral;
        if (!isNullOrUndefined(this.collegeGeneral) && !isNullOrUndefined(this.collegeGeneral.themeColor)) {
          this.setThemeColor(this.collegeGeneral.themeColor);
          this.getCurrentYear();
        }
      }
    );
  }


  listenForCollegeSelection() {
    this.selectedCollegeService.college$.subscribe(
      (college: CollegeSummary) => {
        if(college){
          // After college is selected get the general info so we can set the logo in header.
          this.generalService.getCollegeGeneralInfo().subscribe(
            (collegeGeneral: CollegeGeneralInfo) => {
              this.collegeGeneral = collegeGeneral;
              if (!isNullOrUndefined(this.collegeGeneral) && !isNullOrUndefined(this.collegeGeneral.themeColor)) {
                this.setThemeColor(this.collegeGeneral.themeColor);
              }
            });
          this.selectedCollegeId = college.id;
          this.getCurrentYear();
        }
        else {
          this.selectedCollegeId = undefined;
        }
      });

  }

  logUserOut() {
    this.authenticationService.logOutAndRedirect();
    this.dropdownState['userMenu'] = 'inactive';
    this.slideoutState = false;
    this.renderer.removeClass(document.documentElement, 'menu-open');
  }

  toggleSlideout() {
    window.scroll(0, 0);
    this.slideoutState = !this.slideoutState;
    // this.slideoutState ? this.renderer.addClass(document.body, 'menu-open') : this.renderer.removeClass(document.body, 'menu-open');
    this.slideoutState ? this.renderer.addClass(document.documentElement, 'menu-open') : this.renderer.removeClass(document.documentElement, 'menu-open');
  }

  toggleDropdown(dropdownStateKey: string) {
    this.dropdownState[dropdownStateKey] = (this.dropdownState[dropdownStateKey] === 'inactive' ? 'active' : 'inactive');
  }

  skipLink() {
    this.main.nativeElement.focus();
  }

  selectCollege(collegeId: string): void {
    if(this.selectedCollegeService.selectedCollegeId && this.selectedCollegeService.selectedCollegeId !== collegeId )
        this.collegeService.getCollege(collegeId).subscribe(
      college => {
        this.selectedCollegeService.selectCollege(college)

        // hard reload the authoring site with the new college selected
        let url = window.location.protocol + "//" + window.location.hostname;
        if (!isNullOrUndefined(window.location.port) && window.location.port.length > 0) {
          url = url + ":" + window.location.port;
        }
        window.location.href = url;
      });
  }

  private setThemeColor(color: string) {
    if (!color) {
      color = '#000000';
    }

    // The css-vars-ponyfill handles setting these for modern and legacy browsers.
    // equivalent to document.documentElement.style.setProperty
    cssVars({
      variables: {
        '--color-thememain': color,
        '--color-theme2': this.hex2rgba_convert(color, '0.4'),
        '--color-theme3': this.hex2rgba_convert(color, '0.04'),
      }
    });
  }

  private hex2rgba_convert(hex, opacity) {
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      let hexToUse = hex.replace('#', '');
      if (hexToUse.length === 3) {
        hexToUse =
          hexToUse.charAt(0) +
          hexToUse.charAt(0) +
          hexToUse.charAt(1) +
          hexToUse.charAt(1) +
          hexToUse.charAt(2) +
          hexToUse.charAt(2);
      }
      const r = parseInt(hexToUse.substring(0, 2), 16);
      const g = parseInt(hexToUse.substring(2, 4), 16);
      const b = parseInt(hexToUse.substring(4, 6), 16);

      return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }
    return 'rgba(0,0,0,1.0)';
  }

  openChangeYearModal() {
    this.router.navigate([{ outlets: { popup: ['choose-year'] } }],
      { relativeTo: this.route, skipLocationChange: true });
  }
}
