import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '../../shared/core/api.service';
import {CollegeSummary} from '../../shared/programmapper-authoring.model.d';

@Injectable({
  providedIn: 'root'
})
export class CollegeService {

  constructor(private apiService: ApiService) {}

  getCollege(collegeId: string): Observable<CollegeSummary> {
    return this.apiService.get<CollegeSummary>(`colleges/${collegeId}`)
    .pipe(
      catchError((err: any, caught: Observable<CollegeSummary>) => {
        return throwError(err);
      }), );
  }

  getColleges(): Observable<CollegeSummary[]> {
    return this.apiService.get<CollegeSummary[]>('colleges');
  }
}
