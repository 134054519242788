import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-character-counter',
  templateUrl: './character-counter.component.html',
  styleUrls: ['./character-counter.component.css']
})
export class CharacterCounterComponent implements OnInit, OnChanges {
  @Input() set value(value: string) {
    this.charCount = value ? value.length : 0;
  }
  @Input() charLimit;

  charCount: number;
  charPercent: number;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.charPercent = this.charCount / this.charLimit;
  }
}
