import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';

import { ProgramMap } from '../../../shared/programmapper-authoring.model.d';

import { MapsService } from '../maps.service';

@Injectable({
  providedIn: 'root'
})
export class MapUpdateResolverGuard implements Resolve<[ProgramMap, string[]]> {
  constructor(
    private router: Router,
    private mapsService: MapsService,
  ) {}

  resolve(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<[ProgramMap, string[]]> {

      const mapUuid = next.paramMap.get('mapId'),
        programUuid = next.parent.parent.paramMap.get('programId'),
        getMap$ = this.mapsService.getMap(mapUuid),
        getColleges$ = this.mapsService.getProgramTransferToColleges(programUuid);

      return combineLatest(getMap$, getColleges$);

  }
}
