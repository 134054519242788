import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { MessageService } from '../../../shared/message/message.service';

import { AccountService } from '../account.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private messageService: MessageService,
    private accountService: AccountService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.renderer.addClass(document.body, 'l-staticfooterpage-white');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'l-staticfooterpage-white');
  }

  initializeForm() {
    this.form = this.fb.group({
      'oldPassword': ['', Validators.required],
      'password': ['', [Validators.required, Validators.minLength(8)]],
      'confirmPassword': ['', Validators.required],
    }, { validator: passwordsMatchValidator });
  }

  submitForm() {
    // this.accountService.updatePassword(this.form.controls.password.value)
    //   .subscribe((sucesss: boolean) => {
    //     this.messageService.add('Your password has been sucessfully changed.', 'icon-notify');
    //   },
    //   (error: HttpErrorResponse) => {
    //     this.messageService.add('Your password failed to change.', 'icon-alert', 'authalert-error');
    //   });
  }
}

export const passwordsMatchValidator: ValidatorFn =
  (control: FormGroup): ValidationErrors | null =>
    (control.get('password').value !== control.get('confirmPassword').value) ?
      { 'passwordsDoNotMatch': true } : null;
