import {Component, OnDestroy, OnInit} from '@angular/core';
import {MapListService} from "../map-list.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  MapPreview,
  PendingMapLink,
  ProgramMapSummary,
  PublishStatus
} from "../../../../shared/programmapper-authoring.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SelectedCollegeService} from "../../../selected-college.service";
import {LinkedMapsService} from "../../common/linked-maps/linked-maps.service";
import {Observable, ReplaySubject, Subject, Subscription, timer} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {MapsService} from "../../maps.service";

@Component({
  selector: 'app-link-existing-map-modal',
  templateUrl: './link-existing-map-modal.component.html',
})
export class LinkExistingMapModalComponent implements OnInit, OnDestroy {

  // pathwayTo: string;

  // linkedProgramId: string;
  // transferMapId: string;

  linkableMaps: ProgramMapSummary[];
  linkableMapsSub: Subscription;
  linkableMapsLoaded = false;
  form: FormGroup;
  currentFormValue: ProgramMapSummary;
  private createdForPreviewingMapId: string = null;
  private createdForPreviewingMapSummary : ProgramMapSummary = null;
  private pendingMapLink: PendingMapLink;
  private unsubscribe$ = new Subject();
  private currentSiteContentPreviewSource = new ReplaySubject<MapPreview>();
  currentSiteContentPreview$ : Observable<MapPreview> = this.currentSiteContentPreviewSource.asObservable();
  private previewPollingSub: Subscription;
  private currentFormValuePollingSub: Subscription;
  saving = false;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private mapListService: MapListService,
              private linkedMapsService: LinkedMapsService,
              private fb: FormBuilder,
              protected mapsService: MapsService,
              public selectedCollegeService: SelectedCollegeService) { }

  ngOnInit() {

    this.pendingMapLink = this.mapListService.pendingMapLink;

    this.form = this.fb.group({selectedMapId: [null, Validators.required],});

    this.linkableMapsSub = this.linkedMapsService.getLinkableMaps(
      this.pendingMapLink.linkedProgramId,
      this.pendingMapLink.map.mapId
    ).subscribe(linkableMaps => {
      this.linkableMaps = linkableMaps;
      this.linkableMapsLoaded = true;
    })

    this.currentFormValue = null;

    //this.filterMapsList();
  }

  ngOnDestroy() {
    this.linkableMapsSub.unsubscribe();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent, skipLocationChange: true });
  }

  cancel() {
    this.closeModal();
  }

  submitForm() {
    this.saving = true;

    this.linkedMapsService.completeMapLink(this.form.value.selectedMapId.mapId,
      this.pendingMapLink.linkedProgramId,
      this.pendingMapLink.map.mapId).subscribe(
      (response: boolean) => {
        //this.messageService.add(`Link successfully created.`, 'icon-notify');
        this.closeModal();
        this.mapListService.updateMapList.next(true);
      });
  }

  filteredLinkableMaps(value : ProgramMapSummary[]){
    if(this.createdForPreviewingMapId == null){
      return value;
    }
    for(let i = 0; i < value.length; i ++){
      if(value[i].mapId == this.createdForPreviewingMapId){
        value.splice(i, 1);
      }
    }
    return value;
  }

  isPreviewPending(siteContentPreview: MapPreview) {
    return siteContentPreview.publishStatus === PublishStatus.PENDING;
  }

  isPreviewCompleted(siteContentPreview: MapPreview) {
    return siteContentPreview.publishStatus === PublishStatus.COMPLETED;
  }

  updateSiteContent(){
    this.resetCurrentSitePreviewContent();
    this.currentFormValue = this.form.value;
    return false;
  }

  resetCurrentSitePreviewContent(){
    this.currentSiteContentPreviewSource = new ReplaySubject<MapPreview>();
    this.currentSiteContentPreview$ = this.currentSiteContentPreviewSource.asObservable();
  }

  //TODO: implement a means of resetting relative values when form value changes
  requestPreview() {
    this.currentFormValue = this.form.value;
    this.linkedMapsService.postLinkedMapPreviewWithPendingLink(this.form.value.selectedMapId.mapId, this.pendingMapLink.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(mapPreview => {
        const tick$: Observable<number> = timer(0, 5000); // first check is at 0 seconds;
        const valueTick$: Observable<number> = timer(0, 1000);
        let exit$ : Observable<boolean> = new Observable<boolean>();
        this.currentFormValuePollingSub = valueTick$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(t => {
            if(this.currentFormValue != this.form.value){
              this.resetCurrentSitePreviewContent();
              this.unsubscribe$.next();
              this.currentFormValuePollingSub.unsubscribe();
              return;
            }
          });

        this.previewPollingSub = tick$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(t => {
            const x = this.mapsService.getMapPreview(mapPreview.id)
              .pipe(takeUntil(this.unsubscribe$));

            x.subscribe(siteContentPreview => {
              if (siteContentPreview.publishStatus !== PublishStatus.PENDING) {
                this.previewPollingSub.unsubscribe();
              }
              this.currentSiteContentPreviewSource.next(siteContentPreview);
            });

            return x;
          });
      });

  }

  // addLink() {
  //   this.mapLinkControls.push(new FormControl(''));
  // }
}
