import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LinkedMapsService} from "../linked-maps.service";
import {
  MapLink,
  ProgramMap,
  PublishedProgramSummary
} from "../../../../../shared/programmapper-authoring.model";
import {MessageService} from "../../../../../shared/message/message.service";

@Component({
  selector: 'app-linked-maps-unlink-confirm-modal',
  templateUrl: './linked-maps-unlink-confirm-modal.component.html',
})
export class LinkedMapsUnlinkConfirmModalComponent implements OnInit {

  program: PublishedProgramSummary;
  mapToUnlink: MapLink;
  sourceMap: ProgramMap;
  saving = false;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private linkedMapsService: LinkedMapsService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.program = this.linkedMapsService.program;
    this.mapToUnlink = this.linkedMapsService.mapToUnlink;
    this.sourceMap = this.linkedMapsService.sourceMap;
  }

  cancel() {
    this.closeModal();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { subPopup: ['linked-maps'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );
  }

  unlink() {
    this.saving = true;
    // delete the mapToUnlink.
    // TODO: error handle response here
    this.linkedMapsService.deleteMapLink(this.linkedMapsService.sourceMap.id, this.mapToUnlink.linkedMap.mapId).subscribe(
      (response: boolean) => {
//        this.messageService.add(`Link successfully removed.`, 'icon-notify');
        this.closeModal();
      },
    );
  }
}
