import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CollegeModule } from './authenticated/college/college.module';
import { AboutModule } from './anonymous/about/about.module';
import { AcademicsModule } from './authenticated/academics/academics.module';
import { PublishModule } from './authenticated/publish/publish.module';
import { AccountModule } from './authenticated/account/account.module';
import { MapsModule } from './authenticated/maps/maps.module';
import { LoginModule } from './anonymous/login/login.module';
import { CoreModule } from './shared/core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MessageModule } from './shared/message/message.module';
import {InfoModule} from './anonymous/info/info.module';
import { ErrorPagesModule } from './anonymous/error-pages/error-pages.module';
import {ApiResponseInterceptor} from './api-response-interceptor';
import {JwtAuthenticationInterceptor} from './jwt-authentication-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CacheInterceptor } from './cacheinterceptor';
import { AuthenticatedComponent } from './authenticated/authenticated.component';
import { AnonymousComponent } from './anonymous/anonymous.component';
import {NoCollegesModule} from "./authenticated/no-colleges/no-colleges.module";
import {AuthenticatedModule} from "./authenticated/authenticated.module";
import {NgxWebstorageModule} from "ngx-webstorage";
import {NotificationsModule} from "./authenticated/notifications/notifications.module";
import {MapUpdateModule} from "./authenticated/maps/map-update/map-update.module";


@NgModule({
  declarations: [
    AppComponent,
    AuthenticatedComponent,
    AnonymousComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot(),
    AppRoutingModule,
    CollegeModule,
    AboutModule,
    NoCollegesModule,
    AcademicsModule,
    PublishModule,
    AccountModule,
    MapsModule,
    LoginModule,
    CoreModule,
    MessageModule,
    InfoModule,
    AuthenticatedModule,
    NotificationsModule,

    // For ** route, this MUST be the last import
    ErrorPagesModule,
    MapUpdateModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtAuthenticationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
