import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { CollegeProgramMapCTA } from "../../../../shared/programmapper-authoring.model";

@Injectable({
  providedIn: 'root'
})
export class CtaUpdateService {
  programMapCTA$: BehaviorSubject<CollegeProgramMapCTA> = new BehaviorSubject(null);

  constructor() { }

  initialize(programMapCTA: CollegeProgramMapCTA | void) {
    this.programMapCTA$.next(programMapCTA || null);
  }
}
