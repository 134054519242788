import {
  CourseListMapCard,
  CourseMapCard,
  CustomCourseChoiceMapCard,
  GenEdAreaMapCard,
  MapCard,
  MapCardType,
  PublishedCourseListSummary,
  PublishedCourseSummary,
  PublishedGeneralEducationAreaSummary,
  VerticalSpacer
} from '../../shared/programmapper-authoring.model';
import {isNullOrUndefined} from 'util';

export function isMapCourse(mapItem: MapCard): mapItem is CourseMapCard {
  return mapItem.type === MapCardType.COURSE;
}

export function isMapCourseGroup(mapItem: MapCard): mapItem is CourseListMapCard {
  return mapItem.type === MapCardType.COURSE_LIST;
}

export function isMapGenEdArea(mapItem: MapCard): mapItem is GenEdAreaMapCard {
  return mapItem.type === MapCardType.GENERAL_EDUCATION_AREA;
}

export function isMapCustomCourseGroup(mapItem: MapCard): mapItem is CustomCourseChoiceMapCard {
  return mapItem.type === MapCardType.CUSTOM_COURSE_CHOICE;
}

export function isVerticalSpace(mapItem: MapCard): mapItem is VerticalSpacer {
  return mapItem.type === MapCardType.VERTICAL_SPACE;
}

export function courseToMapCard(course: PublishedCourseSummary, minUnits: number, maxUnits: number): CourseMapCard {
  if (isNullOrUndefined(minUnits)) {
    throw new Error('minUnits must not be undefined');
  }
  if (isNullOrUndefined(maxUnits)) {
    throw new Error('maxUnits must not be undefined');
  }
    const mapCard: CourseMapCard = {
      title: course.title,
      minUnits: minUnits,
      maxUnits: maxUnits,
      code: course.courseCode,
      courseReference: {
        masterRecordId: course.masterRecordId,
        courseCode: course.courseCode,
        courseTitle: course.title,
      },
      type: MapCardType.COURSE,
    };

    return mapCard;
}

export function courseListToMapCard(courseList: PublishedCourseListSummary, minUnits: number, maxUnits: number, filterUnits: boolean): CourseListMapCard {
  if (isNullOrUndefined(minUnits) || isNullOrUndefined(maxUnits)) {
    throw new Error('minUnits and maxUnits must not be undefined');
  }

  const mapCard: CourseListMapCard = {
      masterRecordId: courseList.masterRecordId,
      title: courseList.title,
      shortDescription: courseList.shortDescription,
      longDescription : courseList.longDescription,
      minUnits: minUnits,
      maxUnits: maxUnits,
      type: MapCardType.COURSE_LIST,
      filterUnits: filterUnits
    };

    return mapCard;
}

export function genEdAreaToMapCard(area: PublishedGeneralEducationAreaSummary, minUnits: number, maxUnits: number, filterUnits: boolean): GenEdAreaMapCard {
  if (isNullOrUndefined(minUnits) || isNullOrUndefined(maxUnits)) {
    throw new Error('minUnits/maxUnits must not be undefined');
  }

  const mapCard: GenEdAreaMapCard = {
    generalEducationPattern: area.generalEducationPattern,
    masterRecordId: area.masterRecordId,
    title: area.title,
    shortDescription: area.shortDescription,
    longDescription : area.longDescription,
    area: area.area,
    minUnits: minUnits,
    maxUnits: maxUnits,
    type: MapCardType.GENERAL_EDUCATION_AREA,
    filterUnits: filterUnits
  };

  return mapCard;
}



