import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MessageService } from '../../../../shared/message/message.service';

import { CtaUpdateService } from './cta-update.service';
import {CollegeProgramMapCTA} from "../../../../shared/programmapper-authoring.model";

@Component({
  selector: 'app-cta-update',
  templateUrl: './cta-update.component.html',
  styleUrls: ['./cta-update.component.css']
})
export class CtaUpdateComponent implements OnInit, OnDestroy {
  form: FormGroup;
  programMapCTA$: Subscription;
  programMapCTA: CollegeProgramMapCTA;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private ctaUpdateService: CtaUpdateService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.initializeForm();

    this.programMapCTA$ = this.ctaUpdateService.programMapCTA$.subscribe(
      (programMapCTA: CollegeProgramMapCTA) => {
        if (programMapCTA) {
          this.programMapCTA = programMapCTA;
          this.instantiateForm();
        }
      });
  }

  ngOnDestroy() {
    this.programMapCTA$.unsubscribe();
  }

  initializeForm() {
    this.form = this.fb.group({
      description: ['', [Validators.required, Validators.maxLength(140)]],
      url: ['', [Validators.required, Validators.pattern('^(https?:/\/\.*)') ]],
      actionLabel: ['', Validators.required],
    });
  }

  instantiateForm() {
    this.form.setValue({
      description: this.programMapCTA.description,
      url: this.programMapCTA.url,
      actionLabel: this.programMapCTA.actionLabel,
    });
  }

  submitForm() {
    this.ctaUpdateService.programMapCTA$.next(this.form.value);
    this.closeModal();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }
}
