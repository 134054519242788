import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../authentication.guard';
import { SelectedCollegeGuard } from '../selected-college.guard';

import { MapControlsComponent } from '../maps/common/map-controls/map-controls.component';

import { IconSelectComponent } from './common/icon-select/icon-select.component';
import { ProgramSelectComponent } from './common/program-select/program-select.component';

import { ProgramGroupCreateComponent } from './program-group-create/program-group-create.component';
import { ProgramGroupListComponent } from './program-group-list/program-group-list.component';
import { ProgramGroupUpdateComponent } from './program-group-update/program-group-update.component';


const routes: Routes = [
  {
    path: 'academics',
    canActivateChild: [AuthenticationGuard, SelectedCollegeGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/academics/program-group-list'
      },
      {
        path: 'program-group-list',
        component: ProgramGroupListComponent,
        children: [
          {
            path: 'map-controls',
            component: MapControlsComponent,
            outlet: 'popup',
          },
        ]
      },
      {
        path: 'program-group-create',
        component: ProgramGroupCreateComponent,
        children: [
          {
            path: 'icon-select',
            component: IconSelectComponent,
            outlet: 'popup',
          },
          {
            path: 'program-select',
            component: ProgramSelectComponent,
            outlet: 'popup',
          },
        ]
      },
      {
        path: 'program-group-update/:programGroupId',
        component: ProgramGroupUpdateComponent,
        children: [
          {
            path: 'icon-select/:groupId',
            component: IconSelectComponent,
            outlet: 'popup',
          },
          {
            path: 'program-select/:groupId',
            component: ProgramSelectComponent,
            outlet: 'popup',
          },
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AcademicsRoutingModule { }
