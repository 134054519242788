import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../authentication.guard';
import { SelectedCollegeGuard } from '../selected-college.guard';

import { CollegeComponent } from './college.component';
import { AboutComponent } from './about/about.component';
import { GeneralComponent } from './general/general.component';
import { VersionsListComponent } from './general/versions-list/versions-list.component';
import { CtaUpdateComponent } from './general/cta-update/cta-update.component';
import { UsersComponent } from './users/users.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import {
  ExcludePreviousCatalogYearsComponent
} from "./general/exclude-previous-catalog-years/exclude-previous-catalog-years.component";

const routes: Routes = [
  {
    path: 'college',
    component: CollegeComponent,
    canActivate: [AuthenticationGuard, SelectedCollegeGuard],
    canActivateChild: [AuthenticationGuard, SelectedCollegeGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/college/general'
      },
      {
        path: 'about',
        component: AboutComponent
      },
      {
        path: 'general',
        component: GeneralComponent,
        children: [
          {
            path: 'versions-list',
            component: VersionsListComponent,
            outlet: 'popup',
          },
          {
            path: 'cta-update',
            component: CtaUpdateComponent,
            outlet: 'popup',
          },
          {
            path: 'exclude-previous-catalog-years',
            component: ExcludePreviousCatalogYearsComponent,
            outlet: 'popup'
          }
        ],
      },
      {
        path: 'users',
        component: UsersComponent,
        children: [
          {
            path: 'user-create',
            component: UserCreateComponent,
            outlet: 'popup',
          },
          {
            path: 'user-edit/:userId',
            component: UserEditComponent,
            outlet: 'popup',
          },
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CollegeRoutingModule { }
