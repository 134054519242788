import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';

import { CurrentUser } from './missing-types.type';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  currentUser$: BehaviorSubject<CurrentUser> = new BehaviorSubject(null);

  constructor(private localStorageService: LocalStorageService) { }

  setCurrentUser(user: CurrentUser): void {
    this.localStorageService.store('currentUser', {firstName: user.firstName, lastName: user.lastName, email: user.email});
    this.currentUser$.next(user);
  }

  getCurrentUser(): Observable<CurrentUser> {
    const currentUser = this.localStorageService.retrieve('currentUser');

    if (currentUser) {
      this.currentUser$.next(currentUser);
    }

    return this.currentUser$.asObservable();
  }

  // updateCurrentUser(user: CurrentUser): Observable<CurrentUser> {
  //   this.localStorageService.store('currentUser', {firstName: user.firstName, lastName: user.lastName, email: user.email});
  //   this.currentUser$.next(user);
  //   return of(user);
  // }
  //
  // updatePassword(password: string): Observable<boolean> {
  //   return of(true).pipe(delay(1000));
  // }
}
