import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {CollegeGeneralInfo, PublishedCurriculumSummary} from "../../shared/programmapper-authoring.model";
import {SelectedYearAndVersionService} from "../selected-year-and-version.service";

@Component({
  selector: 'app-choose-year-modal',
  templateUrl: './choose-year-modal.component.html',
})
export class ChooseYearModalComponent implements OnInit, OnDestroy {

  versionsSub: Subscription;
  versionListByYear: PublishedCurriculumSummary[];
  years: number[] = [];
  currentVersion: PublishedCurriculumSummary;
  versionsList: PublishedCurriculumSummary[] = [];
  generalInfo: CollegeGeneralInfo;
  saving = false;
  versionsAvailable = true;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private selectedYearAndVersionService: SelectedYearAndVersionService,) { }

  ngOnInit() {

    this.versionsSub = this.selectedYearAndVersionService.getSelectedCurriculumVersion().subscribe(
      (versionInfo) => {

        if (versionInfo.versionsList) {
          this.versionsList = versionInfo.versionsList;
        } else {
          this.versionsAvailable = false;
          return;
        }

        if (versionInfo.currentSelectedVersion) {
          this.currentVersion = versionInfo.currentSelectedVersion;
        } else {
          this.currentVersion = this.getDefaultVersion();
        }

        if (this.currentVersion && this.currentVersion.year) {
          this.getVersionByYear(this.currentVersion.year);
        }

        this.getYears();
      });
  }

  ngOnDestroy() {
    this.versionsSub.unsubscribe();
  }

  private getDefaultVersion() {
    let highestYear = 0;

    this.versionsList.forEach(
      (version) => {
        if (version.year > highestYear) {
          highestYear = version.year;
        }
      }
    );

    this.getVersionByYear(highestYear);
    return this.versionListByYear.pop();
  }

  private getYears() {
    this.versionsList.forEach(
      (version) => {
        if (!this.years.includes(version.year)) {
          this.years.push(version.year);
        }
      }
    );
    this.years.sort((a, b) => b - a);
  }

  getVersionByYear(year: number) {
    this.versionListByYear = this.versionsList.filter(
      (version) => {
        return version.year == year;
      }
    ).sort((a, b) => b.revision - a.revision);
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent, skipLocationChange: true });
  }

  cancel() {
    this.closeModal();
  }

  save(version) {
    this.saving = true;
    this.selectedYearAndVersionService.setSelectedCurriculumVersion(version);
    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent, skipLocationChange: true }).then(()=>{
      this.router.navigate(['/college']);
    });

  }

  updateVersions(value: number) {
    this.getVersionByYear(value);
  }
}
