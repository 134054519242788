import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription, ReplaySubject, forkJoin, timer } from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import { FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {
  DataIssue, ErrorSeverity, MapDataIssue, ProgramDataIssue, ProgramGroupDataIssue, PublishStatus, SiteContentPreview,
  SiteContentPublish
} from '../../../shared/programmapper-authoring.model';

import { PublishService } from '../publish.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit, OnDestroy {
  dataIssues$: Observable<DataIssue[]>;
  criticalIssues: boolean;
  componentLoaded = false;

  private currentSiteContentPreviewSource = new ReplaySubject<SiteContentPreview>();

  currentSiteContentPreview$ = this.currentSiteContentPreviewSource.asObservable();

  private currentSiteContentPublishSource = new ReplaySubject<SiteContentPublish>();
  currentSiteContentPublish$ = this.currentSiteContentPublishSource.asObservable();

  private previewPollingSub: Subscription;
  private publishPollingSub: Subscription;
  private unsubscribe$ = new Subject();

  dataIssues = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private publishService: PublishService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.criticalIssues = true;
    this.dataIssues$ = this.publishService.getDataIssues();

    this.dataIssues$.subscribe((dataIssues: DataIssue[]) => {

      this.criticalIssues = dataIssues.some(
        (dataIssue: DataIssue) =>
          dataIssue.severity === ErrorSeverity.CRITICAL);

      this.dataIssues = dataIssues;
      this.componentLoaded = true;
    });
  }

  hasCriticalDataIssues() {
    return this.criticalIssues;
  }

  isPreviewPending(siteContentPreview: SiteContentPreview) {
    return siteContentPreview.publishStatus === PublishStatus.PENDING;
  }

  isPreviewCompleted(siteContentPreview: SiteContentPreview) {
    return siteContentPreview.publishStatus === PublishStatus.COMPLETED;
  }

  isPublishPending(siteContentPublish: SiteContentPublish) {
    return siteContentPublish.publishStatus === PublishStatus.PENDING;
  }

  isPublishCompleted(siteContentPublish: SiteContentPublish) {
    return siteContentPublish.publishStatus === PublishStatus.COMPLETED;
  }

  requestPreview() {
    this.publishService.createSiteContentPreview()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(siteContentPreview => {
        const tick$: Observable<number> = timer(0, 5000); // first check is at 0 seconds;

        this.previewPollingSub = tick$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(t => {

            const x = this.publishService.getSiteContentPreview(siteContentPreview.id)
              .pipe(takeUntil(this.unsubscribe$));

            x.subscribe(siteContentPreview => {
              if (siteContentPreview.publishStatus !== PublishStatus.PENDING) {
                this.previewPollingSub.unsubscribe();
              }
              this.currentSiteContentPreviewSource.next(siteContentPreview);
            });

            return x;
        });
      });
  }

  requestPublish() {
    this.publishService.createSiteContentPublish()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(siteContentPublish => {
        const tick$: Observable <number> = timer(0, 5000); // first check is at 0 seconds;

        this.publishPollingSub = tick$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
              const siteContentPublish$ = this.publishService.getSiteContentPublish(siteContentPublish.id)
                .pipe(takeUntil(this.unsubscribe$));
              siteContentPublish$.subscribe(siteContentPublish => {
                if (siteContentPublish.publishStatus !== PublishStatus.PENDING) {
                  this.publishPollingSub.unsubscribe();
                }
                this.currentSiteContentPublishSource.next(siteContentPublish);
              });
              return siteContentPublish$;
            }
          );
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToMap(mapDataIssue: MapDataIssue) {
    this.router.navigate(['maps', 'programs', mapDataIssue.programMasterRecordId, 'maps', mapDataIssue.mapId, 'builder']);
  }

  goToGroup(programDataIssue: ProgramGroupDataIssue) {
    this.router.navigate(['academics', 'program-group-update', programDataIssue.programGroupId]);
  }

  goToGeneral() {
    this.router.navigate(['college', 'general']);
  }
}
