import { Injectable } from '@angular/core';
import { Observable, of, Subject} from 'rxjs';
import { share } from 'rxjs/operators';

import { ApiService } from '../../../shared/core/api.service';
import { CollegeGeneralInfo } from '../../../shared/programmapper-authoring.model.d';
import { CollegeTermTypeWrapper } from '../missing-types.type';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  collegeGeneralUpdated$: Subject<CollegeGeneralInfo> = new Subject();
  private _excludedPreviousCatalogYearsChangedSource: Subject<number[]> = new Subject();
  excludedPreviousCatalogYearsChanged$: Observable<number[]> = this._excludedPreviousCatalogYearsChangedSource.asObservable();

  private collegeTermTypes: CollegeTermTypeWrapper[] = [
    { termsPerYear: 1, termTypeName: '1 Term' },
    { termsPerYear: 2, termTypeName: '2 Terms' },
    { termsPerYear: 3, termTypeName: '3 Terms' },
    { termsPerYear: 4, termTypeName: '4 Terms' },
  ];

  private _excludedPreviousCatalogYears: number[] = [];
  private currentlySelectedCatalogYear: number;

  constructor(private apiService: ApiService) {}

  getCollegeGeneralInfo(): Observable<CollegeGeneralInfo> {
    return this.apiService.getCollege<CollegeGeneralInfo>('general-info');
  }

  changeExcludedPreviousCatalogYears(excludedPreviousCatalogYears: number[]){
    this._excludedPreviousCatalogYearsChangedSource.next(excludedPreviousCatalogYears);
  }

  getExcludedPreviousCatalogYears(): number[]{
    return this._excludedPreviousCatalogYears;
  }

  // Caution: this method returns what can be an unsaved value (an unsaved change sitting on the college general page while in a modal)
  getCurrentlySelectedCatalogYear(): number{
    return this.currentlySelectedCatalogYear;
  }

  initExcludedPreviousCatalogYears(excludedPreviousCatalogYears: number[], currentlySelectedCatalogYear: number){
    // make a deep copy so that changes to the list are not made by reference (so that cancel button works properly)
    this._excludedPreviousCatalogYears = excludedPreviousCatalogYears.map(it => it);
    this.currentlySelectedCatalogYear = currentlySelectedCatalogYear;
  }

  previouslyPublishedCatalogYears$(year: number) : Observable<number[]>{
    return this.apiService.getCollege<number[]>(`years/${year}/previous-catalog-years`);
  }

  updateCollegeGeneral(collegeGeneral: CollegeGeneralInfo):
    Observable<CollegeGeneralInfo> {

    // console.log(collegeGeneral);

    const putCollegeGeneral$ = this.apiService
      .putCollege<CollegeGeneralInfo>(
        collegeGeneral, 'general-info')
      .pipe(share());

    putCollegeGeneral$.subscribe((collegeGeneral: CollegeGeneralInfo) => {
      this.collegeGeneralUpdated$.next(collegeGeneral);
    });

    return putCollegeGeneral$;
  }

  createCollegeGeneral(collegeGeneral: CollegeGeneralInfo): Observable<CollegeGeneralInfo> {

    const postCollegeGeneral$ = this.apiService
      .postCollege<CollegeGeneralInfo, CollegeGeneralInfo>(
        collegeGeneral, 'general-info')
      .pipe(share());

    postCollegeGeneral$.subscribe((collegeGeneral: CollegeGeneralInfo) => {
      this.collegeGeneralUpdated$.next(collegeGeneral);
    });

    return postCollegeGeneral$;
  }


  getCollegeTermTypes(): Observable<CollegeTermTypeWrapper[]> {
    return of(this.collegeTermTypes);
  }

}
