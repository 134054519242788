import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LinkedMapsService} from "../linked-maps.service";
import {MessageService} from "../../../../../shared/message/message.service";

@Component({
  selector: 'app-linked-maps-pending-declined-unlink-modal',
  templateUrl: './linked-maps-pending-declined-unlink-modal.component.html',
})
export class LinkedMapsPendingDeclinedUnlinkModalComponent implements OnInit {

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private linkedMapsService: LinkedMapsService,
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.closeModal();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { subPopup: ['linked-maps'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );
  }

  unlinkPending() {
    // delete the mapToUnlink.
    // TODO: error handle response here
    this.linkedMapsService.deletePendingMapLink(this.linkedMapsService.pendingMapToUnlink.map.mapId,
      this.linkedMapsService.pendingMapToUnlink.linkedProgramId).subscribe(
      (response: boolean) => {
//        this.messageService.add(`Link successfully removed.`, 'icon-notify');
        this.closeModal();
      },
    );
  }
}
