import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AcademicsService} from "../../../academics/academics.service";
import {ProgramMap, ProgramMapSummary, PublishedProgramSummary} from "../../../../shared/programmapper-authoring.model";
import {Subscription} from "rxjs";
import {MapListService} from "../map-list.service";
import {MapsService} from "../../maps.service";
import {MessageService} from "../../../../shared/message/message.service";

@Component({
  selector: 'app-copy-map-modal',
  templateUrl: './copy-map-modal.component.html',
  styleUrls: ['./copy-map-modal.component.css']
})
export class CopyMapModalComponent implements OnInit {

  form: FormGroup;
  programs: PublishedProgramSummary[];
  programsSub: Subscription;
  mapSummary: ProgramMapSummary;
  programSummary: PublishedProgramSummary;
  saving = false;
  mapCopied = false;
  copiedProgramMap: ProgramMap;
  copiedMapProgramSummary: PublishedProgramSummary;
  mapCopiedToDifferentProgram = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private academicsSerice: AcademicsService,
              private mapListService: MapListService,
              private mapsService: MapsService,
              private messageService: MessageService) { }

  ngOnInit() {

    this.mapSummary = this.mapListService.mapToCopySummary;
    this.programSummary = this.mapListService.programSummary;

    this.programsSub = this.academicsSerice.getPrograms().subscribe((programs: PublishedProgramSummary[]) => {
      this.programs = programs;
    });

    this.form = this.fb.group({
      selectedProgramId: [this.programSummary.masterRecordId, Validators.required],
    });

  }

  ngOnDestroy() {
    this.programsSub.unsubscribe();
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
      { relativeTo: this.route.parent, skipLocationChange: true });
  }

  submitForm() {

    this.saving = true;

    this.mapsService.copyMapToProgram(this.mapSummary.mapId, this.form.value.selectedProgramId).subscribe(programMap => {
      if (programMap) {
        this.copiedProgramMap = programMap;
        this.copiedMapProgramSummary = this.programs.find(program => program.masterRecordId === programMap.programMasterRecordId);
        this.mapCopied = true;

        if (this.programSummary.masterRecordId === programMap.programMasterRecordId) {
          this.mapListService.updateMapList.next(true);
        }
      } else {
        this.messageService.add(`Map copy failed.`, 'icon-alert', 'authalert-error');
        this.closeModal();
      }
    });

  }


}
