import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from 'src/app/authenticated/authentication.guard';
import {NoCollegesComponent} from "./no-colleges.component";


const routes: Routes = [
  {
    path: 'no-colleges',
    canActivate: [AuthenticationGuard],
    component: NoCollegesComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NoCollegesRoutingModule { }
