import { Component, Renderer2 } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { ProgramGroupForAuthoring } from '../../../shared/programmapper-authoring.model.d';
import { MessageService } from '../../../shared/message/message.service';

import { ProgramSelectService } from '../common/program-select/program-select.service';
import { IconSelectService } from '../common/icon-select/icon-select.service';

import { AcademicsService } from '../academics.service';
import { ProgramGroupMixin } from '../program-group.mixin';
import {ProgramReference, PublishedProgramSummary} from "../../../shared/programmapper-authoring.model";
import {ProgramGroupIcon} from "../missing-types.type";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-program-group-update',
  templateUrl: '../common/group.component.html',
  styleUrls: ['./program-group-update.component.css'],
  animations: [
      trigger('slidePanel', [
          state('inactive', style({
              height: '0'
          })),
          state('active', style({
              height: '*'
          })),
          transition('inactive => active', animate('200ms ease-in')),
          transition('active => inactive', animate('200ms ease-out'))
      ]),
  ]
})
export class ProgramGroupUpdateComponent extends ProgramGroupMixin {
  templateTitle = 'Edit Program Group';
  templateSaveButtonText = 'Save';
  templateCanDelete = true;
  deleteConfirmed = false;
  programGroup: ProgramGroupForAuthoring;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private fb: FormBuilder,
    private messageService: MessageService,
    private academicsService: AcademicsService,
    protected programSelectService: ProgramSelectService,
    protected iconSelectService: IconSelectService,
    protected renderer: Renderer2,
  ) {
    super(route, router, programSelectService, iconSelectService, renderer);
    this.renderer.removeClass(document.body, 'l-staticfooterpage');
    this.renderer.addClass(document.body, 'l-fixedfooterpage');
  }

  ngOnInit() {
    super.listenForProgramAdditions();
    super.listenForIconSelection();

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      const programGroupId: string = paramMap.get('programGroupId');

      this.academicsService.getProgramGroup(programGroupId).subscribe(
        (programGroup: ProgramGroupForAuthoring) => {
            this.programGroup = programGroup;

            this.setProgramGroupIcon(programGroup.iconId);

            this.getProgramsFromIds(programGroup.programs);
            this.instantiateForm(programGroup);
        });
    });
  }

  setProgramGroupIcon(iconId: number) {
    this.academicsService.getProgramGroupIcon(this.programGroup.iconId).subscribe(
      (programGroupIcon: ProgramGroupIcon) => {
        this.programGroupIcon = programGroupIcon;
        this.iconSelectService.iconSelected$.next(programGroupIcon);
      });
  }

  getProgramsFromIds(programReferences: ProgramReference[]) {

    this.academicsService.getPrograms()
      .subscribe((programs: PublishedProgramSummary[]) => {

        const groupPrograms : PublishedProgramSummary[] = new Array();

        // we have the list of all programs
        programReferences.forEach(programReference => {

          // for each program referenced by this group, find the matching published program, if it exists
          const program: PublishedProgramSummary = programs.find(program => program.masterRecordId === programReference.masterRecordId);

          if (!isNullOrUndefined(program)) {
            groupPrograms.push(program);
          }
        })

        this.programReferences = programReferences;
        this.programSelectService.initialize(groupPrograms);
    });
  }

  deleteThis() {
    this.academicsService.deleteProgramGroup(this.programGroup).subscribe(
      (success: boolean) => {
        this.router.navigate(['.'], { relativeTo: this.route.parent });
        this.messageService.add('Program group successfully deleted', 'icon-notify');
    });
  }

  instantiateForm(programGroup: ProgramGroupForAuthoring) {
    this.form = this.fb.group({
      id: [programGroup.id, Validators.required],
      name: [programGroup.name, [Validators.required, Validators.maxLength(60)]],
      description: [programGroup.description, [Validators.required, Validators.maxLength(750)]],
      iconId: [programGroup.iconId, Validators.required],
      version: [programGroup.version]
    });
  }

  cancelButton() {
    this.instantiateForm(this.programGroup);
    this.setProgramGroupIcon(this.programGroup.iconId);
    this.getProgramsFromIds(this.programGroup.programs);
  }

  submitForm() {
    this.saving = true;
    const formData: ProgramGroupForAuthoring = Object.assign({}, this.form.value);
    formData.programs = this.programReferences;

    this.academicsService.updateProgramGroup(formData).subscribe(
      (programGroup: ProgramGroupForAuthoring) => {
        this.programGroup = programGroup;
        this.messageService.add('Program group successfully updated', 'icon-notify');
        this.router.navigate(['.'], { relativeTo: this.route.parent });
      },
      (error: HttpErrorResponse) => {
        this.messageService.add('Program group failed to update.', 'icon-alert', 'authalert-error');
        this.saving = false;
      });
  }
}
