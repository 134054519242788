import { Injectable } from '@angular/core';
import {Subject, ReplaySubject} from 'rxjs';

import {CollegeSummary} from "../shared/programmapper-authoring.model";
import {LocalStorageService} from "ngx-webstorage";

@Injectable({
  providedIn: 'root'
})
export class SelectedCollegeService {
  college$: Subject<CollegeSummary> = new ReplaySubject(1);
  selectedCollegeId: string;
  selectedCollegeName: string;

  constructor(private localStorageService: LocalStorageService
  ) {}

  selectCollege(college: CollegeSummary) : boolean {
    if (college) {
      this.localStorageService.store('preferredCollegeId', college.id);
      this.selectedCollegeId = college.id;
      this.selectedCollegeName = college.name;
      this.college$.next(college);
      return true;
    }
    else{
      this.selectedCollegeId = undefined;
      this.college$.next(undefined);
      return false;
    }
  }

  getPreferredCollegeId(): string{
    return this.localStorageService.retrieve('preferredCollegeId');
  }

  getSelectedCollegeId() {
    return this.selectedCollegeId;
  }

  clearCollege() {
    this.selectCollege(undefined);
  }

  clearPreferredCollegeId() {
    this.localStorageService.clear('preferredCollegeId');
  }
}
