import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MapControlsAction, MapControlsConfig } from './map-controls.type';
import { MapControlsService } from './map-controls.service';
import {MapCard} from "../../../../shared/programmapper-authoring.model";

@Component({
  selector: 'app-map-controls',
  templateUrl: './map-controls.component.html',
  styleUrls: ['./map-controls.component.css']
})
export class MapControlsComponent implements OnInit {
  config: MapControlsConfig;
  mapCard: MapCard;

  MapControlsAction = {
    'EDIT': MapControlsAction.EDIT,
    'REMOVE': MapControlsAction.REMOVE,
    'MOVE_UP': MapControlsAction.MOVE_UP,
    'MOVE_DOWN': MapControlsAction.MOVE_DOWN,
    'MARK_RECOMMENDED': MapControlsAction.MARK_RECOMMENDED,
  };

  constructor(private router: Router,
    private route: ActivatedRoute,
    private mapControlsService: MapControlsService) { }

  ngOnInit() {
    this.mapCard = this.mapControlsService.mapItem$.value;
    this.config = this.mapControlsService.config;

    if (this.mapControlsService.mapAction$.observers.length < 1 || ! this.mapControlsService.mapItem$) {
      this.closeModal();
    }
  }

  closeModal() {
    this.router.navigate([{ outlets: { popup: null } }],
                         { relativeTo: this.route.parent,
                           skipLocationChange: true });
  }

  selectAction(action: MapControlsAction) {
    this.mapControlsService.mapAction$.next(action);
  }
}
