import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LinkedMapsService} from "../linked-maps.service";
import {
  MapLink,
  PendingMapLink, ProgramMapSummary,
  PublishedProgramSummary,
} from "../../../../../shared/programmapper-authoring.model";
import {Observable} from "rxjs";

export interface MapLinkListItem {
  mapLinkItem: MapLink | PendingMapLink;
  status: string;
}

export const enum StatusType {
  MAP_LINK_AWAITING_APPROVAL = 'Awaiting Approval',
  MAP_LINK_APPROVAL_REQUIRED = 'Approval Required',
  MAP_LINK_ACTIVE = 'Active',
  PENDING_LINK_PENDING = 'Pending',
  PENDING_LINK_DECLINED = 'Declined',
}

@Component({
  selector: 'app-linked-maps-modal',
  templateUrl: './linked-maps-modal.component.html',
})
export class LinkedMapsModalComponent implements OnInit {

  program: PublishedProgramSummary;
  mapId: string;
  mapEmphasis: string;
  allMapLinks: MapLinkListItem[] = [];
  //to be an array of AMapLinkWithStatus[]
  allMapLinksByCollege = [];
  private mapLinks$: Observable<MapLink[]>;
  private pendingMapLinks$: Observable<PendingMapLink[]>;
  mapLinksLoaded = false;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private linkedMapsService: LinkedMapsService
  ) { }

  ngOnInit() {
    this.program = this.linkedMapsService.program;
    this.mapId = this.linkedMapsService.sourceMap.id;
    this.mapEmphasis = this.linkedMapsService.sourceMap.emphasis;
    this.mapLinks$ = this.linkedMapsService
      .getLinkedMaps(this.linkedMapsService.sourceMap.id);
    this.pendingMapLinks$ = this.linkedMapsService
      .getPendingLinkedMaps(this.linkedMapsService.sourceMap.id)

    this.calculateMapLinksWithStatus();
  }

  cancel() {
    this.closeModal();
  }

  closeModal() {
    this.router.navigate([{ outlets: { subPopup: null } }], {relativeTo: this.route.parent, skipLocationChange: true});
  }

  linkNewMap() {
    // Close this modal then open the "add" modal.
    this.router.navigate([{ outlets: { subPopup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { popup: ['linked-maps-add'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );
  }

  reviewLinkedMap(mapLink: MapLink ) {

    this.linkedMapsService.mapToUnlink = mapLink;

    // Close this modal then navigate to the unlink modal
    this.router.navigate([{ outlets: { subPopup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { popup: ['linked-maps-review-active-link'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );
  }

  unlinkPendingMapLink(pendingMapLink: PendingMapLink){
    this.linkedMapsService.pendingMapToUnlink = pendingMapLink;

    // Close this modal then navigate to the unlink modal
    this.router.navigate([{ outlets: { subPopup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { popup: ['linked-maps-pending-declined-unlink'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );
  }

  deletePendingMapLink(pendingMapLink: PendingMapLink ) {
    this.linkedMapsService.pendingMapToUnlink = pendingMapLink;
    // Close this modal then navigate to the unlink modal
    this.router.navigate([{ outlets: { subPopup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { popup: ['pending-linked-maps-unlink'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );
  }

  updateMapLink(mapLink: MapLink){
    this.linkedMapsService.mapToUnlink = mapLink;
    this.router.navigate([{ outlets: { subPopup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { popup: ['linked-maps-update-approval'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );
  }

  reviewApproval(mapLink: MapLink){
    this.linkedMapsService.mapToUnlink = mapLink;
    this.router.navigate([{ outlets: { subPopup: null } }], {relativeTo: this.route.parent, skipLocationChange: true}).then(
      () => {
        this.router.navigate([{ outlets: { popup: ['linked-maps-review-approval'] } }],
          { relativeTo: this.route.parent, skipLocationChange: true });
      }
    );
  }

  calculateMapLinkStatus(value: MapLink) : string{
    if(value.isApproved && value.linkedMapIsApproved){
      return StatusType.MAP_LINK_ACTIVE;
    }
    else if(value.isApproved && !value.linkedMapIsApproved){
      return StatusType.MAP_LINK_AWAITING_APPROVAL;
    }
    else if(!value.isApproved && value.linkedMapIsApproved){
      return StatusType.MAP_LINK_APPROVAL_REQUIRED;
    }
    else{
      throw new Error("At least one map should be approved");
    }
  }

  calculatePendingMapLinkStatus(value: PendingMapLink) : string{
    if(!value.isDeclined){
      return StatusType.PENDING_LINK_PENDING;
    }
    else{
      return StatusType.PENDING_LINK_DECLINED;
    }
  }

  createAllMapLinksByCollege(){
    this.mapLinksLoaded = false;

    if (this.allMapLinks.length === 0) {
      this.allMapLinksByCollege = new Array(0);
      this.mapLinksLoaded = true;
      return;
    }

    this.allMapLinks.sort((a,b) => {
      if(a.mapLinkItem.linkedCollegeName < b.mapLinkItem.linkedCollegeName){
        return -1;
      }
      else if(a.mapLinkItem.linkedCollegeName > b.mapLinkItem.linkedCollegeName){
        return 1;
      }
      return 0;
    })

    //set our lastCollegeName as our first, so that our first is gaurenteed entry
    let lastCollegeName : string = this.allMapLinks[0].mapLinkItem.linkedCollegeName;
    let holderArray : MapLinkListItem[] = new Array();
    //cycle through all of our sortedMapLinks
    for(let i = 0; i < this.allMapLinks.length; i++){
      if(lastCollegeName == this.allMapLinks[i].mapLinkItem.linkedCollegeName){
        holderArray.push(this.allMapLinks[i]);
        //if we are at the end, just take what's left and put it in
        if(i == this.allMapLinks.length-1){
          if(holderArray != null){
            this.allMapLinksByCollege.push(holderArray);
          }
        }
      }
      else{
        if(holderArray.length > 0){
          this.allMapLinksByCollege.push(holderArray);
        }
        holderArray = new Array();
        holderArray.push(this.allMapLinks[i]);
        lastCollegeName = this.allMapLinks[i].mapLinkItem.linkedCollegeName;
        //if we are at the end, just take what's left and put it in
        if(i == this.allMapLinks.length-1){
          this.allMapLinksByCollege.push(holderArray);
        }
      }
    }
    this.mapLinksLoaded = true;
  }

  returnAsMapLinksWithStatus(value: MapLinkListItem[]) : MapLinkListItem[]{
    if(value){
      return value as MapLinkListItem[];
    }
  }

  calculateMapLinksWithStatus(){
    let mapLinksDone = false;
    let pendingLinksDone = false;
    //null check
    if(this.mapLinks$ == null && this.pendingMapLinks$ == null){
      this.allMapLinks = new Array(0);
      return;
    }

    this.mapLinks$.subscribe(mapLinks => {
      //mapLinksWithStatus = new Array(mapLinks.length);
      mapLinks.forEach(mapLink => {
        const ourStatus : string = this.calculateMapLinkStatus(mapLink);
        const mapWithStatus = new class implements MapLinkListItem {
          mapLinkItem: MapLink | PendingMapLink = mapLink;
          status: string = ourStatus;
        }
        this.allMapLinks.push(mapWithStatus);
      })
      mapLinksDone = true;
      if(pendingLinksDone){
        this.createAllMapLinksByCollege();
      }
    });

    this.pendingMapLinks$.subscribe(pendingMapLinks => {
      pendingMapLinks.forEach(pendingMapLink => {
        const ourStatus : string = this.calculatePendingMapLinkStatus(pendingMapLink);
        const mapWithStatus = new class implements MapLinkListItem {
          mapLinkItem: MapLink | PendingMapLink = pendingMapLink;
          status: string = ourStatus;
        }
        this.allMapLinks.push(mapWithStatus);
      })
      pendingLinksDone = true;
      if(mapLinksDone){
        this.createAllMapLinksByCollege();
      }
    });

  }

  getAsPendingMapLinkPending(value: MapLinkListItem) : PendingMapLink{
    if(value.status == StatusType.PENDING_LINK_PENDING){
      return value.mapLinkItem as PendingMapLink;
    }
  }

  //this should currently never get hit
  getAsPendingMapLinkDeclined(value: MapLinkListItem) : PendingMapLink{
    if(value.status == StatusType.PENDING_LINK_DECLINED){
      return value.mapLinkItem as PendingMapLink;
    }
  }

  getAsMapLinkActive(value: MapLinkListItem) : MapLink{
    if(value.status == StatusType.MAP_LINK_ACTIVE){
      return value.mapLinkItem as MapLink;
    }
  }

  getAsMapLinkAwaitingApproval(value: MapLinkListItem) : MapLink{
    if(value.status == StatusType.MAP_LINK_AWAITING_APPROVAL){
      return value.mapLinkItem as MapLink;
    }
  }

  getAsMapLinkApprovalRequired(value: MapLinkListItem) : MapLink{
    if(value.status == StatusType.MAP_LINK_APPROVAL_REQUIRED){
      return value.mapLinkItem as MapLink;
    }
  }

}
